import * as React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getUserTeacherInfo } from "./userSlice";

export default function UserTeacherInfo() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUserTeacherInfo());
  }, [dispatch]);

  const teacher = useSelector((state) => state.user.teachers);

  const profileDetails = teacher
    ? [
        {
          name: "Degree",
          detail: teacher.degree ? teacher.degree : "N/A",
        },
        {
          name: "Start Working Date",
          detail: teacher.start_working_date
            ? teacher.start_working_date
            : "N/A",
        },
        {
          name: "Termination Date",
          detail: teacher.termination_date ? teacher.termination_date : "N/A",
        },
      ]
    : null;

  return (
    <>
      <Typography variant="h5" textAlign="center">
        Teacher Information
      </Typography>
      {teacher ? (
        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              marginRight: "20px",
              textAlign: "right",
            }}
          >
            <Table>
              <TableBody>
                {profileDetails.map((item) => {
                  return item !== null ? (
                    <TableRow key={Math.random()}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.detail}</TableCell>
                    </TableRow>
                  ) : null;
                })}
              </TableBody>
            </Table>           
          </Box>
        </Box>
      ) : null}
    </>
  );
}
