import * as React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  IconButton,
  TextField,
  Button,
  Box,
  Input,
  Tooltip,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

function BucketItem({ item, handleDelete, handleModify, displayAttribute }) {
  const [disabled, setDisabled] = React.useState(true);
  const [value, setValue] = React.useState(item[displayAttribute]);

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton
          edge="end"
          onClick={() => handleDelete(item)}
          disabled={item.deleteDisabled}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemButton onClick={() => setDisabled(false)}>
        <Input
          fullWidth
          disableUnderline={disabled}
          value={value}
          disabled={disabled}
          onChange={(event) => setValue(event.target.value)}
          endAdornment={
            !disabled && (
              <>
                <IconButton
                  edge="end"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleModify(item, { ...item, [displayAttribute]: value });
                    setDisabled(true);
                  }}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={(event) => {
                    event.stopPropagation();
                    setValue(item[displayAttribute]);
                    setDisabled(true);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </>
            )
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default function Bucket({
  items,
  handleAdd,
  handleModify,
  handleDelete,
  textFieldLabel,
  displayAttribute,
}) {
  const [inputValue, setInputValue] = React.useState("");
  const [filterByDisplayAttribute, setFilterByDisplayAttribute] =
    React.useState("");
  // const [listChildren, setListChildren] = React.useState(
  //   items
  //     .slice(0, 100)
  //     .filter(
  //       (item) =>
  //         !filterByDisplayAttribute ||
  //         item[displayAttribute].includes(filterByDisplayAttribute)
  //     )
  //     .map((item) => (
  //       <BucketItem
  //         key={item.id}
  //         item={item}
  //         handleDelete={handleDelete}
  //         handleModify={handleModify}
  //         displayAttribute={displayAttribute}
  //       />
  //     ))
  // );

  const scrollDiv = React.useRef();

  // React.useEffect(() => {
  //   let sd = scrollDiv.current;
  //   sd.addEventListener("scroll", (event) => {
  //     console.log(sd.scrollTop, sd.scrollHeight, sd.offsetHeight);
  //   });
  // }, []);

  const itemsPerPage = 10;
  const [hasMore, setHasMore] = React.useState(true);
  const [records, setrecords] = React.useState(itemsPerPage);
  const loadMore = () => {
    if (records >= items.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage);
      }, 500);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
          <TextField
            label={textFieldLabel}
            fullWidth
            focused
            variant="standard"
            onChange={(event) => {
              setInputValue(event.target.value);
              setFilterByDisplayAttribute(event.target.value);
              setHasMore(true);
              setrecords(itemsPerPage);
            }}
            value={inputValue}
          />
          <IconButton
            disabled={!inputValue}
            onClick={() => {
              handleAdd(inputValue);
              setInputValue("");
              setFilterByDisplayAttribute("");
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <List sx={{ maxHeight: "50vh", overflow: "scroll" }} ref={scrollDiv}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 className="loader" key={Math.random()}>
              Loading...
            </h4>
          }
          useWindow={false}
        >
          {items
            .filter(
              (item) =>
                !filterByDisplayAttribute ||
                item[displayAttribute].includes(filterByDisplayAttribute)
            )
            .slice(0, records + 1)
            .map((item) => (
              <BucketItem
                key={item.id}
                item={item}
                handleDelete={handleDelete}
                handleModify={handleModify}
                displayAttribute={displayAttribute}
              />
            ))}
        </InfiniteScroll>
      </List>
    </>
  );
}
