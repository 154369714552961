import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "showAddBar",
  initialState: {
    category: false,
    programme: false,
    user: false,
    venue: false,
  },
  reducers: {
    updateShowAddBar: (showAddBar, { payload }) => {
      const { name, status } = payload;
      showAddBar[name] = status;
    },
  },
});

const { updateShowAddBar } = slice.actions;

// action
export const updateShowAddBarStatus = (name, status) =>
  updateShowAddBar({ name, status });

// selector
export const getShowAddBarStatus = (name) => (state) =>
  state.ui.showAddBar[name];

export default slice.reducer;
