import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { resetAppointmentFormData, setChanged } from "../lessonsSlice";
import { useDispatch } from "react-redux";

export default function ConfirmationDialog({
  action,
  component,
  open,
  setOpen,
  removeLesson,
  setFormVisibility,
  setTooltipVisibility,
}) {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{"ALERT"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {action === "change"
            ? "Discard unsaved changes?"
            : "Do you confirm to delete this lesson?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            component === "Tooltip" && setTooltipVisibility(true);
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            if (action === "change") {
              dispatch(resetAppointmentFormData());
              dispatch(setChanged(false));
              setOpen(false);
              setFormVisibility(false);
            } else {
              dispatch(setChanged(false));
              removeLesson();
              setOpen(false);
              setTooltipVisibility(false);
            }
          }}
          autoFocus
        >
          {action === "change" ? "DISCARD" : "CONFIRM"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
