import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { combineState, seperateState } from "../normalize";
import {
  moduleObjectiveCreate,
  moduleObjectiveDelete,
  moduleObjectiveDeleteRollback,
} from "./modules";

const endpoint = endpoints["objectives"];

const slice = createSlice({
  name: "objectives",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    objectiveListRequest: (objectives, action) => {
      objectives.loading = true;
    },
    objectiveListReceived: (objectives, { payload }) => {
      seperateState(objectives, payload);
      objectives.loading = false;
    },
    objectiveRequest: (objectives, action) => {
      objectives.loading = true;
    },
    objectiveReceived: (objectives, { payload }) => {
      objectives.allIds.push(payload.id);
      objectives.byId[payload.id] = payload;
      objectives.loading = false;
    },
    objectiveCreate: (objectives, { payload }) => {
      objectives.allIds.push(payload.id);
      objectives.byId[payload.id] = payload;
    },
    objectiveCreateRollback: (objectives, { payload }) => {
      objectives.allIds = objectives.filter(
        (objective) => objective !== payload.id
      );
    },
    objectiveUpdate: (objectives, { payload }) => {
      objectives.byId[payload.id] = payload;
    },
    objectiveUpdateRollback: (objectives, { payload }) => {
      objectives.byId[payload.id] = payload;
    },
    objectiveDelete: (objectives, { payload }) => {
      objectives.allIds = objectives.allIds.filter((id) => id !== payload.id);
    },
    objectiveDeleteRollback: (objectives, { payload }) => {
      objectives.allIds = [...objectives.allIds, payload.id];
    },
  },
});

const {
  objectiveListRequest,
  objectiveListReceived,
  objectiveRequest,
  objectiveReceived,
  objectiveCreate,
  objectiveUpdate,
  objectiveUpdateRollback,
  objectiveDelete,
  objectiveDeleteRollback,
} = slice.actions;

// action
export const getObjectiveList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: objectiveListRequest.type,
    onSuccess: objectiveListReceived.type,
  });

export const retrieveObjective = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "get",
    onStart: objectiveRequest.type,
    onSuccess: objectiveReceived.type,
  });

export const createObjective = (objective) =>
  apiCallBegan({
    endpoint,
    method: "post",
    data: objective,
    onSuccess: objectiveCreate.type,
    onSuccess2: moduleObjectiveCreate.type,
  });

export const updateObjective = (preObjective, objective) =>
  apiCallBegan({
    endpoint: endpoint + objective.id + "/",
    method: "patch",
    data: objective,
    onStart: objectiveUpdate.type,
    onStartPayload: objective,
    onError: objectiveUpdateRollback.type,
    onErrorPayload: preObjective,
  });

export const deleteObjective = (objective) =>
  apiCallBegan({
    endpoint: endpoint + objective.id + "/",
    method: "delete",
    onStart: objectiveDelete.type,
    onStartPayload: objective,
    onStart2: moduleObjectiveDelete.type,
    onStart2Payload: objective,
    onError: objectiveDeleteRollback.type,
    onErrorPayload: objective,
    onError2: moduleObjectiveDeleteRollback.type,
    onError2Payload: objective,
  });

// selector
export const getObjectives = (state) => combineState(state.entities.objectives);

export const getObjectiveLoadingStatus = (state) =>
  state.entities.objectives.loading;

export const getObjectivebyId = (id) => (state) =>
  state.entities.objectives.byId[id];

export default slice.reducer;
