import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "routes",
  initialState: {
    course: "courseInfo",
    resoursePanel: "user",
    coursePanel: "category",
    auditPanel: "module",
    userProfile: "userProfile",
    studentProfile: "studentProfile",
    teacherProfile: "teacherProfile",
    studentMyBag: "bag",
  },
  reducers: {
    updateRoute: (routes, { payload }) => {
      const { name, path } = payload;
      routes[name] = path;
    },
  },
});

const { updateRoute } = slice.actions;

// action
export const updateRoutePath = (name, path) => updateRoute({ name, path });

// selector
export const getRoutes = (state) => state.ui.routes;

export const getRoutePath = (name) => (state) => state.ui.routes[name];

export default slice.reducer;
