import React from "react";

import InfoDialog from "../common/InfoDialog";

export default function ModuleHistoryInfoDialog(props) {
  const { values, open, handleClose } = props;

  const columns = [
    {
      field: "name",
      headerName: "Module Name",
    },
    {
      field: "description",
      headerName: "Description",
    },
  ];

  return (
    <InfoDialog
      columns={columns}
      values={values}
      targetName="Module"
      open={open}
      handleClose={handleClose}
    />
  );
}
