import React from "react";
import { Box } from "@mui/system";

export default function CenterBox({ value, icon }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {value}
      {icon}
    </Box>
  );
}
