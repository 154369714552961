import * as React from "react";
import { paths } from "../../../config";
import { Link } from "react-router-dom";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "Demo";

const classes = {
  commandButton: `${PREFIX}-commandButton`,
};

const StyledIconButton = styled(IconButton)(() => ({
  [`&.${classes.commandButton}`]: {
    backgroundColor: "rgba(255,255,255,0.65)",
  },
}));

export default function Header({ appointmentData, ...restProps }) {
  return (
    <AppointmentTooltip.Header {...restProps} appointmentData={appointmentData}>
      <StyledIconButton
        component={Link}
        to={`/${paths["lessonDetail"](
          appointmentData.programmeId,
          appointmentData.lessonId
        )}`}
        size="large"
      >
        <DoubleArrowIcon />
      </StyledIconButton>
    </AppointmentTooltip.Header>
  );
}
