import { createSlice, createSelector } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { combineState, seperateState } from "../normalize";

const endpoint = endpoints["teachers"];

const slice = createSlice({
  name: "teachers",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    teacherListRequest: (teachers, action) => {
      teachers.loading = true;
    },
    teacherListReceived: (teachers, { payload }) => {
      seperateState(teachers, payload, "user");
      teachers.loading = false;
    },
    teacherRequest: (teachers, action) => {
      teachers.loading = true;
    },
    teacherReceived: (teachers, { payload }) => {
      teachers.allIds.push(payload.user);
      teachers.byId[payload.user] = payload;
      teachers.loading = false;
    },
  },
});

const {
  teacherListRequest,
  teacherListReceived,
  teacherRequest,
  teacherReceived,
} = slice.actions;

// action
export const getTeacherList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: teacherListRequest.type,
    onSuccess: teacherListReceived.type,
  });

export const retrieveTeacher = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "get",
    onStart: teacherRequest.type,
    onSuccess: teacherReceived.type,
  });

// selector
export const getTeachers = (state) => combineState(state.entities.teachers);

export const getTeachersDetail = createSelector(
  (state) => state.entities.users,
  (state) => state.entities.teachers,
  (users, teachers) =>
    teachers.allIds.map((id) => {
      return { ...teachers.byId[id], ...users.byId[id] };
    })
);

export const getTeacherLoadingStatus = (state) =>
  state.entities.teachers.loading;

export const getTeacherbyId = (id) => (state) =>
  state.entities.teachers.byId[id];

export default slice.reducer;
