import React, { forwardRef } from "react";
import AddDialog from "./AddDialog";
import Joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import { createCategory } from "../../../../app/entities/categories";

function CategoryAddDialog(props, ref) {
  const { openDialog, onClose } = props;
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({});

  const columns = [
    {
      field: "name",
      headerName: "Category Name",
      flex: 1,
      required: true,
      focus: true,
      otherStyle: { marginTop: 1 },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
    },
  ];

  const schema = {
    name: Joi.string().required(),
    description: Joi.string().empty(""),
  };

  return (
    <AddDialog
      title={"Category Addition"}
      ref={ref}
      openDialog={openDialog}
      onClose={onClose}
      columns={columns}
      schema={schema}
      onSubmit={(value) => {
        dispatch(createCategory(value));
        onClose();
      }}
      values={values}
      setValues={setValues}
    />
  );
}

export default forwardRef(CategoryAddDialog);
