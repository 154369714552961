import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import useForm from "../../hooks/useForm";
import SaveReminderDialog from "./SaveReminderDialog";
import { Box, IconButton } from "@mui/material";

export default function EditPaper(props) {
  const {
    columns,
    preValues,
    values,
    setValues,
    schema,
    saveAction,
    editMode,
    setEditMode,
  } = props;
  const [errors, setErrors, renderField] = useForm(values, setValues, schema);
  const [remindMode, setRemindOpen] = useState(false);

  const renderEditField = (column) => (
    <Box key={column.field} sx={{ marginTop: 1, marginBottom: 2 }}>
      {renderField(column)}
    </Box>
  );

  const handleSave = () => {
    saveAction();
    setEditMode(false);
  };

  const handleClose = () => {
    if (JSON.stringify(preValues) !== JSON.stringify(values)) {
      setRemindOpen(true);
    } else {
      setValues(preValues);
      setEditMode(false);
    }
  };

  const handleReminderClose = (isDiscard) => {
    setRemindOpen(false);
    if (isDiscard) {
      setValues(preValues);
      setEditMode(false);
    }
  };

  // set edit mode if any fiels is modified
  useEffect(() => {
    if (editMode) return;
    if (JSON.stringify(preValues) !== JSON.stringify(values)) setEditMode(true);
  }, [values]);

  return (
    <React.Fragment>
      <Box
        sx={{
          margin: "1.5rem",
          padding: "1rem",
          border: "solid",
          borderRadius: "8px",
          width: "auto",
          borderColor: "#e0e0e0",
          boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.3)",
        }}
      >
        {columns.map((column) =>
          renderEditField({ ...column, fullWidth: true })
        )}

        {editMode ? (
          <>
            <IconButton onClick={handleSave}>
              <SaveIcon
                sx={{
                  animation: "shake 0.3s",
                  animationIterationCount: "infinite",
                }}
              />
            </IconButton>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={() => setEditMode(true)}>
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <SaveReminderDialog open={remindMode} handleClose={handleReminderClose} />
    </React.Fragment>
  );
}
