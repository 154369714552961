import React from "react";
import { useSelector } from "react-redux";

import Table from "../common/Table";
import { getPerformanceHistoryDisplay } from "../../app/entities/performanceHistory";
import { getPerformanceHistoryLoadingStatus } from "../../app/entities/performanceHistory";

export default function PerformanceHistoryTable(props) {
  const performanceHistory = useSelector(getPerformanceHistoryDisplay);
  const loading = useSelector(getPerformanceHistoryLoadingStatus);

  const columns = [
    {
      field: "history_date",
      headerName: "Date",
      flex: 0.8,
    },
    {
      field: "history_type",
      headerName: "Action",
      flex: 0.4,
    },
    {
      field: "username",
      headerName: "Tracked User",
      flex: 0.6,
    },
    {
      field: "is_teacher",
      headerName: "Teacher",
      type: "boolean",
      flex: 0.4,
    },
    {
      field: "is_student",
      headerName: "Student",
      type: "boolean",
      flex: 0.4,
    },
    {
      field: "name",
      headerName: "Goal Name",
      flex: 1,
    },
  ];

  return (
    <Table
      initialState={{
        sorting: {
          sortModel: [{ field: "history_date", sort: "desc" }],
        },
      }}
      rows={performanceHistory}
      columns={columns}
      loading={loading}
      getRowId={(row) => row.history_id}
      tableName="performance_history"
    />
  );
}
