import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Link,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  getTokenFromLocalStorage,
  getUserInfo,
  userLogout,
} from "../user/userSlice";
import { getCourses } from "./coursesSlice";
import { getVenueConditions, getVenues } from "../lessons/lessonsSlice";

export default function MyAppBar() {
  const dispatch = useDispatch();
  const signedIn = useSelector((state) => state.user.signedIn);
  const hasLoaded = useSelector((state) => state.user.hasLoaded);
  const isSuperUser = useSelector((state) => state.user.is_superuser);
  const isStudent = useSelector((state) => state.user.is_student);
  const isTeacher = useSelector((state) => state.user.is_teacher);
  const userId = useSelector((state) => state.user.userId);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path) => () => {
    if (!matchPath(path, location.pathname)) navigate(path);
  };

  React.useEffect(() => {
    dispatch(getTokenFromLocalStorage());
    dispatch(getUserInfo());
    dispatch(getCourses());
    dispatch(getVenues());
    dispatch(getVenueConditions());
  }, [dispatch]);

  React.useEffect(() => {
    if (!signedIn && hasLoaded) {
      navigate("/", { replace: true });
    }
  }, [signedIn, hasLoaded]);

  async function handleLogout() {
    await dispatch(userLogout());
    navigate("/", { replace: true });
  }

  return (
    <>
      <AppBar
        position="static"
        sx={{
          width: "100%",
          minWidth: "500px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {!isStudent && (
            <Button
              color="inherit"
              id="MyTimeTableButton"
              component={Link}
              to="/timeTable"
            >
              {isTeacher ? "My Time Table" : "Time Table"}
            </Button>
          )}
          <Button
            color="inherit"
            component={Link}
            id="MyLessonsButton"
            to="/lessons"
          >
            {isSuperUser ? "All Lessons" : "My Lessons"}
          </Button>

          {isSuperUser && (
            <>
              <Button
                color="inherit"
                id="ResoursePanelButton"
                onClick={handleNavigate("/resoursePanel")}
              >
                Resourse Panel
              </Button>
              <Button
                color="inherit"
                id="CoursePanelButton"
                onClick={handleNavigate("/coursePanel")}
              >
                Course Panel
              </Button>
              <Button
                color="inherit"
                id="AuditPanelButton"
                onClick={handleNavigate("/auditPanel")}
              >
                Audit Panel
              </Button>
            </>
          )}

          {isTeacher && (
            <Button
              color="inherit"
              component={Link}
              id="CoursesButton"
              to="/courses"
            >
              {isTeacher ? "My Courses" : "All Courses"}
            </Button>
          )}

          {isStudent && (
            <Button color="inherit" onClick={handleNavigate(`/bag/${userId}`)}>
              My Bag
            </Button>
          )}

          <Typography component="div" sx={{ flexGrow: 1 }} />
          <Button color="inherit" onClick={handleNavigate(`/userPage`)}>
            <AccountCircleIcon />
          </Button>
          <Button color="inherit" onClick={handleLogout}>
            Log Out
          </Button>
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
}
