import React from "react";
import { useSelector } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import BookIcon from "@mui/icons-material/Book";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { useNavigate } from "react-router-dom";

import Table from "../common/Table";
import { getStudentsDetail } from "../../app/entities/students";
import { getStudentLoadingStatus } from "../../app/entities/students";
import CenterBox from "../misc/CenterBox";
import { paths } from "../../config";

export default function StudentTable(props) {
  const { handleGiftOpen } = props;
  const students = useSelector(getStudentsDetail);
  const loading = useSelector(getStudentLoadingStatus);
  const navigate = useNavigate();

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "programme_count",
      headerName: "Course Attend",
      flex: 0.5,
      renderCell: (params) => (
        <CenterBox
          value={params.value}
          icon={<BookIcon sx={{ marginLeft: 1 }} />}
        />
      ),
    },
    {
      field: "lesson_count",
      headerName: "Lesson Attend",
      flex: 0.5,
      renderCell: (params) => (
        <CenterBox
          value={params.value}
          icon={<AvTimerIcon sx={{ marginLeft: 1 }} />}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      getActions: ({ row }) => {
        const handleInfo = () =>
          navigate("/" + paths["studentProfile"](row.id));
        const handleGift = () => handleGiftOpen(row);
        return [
          <GridActionsCellItem
            icon={<InfoIcon />}
            label="info"
            onClick={handleInfo}
          />,
          <GridActionsCellItem
            icon={<CardGiftcardIcon />}
            label="info"
            onClick={handleGift}
          />,
        ];
      },
    },
  ];

  return (
    <Table
      initialState={{
        sorting: {
          sortModel: [{ field: "first_name", sort: "asc" }],
        },
      }}
      rows={students}
      columns={columns}
      loading={loading}
      tableName="student"
      getRowId={(row) => row.user}
    />
  );
}
