import React from "react";
import Joi from "joi-browser";

import EditDialog from "../common/EditDialog";

export default function UserEditDialog(props) {
  const { preValues, values, setValues, targetName, open, handleClose } = props;

  const columns = [
    {
      field: "username",
      headerName: "Username",
      required: true,
    },
    {
      field: "first_name",
      headerName: "First Name",
      required: true,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      required: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      type: "email",
    },
  ];

  const schema = {
    username: Joi.string().alphanum().required(),
    first_name: Joi.string().required(),
    last_name: Joi.string().required(),
    email: Joi.string().empty("").email(),
  };

  return (
    <EditDialog
      preValues={preValues}
      columns={columns}
      schema={schema}
      values={values}
      setValues={setValues}
      targetName={targetName}
      open={open}
      handleClose={handleClose}
    />
  );
}
