import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTheme } from "styled-components";

export default function CategoryListItem(props) {
  const { selectedProgramme, open, setOpen, category } = props;
  const theme = useTheme();

  const primaryColorIfSelect = (id) => {
    if (selectedProgramme !== null && selectedProgramme.category === id)
      return theme.palette.primary.main;
  };

  const handleClick = (id) => {
    setOpen({ ...open, [id]: !open[id] });
  };

  return (
    <ListItem disablePadding onClick={() => handleClick(category.id)}>
      <ListItemButton>
        <ListItemText
          primary={category.name}
          sx={{
            color: primaryColorIfSelect(category.id),
          }}
        />
        <ListItemIcon>
          {open[category.id] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
}
