import * as React from "react";
import { Box, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  getOverlappedLessons,
  setAppointmentFormData,
  setChanged,
} from "../../lessonsSlice";

export default function TimePickerPanel({ appointmentData, onFieldChange }) {
  const dispatch = useDispatch();
  const appointmentFormData = useSelector(
    (state) => state.lessons.appointmentFormData
  );

  return (
    <Box>
      <AppointmentForm.Label text="Date & Time*" type="title" />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Start time"
            value={appointmentData.startDate}
            onChange={(newDate) => {
              onFieldChange({ startDate: newDate });
            }}
            onClose={() => {
              dispatch(
                getOverlappedLessons({
                  selectedStartTime: appointmentData.startDate,
                  selectedEndTime: appointmentData.endDate,
                })
              );
              dispatch(setChanged(true));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            label="End time"
            value={appointmentData.endDate}
            onChange={(newDate) => {
              onFieldChange({ endDate: newDate });
            }}
            onClose={() => {
              dispatch(
                getOverlappedLessons({
                  selectedStartTime: appointmentData.startDate,
                  selectedEndTime: appointmentData.endDate,
                })
              );
              dispatch(setChanged(true));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}
