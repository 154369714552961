import {
  red,
  pink,
  purple,
  indigo,
  deepPurple,
  blue,
  green,
  orange,
  brown,
  lightBlue,
  cyan,
  teal,
  lightGreen,
  lime,
  yellow,
  amber,
  deepOrange,
  grey,
  blueGrey,
} from "@mui/material/colors/";

export const primaryColors = {
  green: {
    light: green[100],
    main: green[400],
    dark: green[500],
    contrastText: "#fff",
  },
  red: {
    light: red[100],
    main: red[400],
    dark: red[500],
    contrastText: "#fff",
  },
  pink: {
    light: pink[100],
    main: pink[400],
    dark: pink[500],
    contrastText: "#fff",
  },
  purple: {
    light: purple[100],
    main: purple[400],
    dark: purple[500],
    contrastText: "#fff",
  },
  indigo: {
    light: indigo[100],
    main: indigo[400],
    dark: indigo[500],
    contrastText: "#fff",
  },
  deepPurple: {
    light: deepPurple[100],
    main: deepPurple[400],
    dark: deepPurple[500],
    contrastText: "#fff",
  },
  blue: {
    light: blue[100],
    main: blue[400],
    dark: blue[500],
    contrastText: "#fff",
  },
  orange: {
    light: orange[100],
    main: orange[400],
    dark: orange[500],
    contrastText: "#fff",
  },
  brown: {
    light: brown[100],
    main: brown[400],
    dark: brown[500],
    contrastText: "#fff",
  },
  lightBlue: {
    light: lightBlue[100],
    main: lightBlue[400],
    dark: lightBlue[500],
    contrastText: "#fff",
  },
  cyan: {
    light: cyan[100],
    main: cyan[400],
    dark: cyan[500],
    contrastText: "#fff",
  },
  teal: {
    light: teal[100],
    main: teal[400],
    dark: teal[500],
    contrastText: "#fff",
  },
  lightGreen: {
    light: lightGreen[100],
    main: lightGreen[400],
    dark: lightGreen[500],
    contrastText: "#fff",
  },
  lime: {
    light: lime[100],
    main: lime[400],
    dark: lime[500],
    contrastText: "#fff",
  },
  yellow: {
    light: yellow[300],
    main: yellow[700],
    dark: yellow[800],
    contrastText: "#fff",
  },
  amber: {
    light: amber[100],
    main: amber[400],
    dark: amber[500],
    contrastText: "#fff",
  },
  deepOrange: {
    light: deepOrange[100],
    main: deepOrange[400],
    dark: deepOrange[500],
    contrastText: "#fff",
  },
  grey: {
    light: grey[100],
    main: grey[400],
    dark: grey[500],
    contrastText: "#fff",
  },
  blueGrey: {
    light: blueGrey[100],
    main: blueGrey[400],
    dark: blueGrey[500],
    contrastText: "#fff",
  },
};

export const errorColors = {
  red: { main: red[500] },
  pink: { main: pink[500] },
  purple: { main: purple[500] },
  indigo: { main: indigo[500] },
  deepPurple: { main: deepPurple[500] },
  blue: { main: blue[500] },
  green: { main: green[500] },
  orange: { main: orange[500] },
  lightBlue: { main: lightBlue[500] },
  cyan: { main: cyan[500] },
  teal: { main: teal[500] },
  lightGreen: { main: lightGreen[500] },
  lime: { main: lime[500] },
  yellow: { main: yellow[500] },
  amber: { main: amber[500] },
  deepOrange: { main: deepOrange[500] },
  grey: { main: grey[500] },
  blueGrey: { main: blueGrey[500] },
  brown: { main: brown[500] },
};
