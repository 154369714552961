const object_to_id = (list_of_item) => {
  return list_of_item.map((obj) => obj.id);
};

const id_to_object = (list_of_id, byId_Mapping) => {
  return list_of_id.map((id) => {
    // if (byId_Mapping[id] === undefined)
    //   throw `Mapping error: id:"${id}" does not found`;
    return byId_Mapping[id];
  });
};

const noramlize_according_the_field = (item, field_array) => {
  for (let field of field_array) {
    if (!item.hasOwnProperty(field))
      throw `Normailize error: The item does not own a field "${field}"`;
    item[field] = object_to_id(item[field]);
  }
};

const normalize_if_the_field_is_array_or_object = (item) => {
  const normalized_item = {};
  for (let key of Object.keys(item)) {
    if (Array.isArray(item[key]))
      normalized_item[key] = object_to_id(item[key]);
    else if (item[key] !== null && typeof item[key] === "object")
      normalized_item[key] = item[key].id;
    else normalized_item[key] = item[key];
  }
  return normalized_item;
};

const is_key_in_fields = (key, fields) => {
  for (let field of fields) {
    if (key === field) return true;
  }
  return false;
};

export const normalize = (item_array) =>
  item_array.map((item) => normalize_if_the_field_is_array_or_object(item));

export const normalize_object = (obj) =>
  normalize_if_the_field_is_array_or_object(obj);

export const normalize_by_field = (item_array, field_array) => {
  return item_array.map((item) => {
    noramlize_according_the_field(item, field_array);
    return item;
  });
};

export const joinState = (field, byId_Mapping) => (state) => {
  return state.map((item) => {
    if (item[field] === undefined)
      throw `Join error: The item does not own a field "${field}"`;
    else if (Array.isArray(item[field]))
      return { ...item, [field]: id_to_object(item[field], byId_Mapping) };
    else return { ...item, [field]: byId_Mapping[item[field]] };
  });
};

export const seperateState = (state, payload, id_field_name = "id") => {
  state.allIds = payload.map((obj) => obj[id_field_name]);
  state.byId = payload.reduce((accumulate, obj) => {
    accumulate[obj[id_field_name]] = obj;
    return accumulate;
  }, {});
};

export const combineState = (state) => {
  return state.allIds.map((id) => state.byId[id]);
};

export const annontate = (new_field, func) => (state) => {
  return state.map((item) => {
    return { ...item, [new_field]: func(item) };
  });
};

export const is_loading = (...states) =>
  states.reduce((prev, state) => prev || state.loading, false);

export const remove_unreleated_field = (obj, fields) =>
  Object.keys(obj).reduce((result, key) => {
    if (is_key_in_fields(key, fields)) {
      result[key] = obj[key];
      return result;
    }
    return result;
  }, {});

export const multiple_sort = (fields) => (array) =>
  array.sort((a, b) => {
    for (let field of fields) {
      let compare = a[field].localeCompare(b[field]);
      if (compare !== 0) return compare;
    }
    return 0;
  });

/* 
  functions for annontate
*/
export const list_of_obj_to_str = (field, name) => (item) =>
  item[field].map((item) => item[name]).join(", ");

export const extract_field =
  (field, obj_field, null_name = "") =>
  (item) => {
    if (item[field] === undefined || item[field] === null) return null_name;
    return item[field][obj_field];
  };

export const extract_field_byMapping =
  (field, obj_field, byIdMapping, null_name = "") =>
  (item) => {
    if (!item[field]) return null_name;
    else return byIdMapping[item[field]][obj_field];
  };

export const format_datetime = (date_field) => (item) => {
  const date = new Date(item[date_field]);
  return date.toLocaleString();
};

export const format_action = (action_field) => (item) => {
  const action = item[action_field];
  if (action === "+") {
    return "Create";
  } else if (action === "-") {
    return "Delete";
  } else if (action === "~") {
    return "Modify";
  } else {
    return null;
  }
};

export const count = (field) => (item) => item[field].length;
