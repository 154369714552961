import React, { useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useForm from "../../hooks/useForm";
import { validate } from "../../validator/common";
import { Box } from "@mui/material";

export default function CreateDialog(props) {
  const { columns, values, setValues, open, handleClose, schema, targetName } =
    props;
  const [errors, setErrors, renderField] = useForm(values, setValues, schema);

  const renderEditField = (column) => (
    <Box key={column.field} sx={{ marginTop: 1, marginBottom: 3 }}>
      {renderField(column)}
    </Box>
  );

  const onClose = (isSave) => {
    setErrors({});
    handleClose(isSave);
  };

  const disableButton = useMemo(
    () => Boolean(validate(values, schema)),
    [values, schema]
  );

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => {}} fullWidth>
        <DialogTitle>Create {targetName}</DialogTitle>
        <DialogContent>
          {columns.map((column) =>
            renderEditField({ ...column, fullWidth: true })
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => onClose(true)}
            disabled={disableButton}
          >
            Create
          </Button>
          <Button color="error" onClick={() => onClose(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
