import { useState } from "react";
import { useDispatch } from "react-redux";

export default function useDeleteAlert(
  dispatchDeleteFunction,
  id_field = "id"
) {
  const dispatch = useDispatch();
  const [alertMode, setAlertMode] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});

  const handleOpen = (obj) => {
    setAlertMode(true);
    setSelectedObj(obj);
  };

  const handleClose = (isConfirm) => {
    setAlertMode(false);
    if (isConfirm) dispatch(dispatchDeleteFunction(selectedObj[id_field]));
  };

  return [alertMode, selectedObj, handleOpen, handleClose];
}
