import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { pipe } from "lodash/fp";
import {
  annontate,
  extract_field,
  format_action,
  format_datetime,
} from "../normalize";

const endpoint = endpoints["performanceHistory"];

const slice = createSlice({
  name: "performanceHistory",
  initialState: {
    data: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    performanceHistoryListRequest: (performanceHistory, action) => {
      performanceHistory.loading = true;
    },
    performanceHistoryListReceived: (performanceHistory, { payload }) => {
      performanceHistory.data = payload;
      performanceHistory.loading = false;
    },
  },
});

const { performanceHistoryListRequest, performanceHistoryListReceived } =
  slice.actions;

// action
export const getPerformanceHistoryList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: performanceHistoryListRequest.type,
    onSuccess: performanceHistoryListReceived.type,
  });

// selector
export const getPerformanceHistory = (state) =>
  state.entities.performanceHistory.data;

export const getPerformanceHistoryDisplay = (state) => {
  const history_display = pipe(
    annontate("username", extract_field("history_user", "username")),
    annontate("is_student", extract_field("history_user", "is_student")),
    annontate("is_teacher", extract_field("history_user", "is_teacher")),
    annontate("history_date", format_datetime("history_date")),
    annontate("history_type", format_action("history_type"))
  );
  return history_display(state.entities.performanceHistory.data);
};

export const getPerformanceHistoryLoadingStatus = (state) =>
  state.entities.performanceHistory.loading;

export default slice.reducer;
