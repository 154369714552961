import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import styled from "styled-components";

export default function BagStamp({ stamp, number }) {
  const [flipped, setFlipped] = React.useState(false);

  return (
    <>
      {stamp && (
        <Card
          onClick={() => {
            setFlipped((prevFlip) => !prevFlip);
          }}
          onMouseLeave={() => {
            setFlipped(false);
          }}
          sx={{
            margin: "10px 15px",
            width: "200px",
            height: "auto",
            display: "inline-block",
            background:
              "radial-gradient(circle, rgba(196,230,255,0.7189469537815126) 0%, rgba(187,238,251,0.9402354691876751) 52%, rgba(84,174,240,0.7189469537815126) 93%)",
            boxShadow: "10px 5px 5px rgba(0, 0, 0, 0.35)",
            borderRadius: "15px",
            transform: "rotateY(0deg) scale(1)",
            transition: "0.3s",
            "&:hover": {
              transform: flipped ? "scale(1.1) rotateY(180deg)" : "scale(1.1)",
              boxShadow: "none",
              border: "2.5px solid Tomato",
              boxShadow: "rgba(0, 0, 0, 0.35) 5px 5px 15px",
              transition: "0.3s",
            },
          }}
        >
          <CardContent>
            <Box
              sx={{
                marginTop: "10px",
                display: flipped ? "none" : "block",
                height: "250px",
              }}
            >
              <CardMedia
                component="img"
                image={stamp.image}
                alt={stamp.description}
              />
              <Typography
                gutterBottom
                variant="h5"
                sx={{ marginTop: 1, marginBottom: 0 }}
              >
                {stamp.name}
              </Typography>
              {number !== null && (
                <Typography
                  sx={{ textAlign: "right", width: "100%", fontSize: "25px" }}
                >{`X${number}`}</Typography>
              )}
            </Box>

            <Box
              sx={{
                marginTop: "10px",
                display: !flipped ? "none" : "block",
                transform: `rotateY(180deg)`,
                height: "250px",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                {stamp.name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ whiteSpace: "pre-line" }}
              >
                {stamp.description}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
}
