import * as React from "react";

import Bag from "./Bag";
import Collection from "./Collection";
import useTab from "../../hooks/useTab";
import TabWithContent from "../../components/common/TabWithContent";

export default function StudentProfile() {
  const [tab, handleTabChange] = useTab("view", "studentMyBag");

  const pages = [
    {
      label: "Stamps",
      component: <Bag />,
      route: "bag",
    },
    {
      label: "Collection",
      component: <Collection />,
      route: "collection",
    },
  ];

  return (
    <TabWithContent pages={pages} tab={tab} handleTabChange={handleTabChange} />
  );
}
