import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Avatar,
  Dialog,
  DialogContent,
} from "@mui/material";
import { uploadUserPhoto } from "./userSlice";
import ImageCrop from "../../components/ImageCrop/ImageCrop";

export default function UserProfile() {
  const dispatch = useDispatch();

  // const [width, setWidth] = React.useState(window.innerWidth);
  // const breakpoint = 1150;

  // React.useEffect(() => {
  //   window.addEventListener("resize", () => setWidth(window.innerWidth));
  // }, [width]);

  const [photoUploadName, setPhotoUploadName] = React.useState("");
  const [openImageDialog, setOpenImageDialog] = React.useState(false);
  const {
    email,
    userName,
    profile,
    first_name,
    last_name,
    is_teacher,
    is_student,
  } = useSelector((state) => state.user);
  const ImageRef = React.useRef();

  const profileDetails = profile
    ? [
        {
          name: "Name",
          detail:
            first_name &&
            last_name &&
            first_name.length > 0 &&
            last_name.length > 0
              ? first_name + " " + last_name
              : "N/A",
        },
        {
          name: "User Name",
          detail: userName && userName.length > 0 ? userName : "N/A",
        },
        {
          name: "Date of Birth",
          detail:
            profile.date_of_birth && profile.date_of_birth.length > 0
              ? profile.date_of_birth
              : "N/A",
        },
        {
          name: "Gender",
          detail:
            profile.gender && profile.gender.length > 0
              ? profile.gender
              : "N/A",
        },
        {
          name: "Phone",
          detail:
            profile.phone && profile.phone.length > 0 ? profile.phone : "N/A",
        },
        {
          name: "Email",
          detail: email && email.length > 0 ? email : "N/A",
        },
        {
          name: "Primary Contact Name",
          detail:
            profile.primary_contact_name &&
            profile.primary_contact_name.length > 0
              ? profile.primary_contact_name
              : "N/A",
        },
        {
          name: "Primary Contact Relationship",
          detail:
            profile.primary_contact_relationship &&
            profile.primary_contact_relationship.length > 0
              ? profile.primary_contact_relationship
              : "N/A",
        },
        {
          name: "Primary Contact Number",
          detail:
            profile.primary_contact_number &&
            profile.primary_contact_number.length > 0
              ? profile.primary_contact_number
              : "N/A",
        },
        {
          name: "Secondary Contact Name",
          detail:
            profile.secondary_contact_name &&
            profile.secondary_contact_name.length > 0
              ? profile.secondary_contact_name
              : "N/A",
        },
        {
          name: "Secondary Contact Relationship",
          detail:
            profile.secondary_contact_relationship &&
            profile.secondary_contact_relationship.length > 0
              ? profile.secondary_contact_relationship
              : "N/A",
        },
        {
          name: "Secondary Contact Number",
          detail:
            profile.secondary_contact_number &&
            profile.secondary_contact_number.length > 0
              ? profile.secondary_contact_number
              : "N/A",
        },
        is_teacher || is_student
          ? null
          : {
              name: "Remark",
              detail: profile.remark ? profile.remark : "",
            },
      ]
    : null;

  return (
    <>
      {/* display when the info needed for this page get */}
      {profile ? (
        <>
          <Paper>
            <Box
              style={
                // width > breakpoint
                //   ?
                {
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }
                // : {
                //     justifyContent: "space-between",
                //     alignItems: "flex-start",
                //   }
              }
            >
              <Box>
                <Table>
                  <TableBody>
                    {profileDetails.map((item) => {
                      if (
                        item === null ||
                        (item !== null && item.name !== "Remark")
                      ) {
                        return item !== null ? (
                          <TableRow key={Math.random()}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.detail}</TableCell>
                          </TableRow>
                        ) : null;
                      } else {
                        return (
                          <TableRow key={Math.random()}>
                            <TableCell rowSpan={item.detail.length}>
                              {item.name}
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  whiteSpace: "pre-line",
                                  overflow: "auto",
                                  maxHeight: "200px",
                                }}
                              >
                                {item.detail}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </Box>

              <Box sx={{ display: "inline-block" }}>
                {profile.photo ? (
                  <>
                    {/*<img
                      style={{
                        maxWidth: "400px",
                        maxHeight: "400px",
                        marginTop: "10px",
                        display: "block",
                      }}
                      alt=""
                      src={profile.photo}
                    />*/}
                    <Avatar
                      src={profile.photo}
                      sx={{ width: 256, height: 256 }}
                    />
                  </>
                ) : (
                  <div>{"No Photo Available"}</div>
                )}
                {/* component for photo update */}
                <Typography sx={{ marginTop: "20px", display: "block" }}>
                  {`${photoUploadName}`}
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ marginTop: "20px", display: "block" }}
                  onClick={() => {
                    setOpenImageDialog(true);
                  }}
                >
                  {`Change Photo`}
                </Button>
              </Box>
            </Box>
          </Paper>
        </>
      ) : null}
      {profile && (
        <Dialog
          open={openImageDialog}
          onClose={() => {
            setOpenImageDialog(false);
          }}
          fullWidth
        >
          <DialogContent>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Avatar src={profile.photo} sx={{ width: 256, height: 256 }} />

                <ImageCrop
                  handleSubmit={(file) => {
                    dispatch(uploadUserPhoto({ newPhoto: file }));
                    setOpenImageDialog(false);
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
