import React, { useState, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Box, Button } from "@mui/material/";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCrop({ handleSubmit }) {
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const aspect = 1;

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result ? reader.result.toString() : "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement("canvas");
      canvas.width = 300;
      canvas.height = 300;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        imgRef.current,
        (crop.x * imgRef.current.naturalWidth) / 100,
        (crop.y * imgRef.current.naturalHeight) / 100,
        (crop.width * imgRef.current.naturalWidth) / 100,
        (crop.height * imgRef.current.naturalHeight) / 100,
        0,
        0,
        300,
        300
      );

      canvas.toBlob((blob) => {
        const file = new File([blob], "image.jpg", { type: blob.type });
        handleSubmit(file);
        // console.log(file);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "20px",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          component="label"
          sx={{ marginTop: "20px", display: "block" }}
        >
          {"Select Photo"}
          <input hidden type="file" accept="image/*" onChange={onSelectFile} />
        </Button>

        {Boolean(imgSrc) && (
          <Box>
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentageCrop) => {
                setCrop(percentageCrop);
              }}
              aspect={aspect}
              circularCrop
            >
              <img ref={imgRef} alt="" src={imgSrc} onLoad={onImageLoad} />
            </ReactCrop>
          </Box>
        )}
        <Button variant="contained" onClick={getCroppedImg}>
          Crop Photo
        </Button>
      </Box>
    </>
  );
}
