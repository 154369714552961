import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  TextField,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  addLessonModule,
  removeLessonModule,
  updateObjective,
} from "./lessonSlice";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CheckboxLabels({
  programmeId,
  lessonId,
  selectedModule,
  objectives,
  dispatch,
  selectedObjectives,
}) {
  const [objectiveDialogOpen, setObjectiveDialogOpen] = React.useState(false);
  const [currentObjectiveIds, setCurrentObjectiveIds] = React.useState([]);
  const [removedObjective, setRemovedObjective] = React.useState({});
  const commentIds = useSelector((state) =>
    state.lesson.comments.map((comment) => comment.id)
  );

  return (
    <FormGroup>
      <Dialog
        open={objectiveDialogOpen}
        onClose={() => setObjectiveDialogOpen(false)}
      >
        <DialogTitle>{"ALERT"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you confirm to remove objective from this lesson? You may remove
            all the added comments.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setObjectiveDialogOpen(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              dispatch(
                updateObjective({
                  programmeId,
                  lessonId,
                  objectiveIds: currentObjectiveIds.filter(
                    (objectiveId) => objectiveId !== removedObjective.id
                  ),
                  commentIds,
                })
              );
              setObjectiveDialogOpen(false);
            }}
            autoFocus
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
      {objectives.map((objective) => {
        return (
          <FormControlLabel
            key={Math.random()}
            control={<Checkbox />}
            checked={
              selectedObjectives[selectedModule.id]
                ? selectedObjectives[selectedModule.id].has(objective.id)
                : false
            }
            onChange={(event) => {
              const currentObjectiveIds = [
                ...Object.values(selectedObjectives).reduce(
                  (prevSet, currentSet) => new Set([...prevSet, ...currentSet]),
                  new Set()
                ),
              ];

              if (event.target.checked) {
                dispatch(
                  updateObjective({
                    programmeId,
                    lessonId,
                    objectiveIds: [...currentObjectiveIds, objective.id],
                    commentIds,
                  })
                );
              } else {
                setObjectiveDialogOpen(true);
                setCurrentObjectiveIds(currentObjectiveIds);
                setRemovedObjective(objective);
              }
            }}
            label={objective.name}
          />
        );
      })}
    </FormGroup>
  );
}

export default function LessonModules({
  programmeId,
  lessonId,
  selectedObjectives,
}) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState("");
  const [moduleDialogOpen, setModuleDialogOpen] = React.useState(false);
  const [remainingModules, setRemainingModules] = React.useState([]);

  const allModules = useSelector((state) => state.course.modules);
  const selectedModules = useSelector((state) => state.lesson.info.modules);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const removeModule = (programmeId, lessonId, selectedModules) => {
    dispatch(
      removeLessonModule({
        programmeId,
        lessonId,
        selectedModules,
      })
    );
  };

  return (
    <Paper sx={{ margin: 2, padding: 2, minWidth: "500px" }}>
      <Dialog
        open={moduleDialogOpen}
        onClose={() => setModuleDialogOpen(false)}
      >
        <DialogTitle>{"ALERT"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you confirm to remove module from this lesson? You may remove all
            the added objective and comments.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setModuleDialogOpen(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              removeModule(programmeId, lessonId, remainingModules);
              setModuleDialogOpen(false);
            }}
            autoFocus
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
      <Autocomplete
        multiple
        options={allModules}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedModules}
        renderInput={(params) => (
          <TextField {...params} label="Selected modules" />
        )}
        onChange={(event, value, reason) => {
          if (reason === "selectOption") {
            dispatch(
              addLessonModule({
                programmeId,
                lessonId,
                selectedModules: value,
              })
            );
          } else if (reason === "removeOption") {
            setModuleDialogOpen(true);
            setRemainingModules(value);
          }
        }}
      />
      {allModules && allModules.length > 0
        ? selectedModules.map((selectedModule) => {
            const module = allModules.find(
              (module) => module.id === selectedModule.id
            );
            return (
              <Accordion
                expanded={expanded === selectedModule.id}
                onChange={handleChange(selectedModule.id)}
                key={Math.random()}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography sx={{ marginRight: 2 }}>
                    {selectedModule.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CheckboxLabels
                    programmeId={programmeId}
                    lessonId={lessonId}
                    selectedModule={selectedModule}
                    objectives={module.objectives}
                    dispatch={dispatch}
                    selectedObjectives={selectedObjectives}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })
        : null}
    </Paper>
  );
}
