import React, { useState } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  TextField,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch } from "react-redux";
import {
  deleteModulebyId,
  updateModule,
} from "../../../../app/entities/modules";
import DeleteAlertDialog from "../../../../components/common/DeleteAlertDialog";

export default function ModuleListItem(props) {
  const { programme_id, module, open, setOpen } = props;
  const [preModule, setPreModule] = useState(null);
  const [modifiedModule, setModifiedModule] = useState(null);
  const [selectedDelete, setSelectedDelete] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const dispatch = useDispatch();

  const handleOpenClick = (id) => {
    setOpen({ ...open, [id]: !open[id] });
  };

  const handleEditIcon = () => {
    setEditMode(true);
    setPreModule(module);
    setModifiedModule(module);
  };

  const handleTextFieldChange = (event) => {
    setModifiedModule({ ...modifiedModule, name: event.target.value });
  };

  const handleSave = () => {
    dispatch(updateModule(programme_id, preModule, modifiedModule));
    setEditMode(false);
  };

  const handleDeleteClick = (module) => {
    setDeleteMode(true);
    setSelectedDelete(module);
  };

  const handleDeleteClose = (isDelete) => {
    setDeleteMode(false);
    if (isDelete) dispatch(deleteModulebyId(programme_id, selectedDelete.id));
  };

  const renderReadStatus = () => (
    <>
      <ListItemButton onClick={() => handleOpenClick(module.id)}>
        <ListItemIcon>
          <ImportContactsIcon />
        </ListItemIcon>
        <ListItemText>{module.name}</ListItemText>
        <ListItemIcon>
          {open[module.id] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItemIcon>
      </ListItemButton>
      <IconButton onClick={handleEditIcon}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => handleDeleteClick(module)}>
        <DeleteForeverIcon />
      </IconButton>
    </>
  );

  const renderEditStatus = () => (
    <>
      <ImportContactsIcon color="action" sx={{ pl: 2 }} />
      <TextField
        sx={{ pl: 4 }}
        fullWidth
        variant="standard"
        value={modifiedModule.name}
        onChange={handleTextFieldChange}
      />
      <IconButton onClick={handleSave}>
        <SaveIcon />
      </IconButton>
      <IconButton onClick={() => setEditMode(false)}>
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <>
      <ListItem disablePadding>
        {editMode ? renderEditStatus() : renderReadStatus()}
      </ListItem>
      <DeleteAlertDialog
        open={deleteMode}
        handleClose={handleDeleteClose}
        targetName={selectedDelete.name || "This module"}
      />
    </>
  );
}
