import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLesson,
  getOverlappedLessons,
  setAppointmentFormData,
} from "../lessonsSlice";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import ConfirmationDialog from "./ConfirmationDialog";
import { color } from "../../../config";

export default function TooltipLayout({
  appointmentMeta,
  onOpenButtonClick,
  onDeleteButtonClick,
  setFormVisibility,
  setTooltipVisibility,
  ...restProps
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const appointmentData = appointmentMeta
    ? appointmentMeta.data
    : { lessonId: null };
  const appointment = useSelector((state) => state.lessons.lessons).find(
    (lesson) => lesson.id === appointmentData.lessonId
  );

  const removeLesson = () => {
    dispatch(
      deleteLesson({
        lessonId: appointmentData.lessonId,
      })
    );
    setTooltipVisibility((prevVisibility) => !prevVisibility);
  };

  return (
    <>
      <ConfirmationDialog
        action="delete"
        component="Tooltip"
        open={open}
        setOpen={setOpen}
        removeLesson={removeLesson}
        setTooltipVisibility={setTooltipVisibility}
      />
      <AppointmentTooltip.Layout
        appointmentMeta={appointmentMeta}
        onOpenButtonClick={() => {
          dispatch(
            getOverlappedLessons({
              selectedStartTime: appointment.startDate,
              selectedEndTime: appointment.endDate,
            })
          );
          dispatch(
            setAppointmentFormData({
              selectedCategory: appointment.programme.category_name,
              selectedProgramme: appointment.programme.name,
              selectedLevel: appointment.programme.level,
              selectedTeachers: appointment.teachers,
              selectedStudents: appointment.students,
              selectedVenueConditions: [],
              selectedVenue: appointment.venue,
              selectedColor: color.find(
                (color) => color.code === appointment.color
              ),
            })
          );
          setFormVisibility((prevVisibility) => !prevVisibility);
        }}
        onDeleteButtonClick={() => {
          setOpen(true);
        }}
        {...restProps}
      />
    </>
  );
}
