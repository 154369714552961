import React from "react";

import UserTablePage from "./UserTablePage";
import StudentTablePage from "./StudentTablePage";
import TeacherTablePage from "./TeacherTablePage";
import VenueTablePage from "./VenueTablePage";
import TabWithContent from "../../../components/common/TabWithContent";
import StampAdditionPage from "./StampAdditionPage";
import useTab from "../../../hooks/useTab";

export default function ResoursePanelPage() {
  const [tab, handleTabChange] = useTab("table", "resoursePanel");

  const pages = [
    {
      label: "User",
      component: <UserTablePage />,
      route: "user",
    },
    {
      label: "Student",
      component: <StudentTablePage />,
      route: "student",
    },
    {
      label: "Teacher",
      component: <TeacherTablePage />,
      route: "teacher",
    },
    {
      label: "Venue",
      component: <VenueTablePage />,
      route: "venue",
    },
    {
      label: "Stamp",
      component: <StampAdditionPage />,
      route: "stamp",
    },
  ];

  return (
    <TabWithContent pages={pages} tab={tab} handleTabChange={handleTabChange} />
  );
}
