import * as React from "react";
import { useSelector } from "react-redux";
import { Box, Paper, Typography, Link } from "@mui/material";

export default function LessonInfo({ lesson, courseInfo }) {
  const isStudent = useSelector((state) => state.user.is_student);
  const teachers =
    lesson.teachers.length > 0
      ? lesson.teachers.map(
          (teacher) => `${teacher.first_name} ${teacher.last_name}`
        )
      : "TBC";
  const startDate = new Date(lesson.start_datetime);
  const endDate = new Date(lesson.end_datetime);
  const date = startDate.toLocaleDateString();
  const startTime = startDate.toLocaleString("zh-HK", {
    hour12: false,
    timeStyle: "short",
  });
  const endTime = endDate.toLocaleString("zh-HK", {
    hour12: false,
    timeStyle: "short",
  });

  return (
    <Paper sx={{ margin: 2, padding: 2, minWidth: "500px" }}>
      <Typography variant="h4">Basic Info</Typography>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "inline-block", marginRight: 1 }}>
          <Typography>{"Category: "}</Typography>
          <Typography>{"Programme: "}</Typography>
          <Typography>{"Level: "}</Typography>
          <Typography>{"Teacher(s): "}</Typography>
          <Typography>{"Date: "}</Typography>
          <Typography>{"Time: "}</Typography>
          <Typography>{"Venue: "}</Typography>
          {isStudent && <Typography>{"Location: "}</Typography>}
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <Typography>{courseInfo.category}</Typography>
          <Typography>{courseInfo.programme || "N/A"}</Typography>
          <Typography>{courseInfo.level || "N/A"}</Typography>
          <Typography>
            {teachers === "TBC" ? teachers : teachers.join(", ")}
          </Typography>
          <Typography>{date}</Typography>
          <Typography>{`${startTime} - ${endTime}`}</Typography>
          <Typography>{lesson.venue ? lesson.venue.name : "TBC"}</Typography>
          {isStudent && (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography>
                {lesson.venue ? lesson.venue.location : "TBC"}
              </Typography>
              {lesson.venue !== null && (
                <Link
                  href={`https://www.google.com/maps/search/${encodeURIComponent(
                    lesson.venue.location
                  )}/`}
                >
                  View location
                </Link>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
