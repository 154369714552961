export function nonEmptyString(string) {
  if (string && string.trim() !== "") {
    return true;
  }
  return false;
}

export function isValidEmail(string) {
  const emailFormat = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]+");
  return emailFormat.test(string);
}
