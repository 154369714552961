import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";

export default function InfoDialog(props) {
  const { columns, values, targetName, open, handleClose } = props;

  const onClose = () => {
    handleClose();
  };

  const renderTextField = ({ field, headerName, emptyName, type }) => (
    <TextField
      fullWidth
      variant="standard"
      key={field}
      id={field}
      label={headerName}
      value={values[field] || emptyName || "(empty)"}
      type={type}
      disabled
    />
  );

  return (
    <Dialog open={open} onClose={() => {}} fullWidth>
      <DialogTitle>{targetName} Detail</DialogTitle>
      <DialogContent>
        {columns.map((column, index) => (
          <Box key={index}  sx={{ marginTop: 1, marginBottom: 3 }}>
            {renderTextField(column)}
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
