import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Typography,
  TextField,
  Fab,
  Dialog,
  Button,
  IconButton,
  DialogTitle,
} from "@mui/material";
import {
  addObjective,
  modifyModule,
  modifyObjective,
  deleteObjective,
  addModule,
  deleteModule,
} from "./courseSlice";
import { toast } from "react-toastify";
import { Box } from "@mui/system";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function Module({ module, programmeId, expanded, handleChange }) {
  const is_superuser = useSelector((state) => state.user.is_superuser);
  const [openObjectiveDialog, setOpenObjectiveDialog] = React.useState(false);
  const [modifyingObjective, setModifyingObjective] = React.useState({});
  const modifyObjectiveRef = React.useRef();
  const newObjectiveRef = React.useRef();
  const dispatch = useDispatch();

  const [openModuleDialog, setOpenModuleDialog] = React.useState(false);
  const modifyModuleRef = React.useRef();
  const [openDeleteModuleDialog, setOpenDeleteModuleDialog] =
    React.useState(false);

  return (
    <>
      {ModuleAccordion(
        expanded,
        module,
        handleChange,
        setOpenModuleDialog,
        is_superuser,
        setOpenDeleteModuleDialog,
        setModifyingObjective,
        setOpenObjectiveDialog,
        newObjectiveRef,
        dispatch,
        programmeId
      )}

      {ObjectiveDialog(
        openObjectiveDialog,
        setOpenObjectiveDialog,
        modifyObjectiveRef,
        modifyingObjective,
        dispatch,
        programmeId,
        module,
        setModifyingObjective,
        is_superuser
      )}

      {ModuleDialog(
        openModuleDialog,
        setOpenModuleDialog,
        modifyModuleRef,
        module,
        dispatch,
        programmeId
      )}

      {DeleteModuleDialog(
        openDeleteModuleDialog,
        setOpenDeleteModuleDialog,
        module,
        dispatch,
        programmeId
      )}
    </>
  );
}

function ModuleAccordion(
  expanded,
  module,
  handleChange,
  setOpenModuleDialog,
  is_superuser,
  setOpenDeleteModuleDialog,
  setModifyingObjective,
  setOpenObjectiveDialog,
  newObjectiveRef,
  dispatch,
  programmeId
) {
  return (
    <Accordion expanded={expanded === module} onChange={handleChange(module)}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography>{module.name}</Typography>
        </Box>
        <Box sx={{ marginLeft: "auto" }}>
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setOpenModuleDialog(true);
            }}
          >
            <EditIcon />
          </IconButton>
          {is_superuser && (
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setOpenDeleteModuleDialog(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {module.objectives.map((objective) => (
          <Box key={Math.random()}>
            <Button
              sx={{
                display: "inline-block",
                marginRight: 2,
                cursor: "pointer",
                textTransform: "none",
                color: "black",
                "&:hover": { transform: "scale(1.05)" },
              }}
              onClick={() => {
                setModifyingObjective(objective);
                setOpenObjectiveDialog(true);
              }}
            >
              {objective.name}
            </Button>
          </Box>
        ))}
        <TextField
          id="standard-basic"
          label="New Objective"
          variant="standard"
          inputRef={newObjectiveRef}
        />
        {AddObjectiveFab(module, newObjectiveRef, dispatch, programmeId)}
      </AccordionDetails>
    </Accordion>
  );
}

function AddObjectiveFab(module, newObjectiveRef, dispatch, programmeId) {
  return (
    <Fab
      color="primary"
      aria-label="add"
      size="small"
      onClick={() => {
        if (
          module.objectives.find(
            (objective) =>
              objective.name === newObjectiveRef.current.value.trim()
          )
        ) {
          toast.error("Already exist!!!");
        } else {
          dispatch(
            addObjective({
              programmeId,
              moduleId: module.id,
              objectiveName: newObjectiveRef.current.value.trim(),
            })
          );
        }
      }}
    >
      <AddIcon />
    </Fab>
  );
}

function DeleteModuleDialog(
  openDeleteModuleDialog,
  setOpenDeleteModuleDialog,
  module,
  dispatch,
  programmeId
) {
  return (
    <Dialog
      fullWidth
      open={openDeleteModuleDialog}
      onClose={() => setOpenDeleteModuleDialog(false)}
    >
      <DialogTitle>{`Delete Module ${module.name} ?`}</DialogTitle>
      <Box
        sx={{
          padding: 3,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <Button
          onClick={() => {
            dispatch(deleteModule({ programmeId, moduleId: module.id }));
            setOpenDeleteModuleDialog(false);
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setOpenDeleteModuleDialog(false);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}

function ModuleDialog(
  openModuleDialog,
  setOpenModuleDialog,
  modifyModuleRef,
  module,
  dispatch,
  programmeId
) {
  return (
    <Dialog
      fullWidth
      open={openModuleDialog}
      onClose={() => setOpenModuleDialog(false)}
    >
      <DialogTitle>Modify Module</DialogTitle>
      <Box
        sx={{
          padding: 3,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          justifyContent: "center",
        }}
      >
        <TextField
          fullWidth
          label="Module Name"
          inputRef={modifyModuleRef}
          defaultValue={module.name}
        />
        <Button
          onClick={() => {
            if (modifyModuleRef.current.value) {
              dispatch(
                modifyModule({
                  programmeId,
                  moduleId: module.id,
                  moduleName: modifyModuleRef.current.value.trim(),
                })
              );
              setOpenModuleDialog(false);
            }
          }}
        >
          Rename
        </Button>
      </Box>
    </Dialog>
  );
}

function ObjectiveDialog(
  openObjectiveDialog,
  setOpenObjectiveDialog,
  modifyObjectiveRef,
  modifyingObjective,
  dispatch,
  programmeId,
  module,
  setModifyingObjective,
  is_superuser
) {
  const [openDeleteObjectiveDialog, setOpenDeleteObjectiveDialog] =
    React.useState(false);

  return (
    <>
      <Dialog
        fullWidth
        open={openObjectiveDialog}
        onClose={() => setOpenObjectiveDialog(false)}
      >
        <DialogTitle>Modify Objective</DialogTitle>
        <Box
          sx={{
            padding: 3,
            paddingTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          <TextField
            fullWidth
            label="Objective Name"
            inputRef={modifyObjectiveRef}
            defaultValue={modifyingObjective.name}
          />

          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Button
              onClick={() => {
                setOpenObjectiveDialog(false);
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                if (modifyObjectiveRef.current.value) {
                  dispatch(
                    modifyObjective({
                      programmeId,
                      moduleId: module.id,
                      objectiveName: modifyObjectiveRef.current.value.trim(),
                      objectiveId: modifyingObjective.id,
                    })
                  );
                  setModifyingObjective({});
                  setOpenObjectiveDialog(false);
                }
              }}
            >
              Rename
            </Button>

            {is_superuser && (
              <Button
                onClick={() => {
                  setOpenDeleteObjectiveDialog(true);
                }}
              >
                Delete
              </Button>
            )}
          </Box>
        </Box>
      </Dialog>

      {DeleteObjectiveDialog(
        setOpenObjectiveDialog,
        openDeleteObjectiveDialog,
        setOpenDeleteObjectiveDialog,
        modifyingObjective,
        dispatch,
        programmeId,
        module
      )}
    </>
  );
}

function DeleteObjectiveDialog(
  setOpenObjectiveDialog,
  openDeleteObjectiveDialog,
  setOpenDeleteObjectiveDialog,
  modifyingObjective,
  dispatch,
  programmeId,
  module
) {
  return (
    <Dialog
      fullWidth
      open={openDeleteObjectiveDialog}
      onClose={() => {
        setOpenObjectiveDialog(false);
        setOpenDeleteObjectiveDialog(false);
      }}
    >
      <DialogTitle>{`Delete Objective ${modifyingObjective.name} ?`}</DialogTitle>
      <Box
        sx={{
          padding: 3,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <Button
          onClick={() => {
            dispatch(
              deleteObjective({
                programmeId,
                moduleId: module.id,
                objectiveId: modifyingObjective.id,
              })
            );
            setOpenObjectiveDialog(false);
            setOpenDeleteObjectiveDialog(false);
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setOpenDeleteObjectiveDialog(false);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}

export default function Modules({ courseId }) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState("");
  const modules = useSelector((state) => state.course.modules);
  const newModuleRef = React.useRef();
  const sortedModules = [...modules].sort((module1, module2) => {
    return module1.name.localeCompare(module2.name);
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {sortedModules.map((module) => {
        return (
          <Module
            key={Math.random()}
            module={module}
            programmeId={courseId}
            expanded={expanded}
            handleChange={handleChange}
          />
        );
      })}
      <TextField
        id="standard-basic"
        label="New Module"
        variant="standard"
        inputRef={newModuleRef}
      />
      {AddModuleFab(modules, newModuleRef, dispatch, courseId)}
    </>
  );
}
function AddModuleFab(modules, newModuleRef, dispatch, courseId) {
  return (
    <Fab
      color="primary"
      aria-label="add"
      size="small"
      onClick={() => {
        if (
          modules.find((module) => module.name === newModuleRef.current.value)
        ) {
          toast.error("Already exist!!!");
        } else if (newModuleRef.current.value) {
          dispatch(
            addModule({
              programmeId: courseId,
              moduleName: newModuleRef.current.value.trim(),
            })
          );
          newModuleRef.current.value = "";
        }
      }}
    >
      <AddIcon />
    </Fab>
  );
}
