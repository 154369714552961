import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "./userSlice";
import { Paper, Backdrop, CircularProgress } from "@mui/material";
import StudentInfo from "../student/StudentInfo";
import StudentProfileEditing from "../student/StudentProfileEditing";
import UserTeacherInfo from "./UserTeacherInfo";
import UserTeacherProfileEditing from "./UserTeacherProfileEditing";
import UserProfile from "./UserProfile";
import UserProfileReset from "./UserProfileReset";
import UserSetting from "./UserSetting";
import TabWithContent from "../../components/common/TabWithContent";
import useTab from "../../hooks/useTab";

export default function UserPage() {
  const [tab, handleTabChange] = useTab("view", "userProfile");
  const { is_teacher, is_student, userId, isLoading } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const studentPage = {
    label: "Student Profile",
    component: (
      <Paper sx={{ margin: 1, padding: 1, marginTop: "20px" }}>
        <StudentInfo studentUserId={userId} />
      </Paper>
    ),
    route: "studentProfile",
  };

  const teacherPage = {
    label: "Teacher Profile",
    component: (
      <Paper sx={{ margin: 1, padding: 1, marginTop: "20px" }}>
        <UserTeacherInfo />
        <UserTeacherProfileEditing />
      </Paper>
    ),
    route: "teacherProfile",
  };

  const pages = [
    {
      label: "User Profile",
      component: (
        <>
          <UserProfile />
          <UserProfileReset />
        </>
      ),
      route: "userProfile",
    },
    {
      label: "User Settings",
      component: <UserSetting />,
      route: "setting",
    },
  ];

  if (is_student) pages.push(studentPage);
  if (is_teacher) pages.push(teacherPage);

  return !isLoading ? (
    <TabWithContent pages={pages} tab={tab} handleTabChange={handleTabChange} />
  ) : (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
