import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getModuleHistoryList } from "../../../app/entities/moduleHistory";
import ModuleHistoryTable from "../../../components/Table/ModuleHistoryTable";
import ModuleHistoryInfoDialog from "../../../components/InfoDialog/ModuleHistoryInfoDialog";

export default function ModuleHistoryPage() {
  const [viewDetailMode, setViewDetailMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getModuleHistoryList());
  }, [dispatch]);

  const handleEditOpen = (moduleHistory) => {
    setViewDetailMode(true);
    setSelectedUser(moduleHistory);
  };

  const handleEditClose = () => {
    setViewDetailMode(false);
  };

  return (
    <React.Fragment>
      <ModuleHistoryTable handleEditOpen={handleEditOpen} />
      <ModuleHistoryInfoDialog
        values={selectedUser}
        open={viewDetailMode}
        handleClose={handleEditClose}
      />
    </React.Fragment>
  );
}
