import { useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

export default function TableToolBar({
  tableName = "table",
  handleCreateRecord,
  searchValue,
  setSearchValue,
}) {
  const [search, setSearch] = useState("");
  const handleSearchChange = (event) => {
    if (setSearchValue) setSearchValue(event.target.value);
    else setSearch(event.target.value);
  };
  return (
    <GridToolbarContainer>
      {handleCreateRecord !== undefined && (
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateRecord}
        >
          Add record
        </Button>
      )}
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fileName: tableName + "_" + new Date().toLocaleString(),
        }}
      />
      <GridToolbarQuickFilter sx={{ marginLeft: 2 }} />
    </GridToolbarContainer>
  );
}
