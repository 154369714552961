import * as React from "react";
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateMySettings } from "../../user/userSlice";

const startTimeList = [];
for (let i = 0; i < 24; i++) {
  startTimeList.push(i);
}

export default function FlexibleSpace({ setFormVisibility }) {
  const dispatch = useDispatch();
  const isSuperUser = useSelector((state) => state.user.is_superuser);
  const mySettings = useSelector((state) => state.user.mySettings);

  const handleChange = (event) => {
    dispatch(
      updateMySettings({ ...mySettings, startTime: event.target.value })
    );
  };

  return (
    <>
      {isSuperUser && (
        <Button
          variant="outlined"
          sx={{ marginLeft: "15px" }}
          onClick={() => {
            setFormVisibility((prevVisibility) => !prevVisibility);
          }}
        >
          ADD LESSON
        </Button>
      )}
      <Box
        style={{
          marginLeft: "auto",
          marginRight: "10px",
          minWidth: "140px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="start-time">START TIME</InputLabel>
          <Select
            autoWidth
            labelId="start-time"
            value={mySettings.startTime || ""}
            onChange={handleChange}
          >
            {startTimeList.map((startTime) => (
              <MenuItem key={Math.random()} value={startTime}>
                {startTime}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
