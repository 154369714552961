import * as React from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { giveComment } from "../lessonSlice";

export default function CommentTextField({ studentComment }) {
  const isSuperUser = useSelector((state) => state.user.is_superuser);
  const present =
    useSelector((state) => state.lesson.comments).find(
      (comment) => comment.id === studentComment.id
    ).status === "Y";
  const initialComment = useSelector(
    (state) =>
      state.lesson.comments.find((comment) => comment.id === studentComment.id)
        .remark
  );
  const [comment, setComment] = React.useState(initialComment);
  const dispatch = useDispatch();

  return (
    <TextField
      multiline
      fullWidth
      disabled={!present || isSuperUser}
      value={comment || ""}
      onChange={(event) => setComment(event.target.value)}
      onBlur={(event) => {
        dispatch(
          giveComment({
            remark: event.target.value.trim(),
            commentId: studentComment.id,
          })
        );
      }}
    />
  );
}
