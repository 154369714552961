import { createSlice, createSelector } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { combineState, seperateState } from "../normalize";

const endpoint = endpoints["students"];

const slice = createSlice({
  name: "students",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    studentListRequest: (students, action) => {
      students.loading = true;
    },
    studentListReceived: (students, { payload }) => {
      seperateState(students, payload, "user");
      students.loading = false;
    },
    studentRequest: (students, action) => {
      students.loading = true;
    },
    studentReceived: (students, { payload }) => {
      students.allIds.push(payload.user);
      students.byId[payload.user] = payload;
      students.loading = false;
    },
  },
});

const {
  studentListRequest,
  studentListReceived,
  studentRequest,
  studentReceived,
} = slice.actions;

// action
export const getStudentList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: studentListRequest.type,
    onSuccess: studentListReceived.type,
  });

export const retrieveStudent = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "get",
    onStart: studentRequest.type,
    onSuccess: studentReceived.type,
  });

// selector
export const getStudents = (state) => combineState(state.entities.students);

export const getStudentsDetail = createSelector(
  (state) => state.entities.users,
  (state) => state.entities.students,
  (users, students) =>
    students.allIds.map((id) => {
      return { ...students.byId[id], ...users.byId[id] };
    })
);

export const getStudentLoadingStatus = (state) =>
  state.entities.students.loading;

export const getStudentbyId = (id) => (state) =>
  state.entities.students.byId[id];

export default slice.reducer;
