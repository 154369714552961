import React from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";

import Table from "../common/Table";
import { getVenuesDetail } from "../../app/entities/venues";
import { getVenueLoadingStatus } from "../../app/entities/venues";

export default function VenueTable(props) {
  const { handleEditOpen, handleDeleteOpen, handleCreateRecord } = props;

  const venues = useSelector(getVenuesDetail);
  const loading = useSelector(getVenueLoadingStatus);

  const columns = [
    {
      field: "name",
      headerName: "Venue Name",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 2.5,
    },
    {
      field: "display_conditions",
      headerName: "Conditions",
      flex: 1.5,
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      getActions: ({ row }) => {
        const handleEdit = () => {
          handleEditOpen(row);
        };
        const handleDelete = () => {
          handleDeleteOpen(row);
        };
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEdit}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDelete}
          />,
        ];
      },
    },
  ];

  return (
    <Table
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      rows={venues}
      columns={columns}
      loading={loading}
      tableName="venue"
      handleCreateRecord={handleCreateRecord}
    />
  );
}
