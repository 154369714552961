import React from "react";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";

import MyAppBar from "./features/courses/MyAppBar";
import Courses from "./features/courses/Courses";
import CoursePage from "./features/course/CoursePage";
import LessonsPage from "./features/lessons/LessonsPage";
import LessonDetail from "./features/lesson/LessonDetail";
import StudentProfile from "./features/student/StudentProfile";
import TeacherProfile from "./features/teacher/TeacherProfile";
import TimeTable from "./features/lessons/TimeTablePage";
import LoginPage from "./features/user/LoginPage";
import ForgotPasswordPage from "./features/user/ForgotPasswordPage";
import ResetNewPassword from "./features/user/ResetNewPassword";
import ResoursePanel from "./features/admin/ResourcesPannel/ResoursePanelPage";
import CoursePanel from "./features/admin/CoursePannel/CoursePanelPage";
import AuditPanel from "./features/admin/AuditPannel/AuditPanelPage";
import UserPage from "./features/user/UserPage";
import BagPage from "./features/bag/BagPage";
import { errorColors, primaryColors } from "./color";
import { paths } from "./config";

axios.defaults.baseURL = process.env.REACT_APP_URL;

function App() {
  const { primaryColor, errorColor } = useSelector(
    (state) => state.user.mySettings
  );

  const { is_superuser, is_teacher, is_student } = useSelector(
    (state) => state.user
  );

  const theme = createTheme({
    palette: {
      primary: primaryColors[primaryColor || "green"],
      error: errorColors[errorColor || "red"],
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <StyledComponentThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<ResetNewPassword />}
          />
          <Route element={<MyAppBar />}>
            {is_superuser && (
              <>
                <Route path="resoursePanel" element={<ResoursePanel />} />
                <Route path="coursePanel" element={<CoursePanel />} />
                <Route path="auditPanel" element={<AuditPanel />} />
              </>
            )}
            <Route path="lessons" element={<LessonsPage />} />
            <Route path="courses" element={<Courses />} />
            <Route
              path={paths["course"](":courseId")}
              element={<CoursePage />}
            />
            <Route
              path={paths["lessonDetail"](":programmeId", ":lessonId")}
              element={<LessonDetail />}
            />
            <Route
              path={paths["studentProfile"](":studentUserId")}
              element={<StudentProfile />}
            />
            <Route
              path={paths["teacherProfile"](":teacherUserId")}
              element={<TeacherProfile />}
            />
            <Route path="timeTable" element={<TimeTable />} />
            <Route
              path={paths["bag"](":studentUserId")}
              element={<BagPage />}
            />
            <Route path="userPage" element={<UserPage />} />
          </Route>
        </Routes>
      </StyledComponentThemeProvider>
    </ThemeProvider>
  );
}

export default App;
