import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";

import CategoryMenu from "./Menu/CategoryMenu";
import { getCategoryList } from "../../../app/entities/categories";
import { getProgrammeList } from "../../../app/entities/programmes";
import { getUserList } from "../../../app/entities/users";
import { getVenueConditionList } from "../../../app/entities/venueConditions";
import ProgrammeInfo from "./ProgrammeInfo/ProgrammeInfo";
import ModuleInfo from "./ModuleInfo/ModuleInfo";
import SaveReminderDialog from "../../../components/common/SaveReminderDialog";
import DeleteProgrammeButton from "./ProgrammeInfo/DeleteProgrammeButton";
import CustomNoRowsOverlay from "../../../components/misc/CustomNoRowsOverlay";

export default function CoursePanelPage() {
  const dispatch = useDispatch();
  const [navigateProgramme, setNavigateProgramme] = useState(null);
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [remindMode, setRemindOpen] = useState(false);

  const handleMenuSelect = (programme) => {
    if (editMode) {
      setRemindOpen(true);
      setNavigateProgramme(programme);
    } else setSelectedProgramme(programme);
  };

  const handleReminderClose = (isDiscard) => {
    setRemindOpen(false);
    if (isDiscard) {
      setSelectedProgramme(navigateProgramme);
      setEditMode(false);
    }
  };

  useEffect(() => {
    dispatch(getProgrammeList());
    dispatch(getCategoryList());
    dispatch(getUserList());
    dispatch(getVenueConditionList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <CategoryMenu
          selectedProgramme={selectedProgramme}
          handleMenuSelect={handleMenuSelect}
        />
        <Box sx={{ flex: 1, minHeight: "80vh" }}>
          <ProgrammeInfo
            preValues={selectedProgramme}
            editMode={editMode}
            setEditMode={setEditMode}
            setProgramme={setSelectedProgramme}
          />
          <ModuleInfo
            programme_id={
              selectedProgramme === null ? null : selectedProgramme.id
            }
          />
          <DeleteProgrammeButton
            programme={selectedProgramme}
            setProgramme={setSelectedProgramme}
          />
          {!selectedProgramme && (
            <CustomNoRowsOverlay name="No Course Selected" />
          )}
        </Box>
      </Box>
      <SaveReminderDialog open={remindMode} handleClose={handleReminderClose} />
    </React.Fragment>
  );
}
