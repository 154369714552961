import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { pipe } from "lodash/fp";
import {
  annontate,
  extract_field,
  format_action,
  format_datetime,
} from "../normalize";

const endpoint = endpoints["objectiveHistory"];

const slice = createSlice({
  name: "objectiveHistory",
  initialState: {
    data: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    objectiveHistoryListRequest: (objectiveHistory, action) => {
      objectiveHistory.loading = true;
    },
    objectiveHistoryListReceived: (objectiveHistory, { payload }) => {
      objectiveHistory.data = payload;
      objectiveHistory.loading = false;
    },
  },
});

const { objectiveHistoryListRequest, objectiveHistoryListReceived } =
  slice.actions;

// action
export const getObjectiveHistoryList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: objectiveHistoryListRequest.type,
    onSuccess: objectiveHistoryListReceived.type,
  });

// selector
export const getObjectiveHistory = (state) =>
  state.entities.objectiveHistory.data;

export const getObjectiveHistoryDisplay = (state) => {
  const history_display = pipe(
    annontate("username", extract_field("history_user", "username")),
    annontate("is_student", extract_field("history_user", "is_student")),
    annontate("is_teacher", extract_field("history_user", "is_teacher")),
    annontate("history_date", format_datetime("history_date")),
    annontate("history_type", format_action("history_type")),
    annontate("module_name", extract_field("module", "name", "Deleted Module")),
    annontate(
      "creator_username",
      extract_field("creator", "username", "Not Found")
    )
  );
  return history_display(state.entities.objectiveHistory.data);
};

export const getObjectiveHistoryLoadingStatus = (state) =>
  state.entities.objectiveHistory.loading;

export default slice.reducer;
