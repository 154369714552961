import * as React from "react";
import {
  Box,
  TextField,
  Autocomplete,
  Avatar,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentFormData, setChanged } from "../../lessonsSlice";
import { color } from "../../../../config";
import ProgrammeAddDialog from "./ProgrammeAddDialog";
import TeacherAndStudentAddDialog from "./TeacherAndStudentAddDialog";
import VenueConditionAddDialog from "./VenueConditionAddDialog";
import VenueAddDialog from "./VenueAddDialog";

// const labels = {
//   category: "Category*",
//   programme: "Programme*",
//   level: "Level*",
//   teachers: "Teacher(s)",
//   students: "Student(s)",
//   venueConditions: "Venue Condition(s)",
//   venue: "Venue",
//   color: "Color*",
// };

// const selected = {
//   category: "selectedCategory",
//   programme: "selectedProgramme",
//   level: "selectedLevel",
//   teachers: "selectedTeachers",
//   students: "selectedStudents",
//   venueConditions: "selectedVenueConditions",
//   venue: "selectedVenue",
//   color: "selectedColor",
// };

export default function SelectPanel({
  isNewAppointment,
  choices,
  appointmentData,
}) {
  const dispatch = useDispatch();
  const {
    categoryChoices,
    programmeChoices,
    levelChoices,
    teacherChoices,
    studentChoices,
    venueConditionChoices,
    venueChoices,
  } = choices;
  const appointmentFormData = useSelector(
    (state) => state.lessons.appointmentFormData
  );
  const {
    selectedCategory,
    selectedProgramme,
    selectedLevel,
    selectedTeachers,
    selectedStudents,
    selectedVenueConditions,
    selectedVenue,
    selectedColor,
  } = appointmentFormData;

  const [openProgrammeAddDialog, setOpenProgrammeAddDialog] =
    React.useState(false);
  const [openTeacherAndStudentAddDialog, setOpenTeacherAndStudentAddDialog] =
    React.useState(false);
  const [openVenueConditionAddDialog, setOpenVenueConditionAddDialog] =
    React.useState(false);
  const [openVenueAddDialog, setOpenVenueAddDialog] = React.useState(false);

  const overlappedLessons = useSelector(
    (state) => state.lessons.overlappedLessons
  ).filter((overlappedLesson) =>
    appointmentData.lessonId
      ? overlappedLesson.id !== appointmentData.lessonId
      : true
  );
  const overlappedTeachers = overlappedLessons
    .map((overlappedLesson) => overlappedLesson.teachers)
    .flat();
  const overlappedStudents = overlappedLessons
    .map((overlappedLesson) => overlappedLesson.students)
    .flat();
  const overlappedVenues = overlappedLessons
    .map((overlappedLesson) => overlappedLesson.venue)
    .filter((venue) => venue !== null);

  return (
    <>
      <Box>
        {categoryChoices.length > 0 && (
          <Box>
            <AppointmentForm.Label text="Category*" type="title" />
            <Autocomplete
              disabled={!isNewAppointment}
              options={categoryChoices}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: {
                      ...params.InputProps.endAdornment,
                      props: {
                        ...params.InputProps.endAdornment.props,
                        children: [
                          <InputAdornment
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            }}
                            key={Math.random()}
                            position="end"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setOpenProgrammeAddDialog(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>

                            {params.InputProps.endAdornment.props.children}
                          </InputAdornment>,
                        ],
                      },
                    },
                  }}
                />
              )}
              value={selectedCategory}
              onChange={(event, value) => {
                dispatch(
                  setAppointmentFormData({
                    ...appointmentFormData,
                    selectedCategory: value,
                  })
                );
                dispatch(setChanged(true));
              }}
            />
          </Box>
        )}

        {programmeChoices.length > 0 && (
          <Box>
            <AppointmentForm.Label text="Programme*" type="title" />
            <Autocomplete
              disabled={!isNewAppointment}
              options={programmeChoices}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: {
                      ...params.InputProps.endAdornment,
                      props: {
                        ...params.InputProps.endAdornment.props,
                        children: [
                          <InputAdornment
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            }}
                            key={Math.random()}
                            position="end"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setOpenProgrammeAddDialog(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>

                            {params.InputProps.endAdornment.props.children}
                          </InputAdornment>,
                        ],
                      },
                    },
                  }}
                />
              )}
              value={selectedProgramme}
              onChange={(event, value) => {
                dispatch(
                  setAppointmentFormData({
                    ...appointmentFormData,
                    selectedProgramme: value,
                  })
                );
                dispatch(setChanged(true));
              }}
            />
          </Box>
        )}
        {levelChoices.length > 0 && (
          <Box>
            <AppointmentForm.Label text="Level*" type="title" />
            <Autocomplete
              disabled={!isNewAppointment}
              options={levelChoices}
              getOptionLabel={(option) => option.toString()}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: {
                      ...params.InputProps.endAdornment,
                      props: {
                        ...params.InputProps.endAdornment.props,
                        children: [
                          <InputAdornment
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            }}
                            key={Math.random()}
                            position="end"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setOpenProgrammeAddDialog(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>

                            {params.InputProps.endAdornment.props.children}
                          </InputAdornment>,
                        ],
                      },
                    },
                  }}
                />
              )}
              value={selectedLevel}
              onChange={(event, value) => {
                dispatch(
                  setAppointmentFormData({
                    ...appointmentFormData,
                    selectedLevel: value,
                  })
                );
                dispatch(setChanged(true));
              }}
            />
          </Box>
        )}
        {teacherChoices.length > 0 && (
          <Box>
            <AppointmentForm.Label text="Teacher(s)" type="title" />
            <Autocomplete
              multiple
              options={teacherChoices}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              getOptionDisabled={(option) =>
                overlappedTeachers.find(
                  (teacher) => teacher.id === option.id
                ) !== undefined
              }
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: {
                      ...params.InputProps.endAdornment,
                      props: {
                        ...params.InputProps.endAdornment.props,
                        children: [
                          <InputAdornment
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            }}
                            key={Math.random()}
                            position="end"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setOpenTeacherAndStudentAddDialog(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>

                            {params.InputProps.endAdornment.props.children}
                          </InputAdornment>,
                        ],
                      },
                    },
                  }}
                />
              )}
              value={selectedTeachers}
              onChange={(event, value) => {
                dispatch(
                  setAppointmentFormData({
                    ...appointmentFormData,
                    selectedTeachers: value,
                  })
                );
                dispatch(setChanged(true));
              }}
            />
          </Box>
        )}
        {studentChoices.length > 0 && (
          <Box>
            <AppointmentForm.Label text="Students(s)" type="title" />
            <Autocomplete
              multiple
              options={studentChoices}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              getOptionDisabled={(option) =>
                overlappedStudents.find(
                  (student) => student.id === option.id
                ) !== undefined
              }
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: {
                      ...params.InputProps.endAdornment,
                      props: {
                        ...params.InputProps.endAdornment.props,
                        children: [
                          <InputAdornment
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            }}
                            key={Math.random()}
                            position="end"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setOpenTeacherAndStudentAddDialog(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>

                            {params.InputProps.endAdornment.props.children}
                          </InputAdornment>,
                        ],
                      },
                    },
                  }}
                />
              )}
              value={selectedStudents}
              onChange={(event, value) => {
                dispatch(
                  setAppointmentFormData({
                    ...appointmentFormData,
                    selectedStudents: value,
                  })
                );
                dispatch(setChanged(true));
              }}
            />
          </Box>
        )}

        <AppointmentForm.Label text="Venue Condition(s)" type="title" />
        <Autocomplete
          multiple
          options={venueConditionChoices}
          getOptionLabel={(option) => option.description}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: {
                  ...params.InputProps.endAdornment,
                  props: {
                    ...params.InputProps.endAdornment.props,
                    children: [
                      <InputAdornment
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                        key={Math.random()}
                        position="end"
                      >
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setOpenVenueConditionAddDialog(true);
                          }}
                        >
                          <AddIcon />
                        </IconButton>

                        {params.InputProps.endAdornment.props.children}
                      </InputAdornment>,
                    ],
                  },
                },
              }}
            />
          )}
          value={selectedVenueConditions}
          onChange={(event, value) => {
            dispatch(
              setAppointmentFormData({
                ...appointmentFormData,
                selectedVenueConditions: value,
              })
            );
            dispatch(setChanged(true));
          }}
        />
        <AppointmentForm.Label text="Venue" type="title" />
        <Autocomplete
          options={venueChoices}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) =>
            overlappedVenues.find((venue) => venue.id === option.id) !==
            undefined
          }
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: {
                  ...params.InputProps.endAdornment,
                  props: {
                    ...params.InputProps.endAdornment.props,
                    children: [
                      <InputAdornment
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                        key={Math.random()}
                        position="end"
                      >
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setOpenVenueAddDialog(true);
                          }}
                        >
                          <AddIcon />
                        </IconButton>

                        {params.InputProps.endAdornment.props.children}
                      </InputAdornment>,
                    ],
                  },
                },
              }}
            />
          )}
          value={selectedVenue}
          onChange={(event, value) => {
            dispatch(
              setAppointmentFormData({
                ...appointmentFormData,
                selectedVenue: value,
              })
            );
            dispatch(setChanged(true));
          }}
        />
        <AppointmentForm.Label text="Color*" type="title" />
        <Autocomplete
          options={color}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                "& > img": { mr: 2, flexShrink: 0 },
                display: "flex",
                gap: "5px",
              }}
              {...props}
            >
              <Avatar
                sx={{
                  backgroundColor: option.code,
                  width: "20px",
                  height: "20px",
                }}
                children=""
              />
              {option.name}
            </Box>
          )}
          value={selectedColor}
          onChange={(event, value) => {
            dispatch(
              setAppointmentFormData({
                ...appointmentFormData,
                selectedColor: value,
              })
            );
            dispatch(setChanged(true));
          }}
        />
      </Box>

      {/* Add Dialog */}
      <ProgrammeAddDialog
        openDialog={openProgrammeAddDialog}
        onClose={() => {
          setOpenProgrammeAddDialog(false);
        }}
      />
      <TeacherAndStudentAddDialog
        openDialog={openTeacherAndStudentAddDialog}
        onClose={() => {
          setOpenTeacherAndStudentAddDialog(false);
        }}
      />

      <VenueConditionAddDialog
        openDialog={openVenueConditionAddDialog}
        onClose={() => {
          setOpenVenueConditionAddDialog(false);
        }}
      />
      <VenueAddDialog
        openDialog={openVenueAddDialog}
        onClose={() => {
          setOpenVenueAddDialog(false);
        }}
      />
    </>
  );
}
