import React, { forwardRef } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../misc/CustomNoRowsOverlay";
import TableToolBar from "../misc/TableToolBar";
import TablePagination from "../misc/TablePagination";

function Table(props, ref) {
  const {
    initialState,
    tableName,
    rows,
    columns,
    loading,
    getRowId,
    handleCreateRecord,
    searchValue,
    setSearchValue,
  } = props;

  return (
    <Box sx={{ height: "72vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        getRowId={getRowId}
        initialState={initialState}
        ref={ref}
        autoPageSize
        disableSelectionOnClick
        components={{
          Toolbar: TableToolBar,
          NoRowsOverlay: CustomNoRowsOverlay,
          LoadingOverlay: LinearProgress,
          Pagination: TablePagination,
        }}
        componentsProps={{
          toolbar: {
            tableName,
            handleCreateRecord,
            searchValue,
            setSearchValue,
          },
        }}
        hideFooterSelectedRowCount
      />
    </Box>
  );
}

export default forwardRef(Table);

// import React, { useEffect, useState } from "react";
// import Pagination from "@mui/material/Pagination";

// export default function Table(props) {
//   const [page, setPage] = useState(1);
//   const rowPerPage = 10;
//   const pageNumber = 100;

//   const handleClick = (event, value) => {
//     setPage(value);
//   };

//   return (
//     <React.Fragment>
//       <table id="table" className="table">
//         <thead>
//           <tr id="table-header">
//             {props.columns.map((column) => (
//               <th key={column.field}>{column.headerName}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {props.rows.map((row) => (
//             <tr key={row.id}>
//               {props.columns.map((column) => (
//                 <td key={row.id + column.field}>{row[column.field]}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       {pageNumber > 1 ? (
//         <Pagination
//           count={pageNumber}
//           onChange={handleClick}
//           page={page}
//         ></Pagination>
//       ) : null}
//     </React.Fragment>
//   );
// }
