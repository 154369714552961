import React, { useState, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useForm from "../../hooks/useForm";
import { validate } from "../../validator/common";
import SaveReminderDialog from "./SaveReminderDialog";
import { Box } from "@mui/material";

export default function EditDialog(props) {
  const {
    columns,
    preValues,
    values,
    setValues,
    targetName,
    open,
    handleClose,
    schema,

  } = props;
  const [errors, setErrors, renderField] = useForm(values, setValues, schema);
  const [remindMode, setRemindOpen] = useState(false);

  const renderEditField = (column) => (
    <Box key={column.field} sx={{ marginTop: 1, marginBottom: 3 }}>
      {renderField(column)}
    </Box>
  );

  const onClose = (isSave) => {
    if (JSON.stringify(preValues) !== JSON.stringify(values) && !isSave) {
      setRemindOpen(true);
    } else {
      setErrors({});
      handleClose(isSave);
    }
  };

  const handleReminderClose = (isDiscard) => {
    setRemindOpen(false);
    if (isDiscard) {
      setErrors({});
      handleClose(false);
    }
  };

  const disableButton = useMemo(
    () =>
      Boolean(validate(values, schema)) ||
      JSON.stringify(preValues) === JSON.stringify(values),
    [values, schema]
  );

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => {}} fullWidth>
        <DialogTitle>Edit {targetName}</DialogTitle>
        <DialogContent>
          {columns.map((column) =>
            renderEditField({ ...column, fullWidth: true })
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => onClose(true)}
            disabled={disableButton}
          >
            Save
          </Button>
          <Button color="error" onClick={() => onClose(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SaveReminderDialog open={remindMode} handleClose={handleReminderClose} />
    </React.Fragment>
  );
}
