import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getLessonsDetail, getLessonComment } from "./studentSlice";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// this function is to show what programmes the student is taking
export default function StudentProgrammes({ studentId }) {
  const [expandedProgramme, setExpandedProgramme] = React.useState(0);
  const dispatch = useDispatch();
  const programmes = useSelector((state) => state.student.programmes);
  const lessons = useSelector((state) => state.student.lessons);
  const comment = useSelector((state) => state.student.comment);

  const handleProgrammeChange = (panel) => (event, newExpanded) => {
    if (newExpanded) {
      dispatch(getLessonsDetail({ studentId, programmeId: panel }));
    }
    setExpandedProgramme(newExpanded ? panel : false);
  };

  const [expandedLesson, setExpandedLesson] = React.useState(0);

  const handleLessonChange = (panel) => (event, newExpanded) => {
    if (newExpanded) {
      dispatch(
        getLessonComment({
          studentId,
          programmeId: expandedProgramme,
          lessonId: panel,
        })
      );
    }
    setExpandedLesson(newExpanded ? panel : false);
  };

  function CommentTable() {
    return (
      <>
        {comment ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Module</TableCell>
                  <TableCell>Objective</TableCell>
                  <TableCell>Expectation</TableCell>
                  <TableCell>Performance</TableCell>
                  <TableCell>Score</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(comment.formattedObjectiveItems).map(
                  (moduleId) => {
                    return comment.formattedObjectiveItems[moduleId].length ===
                      0 ? null : (
                      <React.Fragment key={Math.random()}>
                        <TableRow>
                          <TableCell
                            rowSpan={
                              comment.formattedObjectiveItems[moduleId].length
                            }
                          >
                            {
                              comment.modules.find(
                                (module) =>
                                  module.id.toString() === moduleId.toString()
                              ).name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              comment.formattedObjectiveItems[moduleId][0]
                                .objective.name
                            }
                          </TableCell>
                          <TableCell>
                            {comment.formattedObjectiveItems[moduleId][0]
                              .expectation === "E"
                              ? "Exceeded Expectation"
                              : comment.formattedObjectiveItems[moduleId][0]
                                  .expectation === "M"
                              ? "Meet Expectation"
                              : "Below Expectation"}
                          </TableCell>
                          <TableCell>
                            {comment.formattedObjectiveItems[moduleId][0]
                              .performance === "O"
                              ? "Outperform"
                              : comment.formattedObjectiveItems[moduleId][0]
                                  .performance === "S"
                              ? "Standard"
                              : "Underperform"}
                          </TableCell>
                          <TableCell>
                            {comment.formattedObjectiveItems[moduleId][0].score}
                          </TableCell>
                        </TableRow>

                        {comment.formattedObjectiveItems[moduleId]
                          .slice(1)
                          .map((objectiveItem) => (
                            <TableRow key={Math.random()}>
                              <TableCell>
                                {objectiveItem.objective.name}
                              </TableCell>
                              <TableCell>
                                {objectiveItem.expectation === "E"
                                  ? "Exceeded Expectation"
                                  : objectiveItem.expectation === "M"
                                  ? "Meet Expectation"
                                  : "Below Expectation"}
                              </TableCell>
                              <TableCell>
                                {objectiveItem.performance === "O"
                                  ? "Outperform"
                                  : objectiveItem.performance === "S"
                                  ? "Standard"
                                  : "Underperform"}
                              </TableCell>
                              <TableCell>{objectiveItem.score}</TableCell>
                            </TableRow>
                          ))}
                      </React.Fragment>
                    );
                  }
                )}
                <TableRow>
                  <TableCell>Goals</TableCell>
                  <TableCell colSpan={4}>
                    {comment.performanceItems.map((goal) => (
                      <Typography key={Math.random()}>{goal.name}</Typography>
                    ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No Comment</Typography>
        )}
      </>
    );
  }

  function LessonsAccordion() {
    return lessons.map((lesson) => {
      const lessonStartDate = lesson.start_datetime.split("T")[0];
      const lessonStartTime = lesson.start_datetime
        .split("T")[1]
        .slice(0, lesson.start_datetime.split("T")[1].length - 4);
      const lessonEndDate = lesson.end_datetime.split("T")[0];
      const lessonEndTime = lesson.end_datetime
        .split("T")[1]
        .slice(0, lesson.end_datetime.split("T")[1].length - 4);

      return (
        <Accordion
          expanded={expandedLesson === lesson.id}
          onChange={handleLessonChange(lesson.id)}
          key={Math.random()}
        >
          <AccordionSummary>
            <Typography>{`${lessonStartDate} ${lessonStartTime} to ${lessonEndDate} ${lessonEndTime}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {comment
                ? comment.comment.status === "Y"
                  ? "Present"
                  : "Absent"
                : "No Status"}
            </Typography>
            <CommentTable />
            <Typography>{`Remark: ${
              comment ? comment.comment.remark : "No Remark"
            }`}</Typography>
          </AccordionDetails>
        </Accordion>
      );
    });
  }

  return (
    <>
      {programmes ? (
        <>
          {programmes.map((programme) => (
            <Accordion
              expanded={expandedProgramme === programme.id}
              onChange={handleProgrammeChange(programme.id)}
              key={Math.random()}
            >
              <AccordionSummary>
                <Typography>{`${programme.category_name} ${programme.name} ${programme.level}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {lessons ? <LessonsAccordion /> : null}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ) : null}
    </>
  );
}
