import React, { forwardRef } from "react";
import AddDialog from "./AddDialog";
import Joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import { createVenue } from "../../../../app/entities/venues";
import { getVenueConditionList } from "../../../../app/entities/venueConditions";
import {
  getVenueConditions,
  createVenueCondition,
  updateVenueCondition,
  deleteVenueConditionbyId,
} from "../../../../app/entities/venueConditions";

function VenueAddDialog(props, ref) {
  const { openDialog, onClose } = props;
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({});

  const venueConditions = useSelector(getVenueConditions);

  React.useEffect(() => {
    dispatch(getVenueConditionList());
  }, [dispatch]);

  const columns = [
    {
      field: "name",
      headerName: "Venue Name",
      required: true,
      otherStyle: { marginTop: 1 },
    },
    {
      field: "location",
      headerName: "Location",
    },
    {
      render_type: "autocomplete",
      field: "conditions",
      headerName: "Conditions",
      choices: venueConditions,
      multiple: true,
      displayLabel: (obj) => obj.description,
    },
  ];

  const schema = {
    name: Joi.string().required(),
    location: Joi.string().empty(""),
  };

  return (
    <AddDialog
      title={"Venue Addition"}
      ref={ref}
      openDialog={openDialog}
      onClose={onClose}
      columns={columns}
      schema={schema}
      onSubmit={(value) => {
        const data = {
          name: value.name,
          location: value.location,
          conditions: value.conditions.map((condition) => condition.id),
        };
        dispatch(createVenue(data));
        onClose();
      }}
      values={values}
      setValues={setValues}
    />
  );
}

export default forwardRef(VenueAddDialog);
