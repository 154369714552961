import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Paper } from "@mui/material";

import GeneralProfile from "../../components/common/GeneralProfile";
import TeacherInfo from "./TeacherInfo";
import GeneralProfileEditing from "../../components/common/GeneralProfileEditing";
import TeacherProgrammes from "./TeacherProgrammes";
import TeacherProfileEditing from "./TeacherProfileEditing";
import { getTeacherDetail } from "./teacherSlice";
import useTab from "../../hooks/useTab";
import TabWithContent from "../../components/common/TabWithContent";

export default function TeacherProfile() {
  const [tab, handleTabChange] = useTab("view", "teacherProfile");
  const { teacherUserId } = useParams();
  const is_superuser = useSelector((state) => state.user.is_superuser);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTeacherDetail({ teacherUserId }));
  }, [teacherUserId, dispatch]);

  const pages = [
    {
      label: "Teacher Profile",
      component: (
        <>
          <Paper>
            <GeneralProfile userId={teacherUserId} />
            {is_superuser && <GeneralProfileEditing userId={teacherUserId} />}
          </Paper>
          <Paper sx={{ marginTop: "20px" }}>
            <TeacherInfo teacherUserId={teacherUserId} />
            {is_superuser && (
              <TeacherProfileEditing teacherUserId={teacherUserId} />
            )}
          </Paper>
        </>
      ),
      route: "teacherProfile",
    },
    {
      label: "Programmes",
      component: <TeacherProgrammes teacherUserId={teacherUserId} />,
      route: "programme",
    },
  ];

  return (
    <TabWithContent pages={pages} tab={tab} handleTabChange={handleTabChange} />
  );
}
