import { Typography, Box, Paper } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";

export default function CourseInfo() {
  const courseInfo = useSelector((state) => state.course.courseInfo);

  const courseId = courseInfo.id ? courseInfo.id.toString() : "No id";
  const courseName = courseInfo.name ? courseInfo.name : "No Name";
  const courseLevel = courseInfo.level ? courseInfo.level : "No level";
  const courseCategory = courseInfo.category_name
    ? courseInfo.category_name
    : "No Category";
  const venueConditions =
    courseInfo.venue_conditions && courseInfo.venue_conditions.length > 0
      ? courseInfo.venue_conditions
          .map((venueCondition) => venueCondition.description)
          .join(", ")
      : "No Conditions";

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h6">Course Info</Typography>
      <Box>
        <Box sx={{ display: "inline-block", marginRight: 1 }}>
          <Typography>{"ID: "}</Typography>
          <Typography>{"Name: "}</Typography>
          <Typography>{"Level: "}</Typography>
          <Typography>{"Category: "}</Typography>
          <Typography>{"Venue Conditions: "}</Typography>
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <Typography>{courseId}</Typography>
          <Typography>{courseName}</Typography>
          <Typography>{courseLevel}</Typography>
          <Typography>{courseCategory}</Typography>
          <Typography>{venueConditions}</Typography>
        </Box>
      </Box>
    </Paper>
  );
}
