import { construct_path as _ } from "./tool";

export const endpoints = {
  users: "auth/users/",
  students: "students/",
  teachers: "teachers/",
  programmes: "programmes/",
  categories: "categories/",
  profiles: "profiles/",
  venues: "venues/",
  venueConditions: "venue_conditions/",
  moduleHistory: "history/modules/",
  objectiveHistory: "history/objectives/",
  performanceHistory: "history/performances/",
  modules: _("programmes/{}/modules/"),
  objectives: "objectives/",
};

export const paths = {
  timeTable: "timeTable/",
  lessons: "lessons/",
  forgotpassword: "forgotpassword/",
  studentProfile: _("studentProfile/{}"),
  teacherProfile: _("teacherProfile/{}"),
  lessonDetail: _("lessonDetail/{}/{}"),
  course: _("course/{}"),
  bag: _("bag/{}"),
};

export const write_fields = {
  venues: ["id", "name", "location", "conditions"],
  programmes: [
    "id",
    "name",
    "level",
    "description",
    "category",
    "teachers",
    "students",
    "venue_conditions",
  ],
};

export const SELECT_NONE = "select-none";

export const IMAGE_BASE_URL = process.env.REACT_APP_URL;

export const color = [
  { name: "red", code: "#FF6347" },
  { name: "orangeRed", code: "#F28500" },
  { name: "pink", code: "#F9A7B0" },
  { name: "wine", code: "#C71585" },
  { name: "orange", code: "#FFA500" },
  { name: "yellow", code: "#FFE135" },
  { name: "peach", code: "#FFDAB9" },
  { name: "lightPurple", code: "#E6E6FA" },
  { name: "purple", code: "#6F2DA8" },
  { name: "indigo", code: "#4B0082" },
  { name: "lightGreen", code: "#9BA17B" },
  { name: "green", code: "#59910F" },
  { name: "skyBlue", code: "#326872" },
  { name: "blue", code: "#4F86F7" },
  { name: "wood", code: "#DEB887" },
  { name: "brown", code: "#A52A2A" },
  { name: "lightGray", code: "#C0C0C0" },
  { name: "gray", code: "#383428" },
];

export const expectationValues = [
  "Exceeded expectation",
  "Meet expectation",
  "Below expectation",
];

export const performanceValues = ["Outperform", "Standard", "Underperform"];

export const scoreValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
