import React, { useState, useMemo, forwardRef } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import useForm from "../../hooks/useForm";
import { validate } from "../../validator/common";

function Addbar(props, ref) {
  const { columns, onSubmit, schema } = props;
  const [values, setValues] = useState({});

  const [errors, setErrors, renderField] = useForm(
    values,
    setValues,
    schema,
    "outlined"
  );

  const disableButton = useMemo(
    () => Boolean(validate(values, schema)),
    [values, schema]
  );

  return (
    <Stack
      ref={ref}
      direction="row"
      spacing={2}
      sx={{
        mt: 1,
        mr: 1,
        padding: 1,
        borderRadius: 2,
        border: 1,
        height: "60px",
        borderColor: "#e0e0e0",
      }}
    >
      {columns.map((column) => renderField(column))}
      <Button
        startIcon={<AddIcon />}
        size="small"
        onClick={() => {
          setValues({});
          onSubmit(values);
        }}
        disabled={disableButton}
      >
        Add Record
      </Button>
    </Stack>
  );
}

export default forwardRef(Addbar);
