import { Typography, Paper, Chip } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { paths } from "../../config";

export default function Teachers() {
  const teachers = useSelector((state) => state.course.courseInfo.teachers);
  const sortedTeachers = teachers
    ? [...teachers].sort((teacher1, teacher2) => {
        return `${teacher1.first_name} ${teacher1.last_name}`.localeCompare(
          `${teacher2.first_name} ${teacher2.last_name}`
        );
      })
    : null;

  return (
    <Paper sx={{ marginTop: 2, padding: 2 }}>
      <Typography variant="h6">Teachers:</Typography>
      {sortedTeachers
        ? sortedTeachers.map((teacher) => (
            <Chip
              key={Math.random()}
              // avatar={<Avatar alt="" src="/static/images/avatar/1.jpg" />}
              clickable
              label={`${teacher.first_name} ${teacher.last_name.toUpperCase()}`}
              component={Link}
              to={`/${paths["teacherProfile"](teacher.id)}`}
              sx={{ margin: "5px 10px" }}
            />
          ))
        : null}
    </Paper>
  );
}
