import React, { useState } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";

import {
  deleteObjective,
  updateObjective,
} from "../../../../app/entities/objectives";

export default function ObjectiveListItem(props) {
  const { objective } = props;
  const [preObjective, setPreObjective] = useState(null);
  const [modifiedObjective, setModifiedObjective] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  const handleMenuSelect = (objective) => {
    setPreObjective(objective);
    setModifiedObjective(objective);
    setEditMode(true);
  };

  const handleTextFieldChange = (event) => {
    setModifiedObjective({ ...modifiedObjective, name: event.target.value });
  };

  const handleSave = () => {
    dispatch(updateObjective(preObjective, modifiedObjective));
    setEditMode(false);
  };

  const handleDelete = (objective) => {
    dispatch(deleteObjective(objective));
  };

  const renderReadStatus = () => (
    <ListItemButton
      key={objective.id}
      sx={{ pl: 4 }}
      onClick={() => handleMenuSelect(objective)}
    >
      <ListItemText primary={objective.name} />
    </ListItemButton>
  );

  const renderEditStatus = () => (
    <>
      <TextField
        sx={{ pl: 4 }}
        fullWidth
        variant="standard"
        value={modifiedObjective.name}
        onChange={handleTextFieldChange}
      />
      <IconButton onClick={handleSave}>
        <SaveIcon />
      </IconButton>
      <IconButton sx={{ mr: 3 }} onClick={() => setEditMode(false)}>
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <ListItem disablePadding>
      {editMode ? renderEditStatus() : renderReadStatus()}
      <IconButton sx={{ mr: 5 }} onClick={() => handleDelete(objective)}>
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
}
