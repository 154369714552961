import React from "react";
import {
  Dialog,
  Card,
  CardContent,
  CardMedia,
  Typography,
  ImageListItem,
  Badge,
} from "@mui/material";
import { Box } from "@mui/system";

export default function CollectionStamp({ stamp, had }) {
  const [flipped, setFlipped] = React.useState(false);

  return (
    <Card
      onClick={() => {
        if (had) {
          setFlipped((prevFlip) => !prevFlip);
        }
      }}
      onMouseLeave={() => {
        if (had) {
          setFlipped(false);
        }
      }}
      sx={
        had
          ? {
              margin: "10px 15px",
              width: "200px",
              height: "auto",
              display: "inline-block",
              background:
                "radial-gradient(circle, rgba(196,230,255,0.7189469537815126) 0%, rgba(187,238,251,0.9402354691876751) 52%, rgba(84,174,240,0.7189469537815126) 93%)",
              boxShadow: "10px 5px 5px rgba(0, 0, 0, 0.35)",
              borderRadius: "15px",
              transform: "rotateY(0deg) scale(1)",
              transition: "0.3s",
              "&:hover": {
                transform: flipped
                  ? "scale(1.1) rotateY(180deg)"
                  : "scale(1.1)",
                boxShadow: "none",
                border: "2.5px solid Tomato",
                boxShadow: "rgba(0, 0, 0, 0.35) 5px 5px 15px",
                transition: "0.3s",
              },
            }
          : {
              margin: "10px 15px",
              width: "200px",
              height: "auto",
              display: "inline-block",
              background: "grey",
              boxShadow: "10px 5px 5px gray",
            }
      }
    >
      <CardContent>
        <Box
          sx={{
            marginTop: "10px",
            display: flipped ? "none" : "block",
            height: "250px",
          }}
        >
          <CardMedia
            component="img"
            image={stamp.image}
            alt={stamp.description}
          />
          <Typography
            gutterBottom
            variant="h5"
            sx={{ marginTop: 1, marginBottom: 0 }}
          >
            {stamp.name}
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: "10px",
            display: !flipped ? "none" : "block",
            transform: `rotateY(180deg)`,
            height: "250px",
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {stamp.name}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ whiteSpace: "pre-line" }}
          >
            {stamp.description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
