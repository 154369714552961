import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { seperateState, combineState } from "../normalize";

const endpoint = endpoints["users"];

const slice = createSlice({
  name: "users",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    userListRequest: (users, action) => {
      users.loading = true;
    },
    userListReceived: (users, { payload }) => {
      seperateState(users, payload);
      users.loading = false;
    },
    userRequest: (users, action) => {
      users.loading = true;
    },
    userReceived: (users, { payload }) => {
      users.allIds.push(payload.id);
      users.byId[payload.id] = payload;
      users.loading = false;
    },
    userCreate: (users, { payload }) => {
      users.allIds.push(payload.id);
      users.byId[payload.id] = payload;
    },
    userCreateRollback: (users, { payload }) => {
      users.allIds = users.filter((user) => user !== payload.id);
    },
    userUpdate: (users, { payload }) => {
      users.byId[payload.id] = { ...users.byId[payload.id], ...payload };
    },
    userUpdateRollback: (users, { payload }) => {
      users.byId[payload.id] = payload;
    },
    userDelete: (users, { payload }) => {
      users.allIds = users.allIds.filter((id) => id !== payload.id);
    },
    userDeleteRollback: (users, { payload }) => {
      users.allIds = [...users.allIds, payload.id];
    },
  },
});

const {
  userListRequest,
  userListReceived,
  userRequest,
  userReceived,
  userCreate,
  userUpdate,
  userUpdateRollback,
  userDelete,
  userDeleteRollback,
} = slice.actions;

// action
export const getUserList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: userListRequest.type,
    onSuccess: userListReceived.type,
  });

export const retrieveUser = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "get",
    onStart: userRequest.type,
    onSuccess: userReceived.type,
  });

export const createUser = (user) =>
  apiCallBegan({
    endpoint,
    method: "post",
    data: user,
    onSuccess: userCreate.type,
    createdRecordName: `user "${user.username}"`,
  });

export const updateUser = (preUser, user) =>
  apiCallBegan({
    endpoint: endpoint + user.id + "/",
    method: "patch",
    data: user,
    onStart: userUpdate.type,
    onStartPayload: user,
    onError: userUpdateRollback.type,
    onErrorPayload: preUser,
  });

export const deleteUserbyId = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "delete",
    onStart: userDelete.type,
    onStartPayload: { id },
    onError: userDeleteRollback.type,
    onErrorPayload: { id },
  });

// selector
export const getUsers = (state) => combineState(state.entities.users);

export const getUserLoadingStatus = (state) => state.entities.users.loading;

export const getUserbyId = (id) => (state) => state.entities.users.byId[id];

export default slice.reducer;
