import * as React from "react";
import LessonModules from "./LessonModules";
import Comment from "./Comment";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import { getCourseDetail } from "../course/courseSlice";
import { getGoals, getLessonDetail } from "./lessonSlice";
import { getStamps } from "../stamps/stampsSlice";
import LessonInfo from "./LessonInfo";

export default function LessonDetail() {
  React.useEffect(() => {
    dispatch(getGoals());
    dispatch(getStamps());
    dispatch(getLessonDetail({ programmeId, lessonId }));
    dispatch(getCourseDetail({ programmeId }));
  }, []);

  const dispatch = useDispatch();
  const { programmeId, lessonId } = useParams();
  const isStudent = useSelector((state) => state.user.is_student);
  const isTeacher = useSelector((state) => state.user.is_teacher);
  const isLoading = useSelector((state) => state.lesson.loading);
  const lesson = useSelector((state) => state.lesson.info);
  const courseInfo = useSelector((state) => state.lesson.courseInfo);
  const studentsComment = useSelector((state) =>
    state.lesson.comments ? state.lesson.comments : null
  );

  const selectedObjectives = {};
  if (
    studentsComment &&
    studentsComment.length > 0 &&
    studentsComment[0].objectiveItems.length > 0
  ) {
    studentsComment[0].objectiveItems.forEach((objectiveItem) => {
      if (selectedObjectives[objectiveItem.objective.module]) {
        selectedObjectives[objectiveItem.objective.module].add(
          objectiveItem.objective.id
        );
      } else {
        selectedObjectives[objectiveItem.objective.module] = new Set([
          objectiveItem.objective.id,
        ]);
      }
    });
  }

  return (
    <>
      {!isLoading ? (
        <Box>
          <LessonInfo lesson={lesson} courseInfo={courseInfo} />
          {isTeacher ? (
            <LessonModules
              programmeId={programmeId}
              lessonId={lessonId}
              selectedObjectives={selectedObjectives}
            />
          ) : null}
          {studentsComment
            ? studentsComment.map((studentComment) => (
                <Comment
                  key={Math.random()}
                  studentComment={studentComment}
                  selectedObjectives={selectedObjectives}
                />
              ))
            : null}
        </Box>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
}
