import * as React from "react";
import { useSelector } from "react-redux";
import { Paper } from "@mui/material";
import { useParams } from "react-router-dom";

import StudentProgrammes from "./StudentProgrammes";
import GeneralProfile from "../../components/common/GeneralProfile";
import StudentInfo from "./StudentInfo";
import GeneralProfileEditing from "../../components/common/GeneralProfileEditing";
import StudentProfileEditing from "./StudentProfileEditing";
import Bag from "../bag/Bag";
import Collection from "../bag/Collection";
import useTab from "../../hooks/useTab";
import TabWithContent from "../../components/common/TabWithContent";
// import StudentStampsComsumptionDialog from "../bag/StudentStampsComsumptionDialog";

export default function StudentProfile() {
  const [tab, handleTabChange] = useTab("view", "studentProfile");
  const is_superuser = useSelector((state) => state.user.is_superuser);
  const { studentUserId } = useParams();

  const pages = [
    {
      label: "Student Profile",
      component: (
        <>
          <Paper>
            <GeneralProfile userId={studentUserId} />
            {is_superuser && <GeneralProfileEditing userId={studentUserId} />}

            {/*<StudentStampsComsumptionDialog bagId={studentUserId} />*/}
          </Paper>
          {
            <Paper sx={{ marginTop: "20px" }}>
              <StudentInfo studentUserId={studentUserId} />
              {is_superuser && (
                <StudentProfileEditing studentUserId={studentUserId} />
              )}
            </Paper>
          }
        </>
      ),
      route: "studentProfile",
    },
    {
      label: "Programmes",
      component: <StudentProgrammes studentId={studentUserId} />,
      route: "programme",
    },
    {
      label: "Stamps",
      component: <Bag />,
      route: "bag",
    },
    {
      label: "Collection",
      component: <Collection />,
      route: "collection",
    },
  ];

  return (
    <TabWithContent pages={pages} tab={tab} handleTabChange={handleTabChange} />
  );
}
