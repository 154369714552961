import * as React from "react";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { useDispatch, useSelector } from "react-redux";
import { getOverlappedLessons, setAppointmentFormData } from "../lessonsSlice";
import { color } from "../../../config";

export default function Appointment({
  children,
  data,
  onDoubleClick,
  style,
  ...restProps
}) {
  const dispatch = useDispatch();
  const isSuperUser = useSelector((state) => state.user.is_superuser);
  const appointment = useSelector((state) => state.lessons.lessons).find(
    (lesson) => lesson.id === data.lessonId
  );

  const openAppointmentForm = (event) => {
    dispatch(
      getOverlappedLessons({
        selectedStartTime: data.startDate,
        selectedEndTime: data.endDate,
      })
    );
    dispatch(
      setAppointmentFormData({
        selectedCategory: appointment.programme.category_name,
        selectedProgramme: appointment.programme.name,
        selectedLevel: appointment.programme.level,
        selectedTeachers: appointment.teachers,
        selectedStudents: appointment.students,
        selectedVenueConditions: [],
        selectedVenue: appointment.venue,
        selectedColor: color.find((color) => color.code === appointment.color),
      })
    );
    onDoubleClick(event);
  };

  return (
    <Appointments.Appointment
      data={data}
      style={{ ...style, backgroundColor: data.color.code }}
      onDoubleClick={isSuperUser ? openAppointmentForm : undefined}
      {...restProps}
    >
      {children}
    </Appointments.Appointment>
  );
}
