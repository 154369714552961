const parse_settings = (string) => {
  let res = string
    .split("{}")
    .reduce((acc, cur) => acc.concat([cur, "{}"]), []);
  res.pop();
  return res;
};

export const construct_path =
  (settings) =>
  (...value) => {
    let var_index = 0;
    let result = "";

    let p_settings = parse_settings(settings);

    for (let setting of p_settings) {
      if (setting !== "{}") {
        result += setting;
      } else {
        result += value[var_index];
        var_index += 1;
      }
    }
    return result;
  };

export const title = (string) =>
  string
    .toLowerCase()
    .split(" ")
    .map(function (s) {
      return s.charAt(0).toUpperCase() + s.substring(1);
    })
    .join(" ");

export const getRandomInt = (max) => Math.floor(Math.random() * max);

export const randomPassword = (length) =>
  Array(length)
    .fill(0)
    .map(() => getRandomInt(10))
    .join("");

export const getWeekstart = () => {
  const curr = new Date();
  return new Date(curr.setDate(curr.getDate() - curr.getDay()));
};

export const getWeekend = () => {
  const curr = new Date();
  return new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
};

export const getMonthstart = () => {
  const curr = new Date();
  return new Date(curr.getFullYear(), curr.getMonth(), 1);
};

export const getMonthend = () => {
  const curr = new Date();
  return new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
};

export const getYearstart = () => {
  return new Date(new Date().getFullYear(), 0, 1);
};

export const getYearend = () => {
  return new Date(new Date().getFullYear(), 11, 31);
};

export const getNextWeek = () => {
  const curr = new Date();
  return new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + 7);
};

export const getPreviousWeek = () => {
  const curr = new Date();
  return new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() - 7);
};

export const getNextMonth = () => {
  const curr = new Date();
  return new Date(curr.getFullYear(), curr.getMonth() + 1, curr.getDate());
};

export const getPreviousMonth = () => {
  const curr = new Date();
  return new Date(curr.getFullYear(), curr.getMonth() - 1, curr.getDate());
};

export const getNextYear = () => {
  const curr = new Date();
  return new Date(curr.getFullYear() + 1, curr.getMonth(), curr.getDate());
};

export const getPreviousYear = () => {
  const curr = new Date();
  return new Date(curr.getFullYear() - 1, curr.getMonth(), curr.getDate());
};

export const compareByField = (field) => (a, b) => {
  return a[field].localeCompare(b[field]);
};
