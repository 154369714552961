import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  Resources,
  DayView,
  ViewSwitcher,
} from "@devexpress/dx-react-scheduler-material-ui";
import { getLessons, resetAppointmentFormData } from "./lessonsSlice";
import { color } from "../../config";
import FlexibleSpace from "./scheduler/FlexibleSpace";
import Switcher from "./scheduler/Switcher";
import CommandButton from "./scheduler/CommandButton";
import Appointment from "./scheduler/Appointment";
import TimeTableCell from "./scheduler/TimeTableCell";
import Header from "./scheduler/Header";
import TooltipLayout from "./scheduler/TooltipLayout";
import BasicLayout from "./scheduler/BasicLayout";
import ConfirmationDialog from "./scheduler/ConfirmationDialog";
import { getCourses } from "../courses/coursesSlice";
import { getVenueConditions, getVenues } from "../lessons/lessonsSlice";

export default function TimeTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [formVisibility, setFormVisibility] = React.useState(false);
  const [tooltipVisibility, setTooltipVisibility] = React.useState(false);
  const isLoading = useSelector((state) => state.lessons.loading);
  const isSuperUser = useSelector((state) => state.user.is_superuser);
  const isStudent = useSelector((state) => state.user.is_student);
  const mySettings = useSelector((state) => state.user.mySettings);
  const schedulerData = useSelector((state) => state.lessons.lessons).map(
    (lesson, index) => ({
      id: index,
      programmeId: lesson.programme.id,
      lessonId: lesson.id,
      startDate: new Date(lesson.start_datetime),
      endDate: new Date(lesson.end_datetime),
      title: `${lesson.programme.category_name} ${
        lesson.programme.name || ""
      } ${lesson.programme.level ? `Level ${lesson.programme.level}` : ""}`,
      category: lesson.programme.category_name,
      programme: lesson.programme.name || "N/A",
      level: lesson.programme.level ? `Level ${lesson.programme.level}` : "N/A",
      venue: lesson.venue ? lesson.venue.name : "TBC",
      teachers:
        lesson.teachers.length > 0
          ? lesson.teachers
              .map((teacher) => `${teacher.first_name} ${teacher.last_name}`)
              .join(", ")
          : "TBC",
      students:
        lesson.students.length > 0
          ? lesson.students
              .map((student) => `${student.first_name} ${student.last_name}`)
              .join(", ")
          : "TBC",
      color: color.find((color) => color.code === lesson.color),
    })
  );
  const changed = useSelector((state) => state.lessons.lessonChanged);

  React.useEffect(() => {
    dispatch(getLessons({ startDatetime: null, endDatetime: null }));
    dispatch(getCourses());
    dispatch(getVenues());
    dispatch(getVenueConditions());
  }, [dispatch]);

  const resources = [
    {
      fieldName: "category",
      instances: [...new Set(schedulerData.map((data) => data.category))].map(
        (item) => ({
          id: item,
          text: item,
        })
      ),
    },
    {
      fieldName: "programme",
      instances: [...new Set(schedulerData.map((data) => data.programme))].map(
        (item) => ({
          id: item,
          text: item,
        })
      ),
    },
    {
      fieldName: "level",
      instances: [...new Set(schedulerData.map((data) => data.level))].map(
        (item) => ({
          id: item,
          text: item,
        })
      ),
    },
    {
      fieldName: "venue",
      instances: [...new Set(schedulerData.map((data) => data.venue))].map(
        (item) => ({
          id: item,
          text: item,
        })
      ),
    },
    {
      fieldName: "teachers",
      instances: [...new Set(schedulerData.map((data) => data.teachers))].map(
        (item) => ({
          id: item,
          text: item,
        })
      ),
    },
    {
      fieldName: "students",
      instances: [...new Set(schedulerData.map((data) => data.students))].map(
        (item) => ({
          id: item,
          text: item,
        })
      ),
    },
  ];

  return (
    <>
      {!isStudent &&
        (!isLoading ? (
          <Box>
            <Scheduler data={schedulerData}>
              <ConfirmationDialog
                action="change"
                component="Form"
                open={open}
                setOpen={setOpen}
                setFormVisibility={setFormVisibility}
                setTooltipVisibility={setTooltipVisibility}
              />
              <ViewState defaultCurrentViewName={mySettings.view || "Week"} />
              <EditingState />
              <IntegratedEditing />
              <DayView
                startDayHour={mySettings.startTime || 0}
                timeTableCellComponent={TimeTableCell}
              />
              <WeekView
                startDayHour={mySettings.startTime || 0}
                timeTableCellComponent={TimeTableCell}
              />
              <Appointments appointmentComponent={Appointment} />
              <AppointmentTooltip
                visible={tooltipVisibility}
                onVisibilityChange={() =>
                  setTooltipVisibility((prevVisibility) => !prevVisibility)
                }
                headerComponent={Header}
                layoutComponent={({ ...props }) => (
                  <TooltipLayout
                    setTooltipVisibility={setTooltipVisibility}
                    setFormVisibility={setFormVisibility}
                    {...props}
                  />
                )}
                showOpenButton={false}
                showDeleteButton={isSuperUser}
                showCloseButton
              />
              <AppointmentForm
                visible={formVisibility}
                onVisibilityChange={() => {
                  if (formVisibility) {
                    if (changed) {
                      setOpen(true);
                    } else {
                      dispatch(resetAppointmentFormData());
                      setFormVisibility((prevVisibility) => !prevVisibility);
                    }
                  } else {
                    setFormVisibility((prevVisibility) => !prevVisibility);
                  }
                }}
                basicLayoutComponent={({ ...props }) => (
                  <BasicLayout
                    setFormVisibility={setFormVisibility}
                    setTooltipVisibility={setTooltipVisibility}
                    {...props}
                  />
                )}
                commandButtonComponent={CommandButton}
              />
              <Toolbar
                sx={{ display: "flex", justifyContent: "flex-end" }}
                flexibleSpaceComponent={() => (
                  <FlexibleSpace setFormVisibility={setFormVisibility} />
                )}
              />
              <ViewSwitcher switcherComponent={Switcher} />
              <DateNavigator />
              <Resources data={resources} />
            </Scheduler>
          </Box>
        ) : (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ))}
    </>
  );
}
