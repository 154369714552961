import * as React from "react";
import { Autocomplete, Checkbox, TextField, Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers/";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import BasicSelect from "../../components/common/BasicSelect";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import HistoryIcon from "@mui/icons-material/History";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import {
  getNextMonth,
  getNextWeek,
  getNextYear,
  getPreviousMonth,
  getPreviousWeek,
  getPreviousYear,
} from "../../tool";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const range_choices = [
  { id: "week", name: "Week" },
  { id: "month", name: "Month" },
  { id: "year", name: "Year" },
];

function DateFilter({ label, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect
      />
    </LocalizationProvider>
  );
}

export default function LessonsFilter({
  filter,
  setFilter,
  categories,
  programmes,
  levels,
  students,
}) {
  const [range, setRange] = React.useState("week");
  const [period, setPeriod] = React.useState("upcoming");

  const now_if_upcoming = (period, date_func) =>
    period === "upcoming" ? new Date() : date_func();

  const now_if_previous = (period, date_func) =>
    period === "previous" ? new Date() : date_func();

  const handleStartEndDate = (range_choice, period_choice) => {
    if (range_choice === "week") {
      setFilter({
        ...filter,
        startDate: now_if_upcoming(period_choice, getPreviousWeek),
        endDate: now_if_previous(period_choice, getNextWeek),
      });
    } else if (range_choice === "month") {
      setFilter({
        ...filter,
        startDate: now_if_upcoming(period_choice, getPreviousMonth),
        endDate: now_if_previous(period_choice, getNextMonth),
      });
    } else if (range_choice === "year") {
      setFilter({
        ...filter,
        startDate: now_if_upcoming(period_choice, getPreviousYear),
        endDate: now_if_previous(period_choice, getNextYear),
      });
    }
  };

  const handleRangeChange = (event) => {
    const choice = event.target.value;
    setRange(choice);
    handleStartEndDate(choice, period);
  };

  const handlePreviousChnage = (event, value) => {
    if (value !== null) {
      setPeriod(value);
      handleStartEndDate(range, value);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 1 }}>
        <ToggleButtonGroup
          value={period}
          onChange={handlePreviousChnage}
          exclusive
        >
          <ToggleButton value="previous">
            <HistoryIcon sx={{ mr: 1 }} />
            Previous
          </ToggleButton>
          <ToggleButton value="upcoming">
            <SkipNextOutlinedIcon sx={{ mr: 1 }} />
            Upcoming
          </ToggleButton>
          <ToggleButton value="all">
            <AllInclusiveIcon sx={{ mr: 1 }} />
            Recent
          </ToggleButton>
        </ToggleButtonGroup>
        <BasicSelect
          label="Range"
          choices={range_choices}
          selectedValue={range}
          handleChange={handleRangeChange}
          required
          width="9rem"
        />
        <DateFilter
          label="Start Date"
          value={filter.startDate}
          onChange={(value) => {
            setFilter({ ...filter, startDate: value });
            setPeriod(null);
          }}
        />
        <DateFilter
          label="End Date"
          value={filter.endDate}
          onChange={(value) => {
            setFilter({ ...filter, endDate: value });
          }}
        />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1, marginTop: 1 }}
      >
        <Autocomplete
          value={filter.categories}
          onChange={(event, newValue) => {
            setFilter({ ...filter, categories: newValue });
          }}
          limitTags={5}
          multiple
          options={categories}
          renderInput={(params) => <TextField {...params} label="Categories" />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
        />
        <Autocomplete
          value={filter.programmes}
          onChange={(event, newValue) => {
            setFilter({ ...filter, programmes: newValue });
          }}
          limitTags={5}
          multiple
          options={programmes}
          renderInput={(params) => <TextField {...params} label="Programmes" />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
        />
        <Autocomplete
          value={filter.levels}
          onChange={(event, newValue) => {
            setFilter({ ...filter, levels: newValue });
          }}
          limitTags={5}
          multiple
          options={levels}
          renderInput={(params) => <TextField {...params} label="Levels" />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
        />
        {students && (
          <Autocomplete
            value={filter.students}
            onChange={(event, newValue) => {
              setFilter({ ...filter, students: newValue });
            }}
            limitTags={5}
            multiple
            options={students}
            renderInput={(params) => <TextField {...params} label="Students" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name} (${option.id})`
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option.first_name} ${option.last_name} (${option.id})`}
              </li>
            )}
          />
        )}
      </Box>
    </>
  );
}
