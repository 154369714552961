import * as React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getStudentDetail } from "./studentSlice";

export default function StudentInfo({ studentUserId }) {
  const dispatch = useDispatch();
  const { schools } = useSelector((state) => state.student);

  React.useEffect(() => {
    dispatch(getStudentDetail({ studentUserId }));
  }, [studentUserId, dispatch]);

  const profileDetails = schools
    ? schools.map((school) => {
        return {
          name: school.school ? school.school.name : "N/A",
          detail: `${school.start_date} - ${
            school.end_date ? school.end_date : "now"
          }`,
        };
      })
    : null;

  return (
    <>
      {profileDetails && (
        <>
          <Typography variant="h5" textAlign="center">
            Student Information
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Box>
              {profileDetails.length > 0 ? (
                <Table>
                  <TableBody>
                    {profileDetails.map((item) => {
                      return item !== null ? (
                        <TableRow key={Math.random()}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.detail}</TableCell>
                        </TableRow>
                      ) : null;
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  textAlign="center"
                >
                  {"No school Detail"}
                </Typography>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
