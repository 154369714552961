import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const axios = require("axios");

export const getStudentDetail = createAsyncThunk(
  "student/getStudentDetail",
  async ({ studentUserId }, { getState }) => {
    const state = getState();

    const schools = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/students/${studentUserId}/school_items/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);

    const programmes = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/programmes/`,
      headers: {
        Authorization: state.user.token,
      },
      params: { students: studentUserId },
    }).then((response) => response.data);

    return {
      schools,
      programmes,
    };
  }
);

export const getLessonsDetail = createAsyncThunk(
  "student/getLessonsDetail",
  async ({ studentId, programmeId }, { getState }) => {
    const state = getState();

    const lessons = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/lessons/`,
      headers: {
        Authorization: state.user.token,
      },
      params: { students: studentId, programme: programmeId },
    }).then((response) => response.data);

    return lessons;
  }
);

export const getLessonComment = createAsyncThunk(
  "student/getLessonComment",
  async ({ studentId, programmeId, lessonId }, { getState }) => {
    const state = getState();

    const comments = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/comments/`,
      headers: {
        Authorization: state.user.token,
      },
      params: { lesson_id: lessonId },
    }).then((response) => response.data);

    const comment = comments.find(
      (comment) => comment.student.id.toString() === studentId.toString()
    );

    const objectiveItems = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/comments/${comment.id}/objective_items/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);

    const formattedObjectiveItems = {};
    objectiveItems.forEach((objectiveItem) => {
      if (formattedObjectiveItems[objectiveItem.objective.module]) {
        formattedObjectiveItems[objectiveItem.objective.module].push(
          objectiveItem
        );
      } else {
        formattedObjectiveItems[objectiveItem.objective.module] = [
          objectiveItem,
        ];
      }
    });

    const performanceItems = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/comments/${comment.id}/performance_items/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);

    const modules = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/modules/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);

    return {
      comment,
      objectiveItems,
      performanceItems,
      formattedObjectiveItems,
      modules,
    };
  }
);

export const addSchoolStudying = createAsyncThunk(
  "student.addSchoolStudying",
  async ({ studentId, data }, { getState }) => {
    const state = getState();
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/students/${studentId}/school_items/`,
      headers: {
        Authorization: state.user.token,
      },
      data: data,
    }).then((response) => response.data);
  }
);

export const updateSchoolStudying = createAsyncThunk(
  "student.updateSchoolStudying",
  async ({ studentId, data, school_itemsId, school_object }, { getState }) => {
    const state = getState();
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_URL}/students/${studentId}/school_items/${school_itemsId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: data,
    }).then((response) => response.data);
  }
);

export const deleteSchoolStudying = createAsyncThunk(
  "student.deleteSchoolStudying",
  async ({ studentId, school_itemsId }, { getState }) => {
    const state = getState();
    return await axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}/students/${studentId}/school_items/${school_itemsId}/`,
      headers: {
        Authorization: state.user.token,
      },
    }).then((response) => response.data);
  }
);

export const studentSlice = createSlice({
  name: "student",
  initialState: {isLoading : false},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStudentDetail.fulfilled, (state, action) => {
      const { schools, programmes } = action.payload;
      state.schools = schools;
      state.programmes = programmes;
    });
    builder.addCase(getLessonsDetail.fulfilled, (state, action) => {
      state.lessons = action.payload.filter(
        (lesson) => lesson.start_datetime && lesson.end_datetime
      );
    });
    builder.addCase(getLessonComment.fulfilled, (state, action) => {
      state.comment = action.payload;
    });
    builder.addCase(addSchoolStudying.fulfilled, (state, action) => {
      const schoolItem = {
        ...action.payload,
        school: null,
      };
      state.schools.push(schoolItem);
    });
    builder.addCase(updateSchoolStudying.fulfilled, (state, action) => {
      state.schools = state.schools.map((schoolItem) =>
        schoolItem.id === action.payload.id
          ? { ...action.payload, school: action.meta.arg.school_object }
          : schoolItem
      );
    });
    builder.addCase(deleteSchoolStudying.fulfilled, (state, action) => {
      state.schools = state.schools.filter(
        (school) => school.id !== action.meta.arg.school_itemsId
      );
    });
  },
});

export default studentSlice.reducer;
