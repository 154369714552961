import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const axios = require("axios");

export const getLessons = createAsyncThunk(
  "lessons/getLessons",
  async ({ startDatetime, endDatetime }, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/lessons/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {
        end_datetime__gt: startDatetime,
        start_datetime__lt: endDatetime,
      },
    }).then((response) => response.data);
  }
);

export const getOverlappedLessons = createAsyncThunk(
  "lessons/getOverlappedLessons",
  async ({ selectedStartTime, selectedEndTime }, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/lessons/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {
        end_datetime__gt: selectedStartTime,
        start_datetime__lt: selectedEndTime,
      },
    }).then((response) => response.data);
  }
);

export const getVenues = createAsyncThunk(
  "lessons/getVenues",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/venues/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const getVenueConditions = createAsyncThunk(
  "lessons/getVenueConditions",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/venue_conditions/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const createLesson = createAsyncThunk(
  "lessons/createLesson",
  async (
    {
      programmeId,
      startDate,
      endDate,
      selectedTeachers,
      selectedStudents,
      selectedVenue,
      selectedColor,
    },
    { getState }
  ) => {
    const state = getState();
    const addedLesson = await axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/lessons/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        programme: programmeId,
        start_datetime: startDate,
        end_datetime: endDate,
        teachers: selectedTeachers.map((teacher) => teacher.id),
        students: selectedStudents.map((student) => student.id),
        venue: selectedVenue ? selectedVenue.id : null,
        subclass: null,
        color: selectedColor.code,
      },
    }).then((response) => response.data);

    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/lessons/${addedLesson.id}`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const changeLesson = createAsyncThunk(
  "lessons/changeLesson",
  async (
    {
      lessonId,
      startDate,
      endDate,
      selectedTeachers,
      selectedStudents,
      selectedVenue,
      selectedColor,
    },
    { getState }
  ) => {
    const state = getState();
    await axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/lessons/${lessonId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        start_datetime: startDate,
        end_datetime: endDate,
        teachers: selectedTeachers.map((teacher) => teacher.id),
        students: selectedStudents.map((student) => student.id),
        venue: selectedVenue ? selectedVenue.id : null,
        subclass: null,
        color: selectedColor.code,
      },
    }).then((response) => response.data);

    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/lessons/${lessonId}`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const deleteLesson = createAsyncThunk(
  "lessons/deleteLesson",
  async ({ lessonId }, { getState }) => {
    const state = getState();
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}/lessons/${lessonId}`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const lessonsSlice = createSlice({
  name: "lessons",
  initialState: {
    loading: true,
    lessons: [],
    overlappedLessons: [],
    venues: [],
    venueConditions: [],
    appointmentFormData: {
      selectedCategory: null,
      selectedProgramme: null,
      selectedLevel: null,
      selectedTeachers: [],
      selectedStudents: [],
      selectedVenueConditions: [],
      selectedVenue: null,
      selectedColor: null,
    },
    lessonChanged: false,
  },
  reducers: {
    setAppointmentFormData: (state, action) => {
      state.appointmentFormData = action.payload;
    },
    resetAppointmentFormData: (state) => {
      state.appointmentFormData = {
        selectedCategory: null,
        selectedProgramme: null,
        selectedLevel: null,
        selectedTeachers: [],
        selectedStudents: [],
        selectedVenueConditions: [],
        selectedVenue: null,
        selectedColor: null,
      };
    },
    setChanged: (state, action) => {
      state.lessonChanged = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessons.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLessons.fulfilled, (state, action) => {
      state.loading = false;
      state.lessons = action.payload.filter(
        (lesson) => lesson.start_datetime && lesson.end_datetime
      );
    });
    builder.addCase(getOverlappedLessons.fulfilled, (state, action) => {
      state.overlappedLessons = action.payload;
    });
    builder.addCase(getVenues.fulfilled, (state, action) => {
      state.venues = action.payload;
    });
    builder.addCase(getVenueConditions.fulfilled, (state, action) => {
      state.venueConditions = action.payload;
    });
    builder.addCase(createLesson.fulfilled, (state, action) => {
      state.lessons.push(action.payload);
      state.appointmentFormData = {
        selectedCategory: null,
        selectedProgramme: null,
        selectedLevel: null,
        selectedTeachers: [],
        selectedStudents: [],
        selectedVenueConditions: [],
        selectedVenue: null,
        selectedColor: null,
      };
      state.lessonChanged = false;
    });
    builder.addCase(changeLesson.fulfilled, (state, action) => {
      const { lessonId } = action.meta.arg;
      state.lessons = state.lessons.map((lesson) =>
        lesson.id === lessonId ? action.payload : lesson
      );
      state.appointmentFormData = {
        selectedCategory: null,
        selectedProgramme: null,
        selectedLevel: null,
        selectedTeachers: [],
        selectedStudents: [],
        selectedVenueConditions: [],
        selectedVenue: null,
        selectedColor: null,
      };
      state.lessonChanged = false;
    });
    builder.addCase(deleteLesson.fulfilled, (state, action) => {
      const { lessonId } = action.meta.arg;
      state.lessons = state.lessons.filter((lesson) =>
        lesson.id === lessonId ? action.payload : lesson
      );
    });
  },
});

export const {
  changeFilter,
  setAppointmentFormData,
  resetAppointmentFormData,
  setChanged,
} = lessonsSlice.actions;

export default lessonsSlice.reducer;
