import React from "react";
import Joi from "joi-browser";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import EditDialog from "../common/EditDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  getVenueConditions,
  createVenueCondition,
  updateVenueCondition,
  deleteVenueConditionbyId,
} from "../../app/entities/venueConditions";
import BucketDialog from "./BucketDialog";

export default function VenueEditDialog(props) {
  const { preValues, values, setValues, targetName, open, handleClose } = props;
  const dispatch = useDispatch();

  const [openVenueConditionsDialog, setOpenVenueConditionsDialog] =
    React.useState(false);

  const venueConditions = useSelector(getVenueConditions);

  const columns = [
    {
      field: "name",
      headerName: "Venue Name",
      required: true,
    },
    {
      field: "location",
      headerName: "Location",
    },
    {
      render_type: "autocomplete",
      field: "conditions",
      headerName: "Conditions",
      choices: venueConditions,
      multiple: true,
      displayLabel: (obj) => obj.description,
      extraEndAdornment: (
        <IconButton
          color="primary"
          onClick={() => {
            setOpenVenueConditionsDialog(true);
          }}
        >
          <AddIcon />
        </IconButton>
      ),
    },
  ];

  const schema = {
    name: Joi.string().required(),
  };

  return (
    <>
      <EditDialog
        columns={columns}
        schema={schema}
        preValues={preValues}
        values={values}
        setValues={setValues}
        targetName={targetName}
        open={open}
        handleClose={handleClose}
      />
      <BucketDialog
        dialogTitle={"Venue Conditions"}
        openDialog={openVenueConditionsDialog}
        setOpenDialog={setOpenVenueConditionsDialog}
        textFieldLabel={"Venue Conditions"}
        items={venueConditions}
        handleAdd={(inputValue) => {
          dispatch(createVenueCondition({ description: inputValue }));
        }}
        handleModify={(preVenueCondition, venueCondition) => {
          dispatch(updateVenueCondition(preVenueCondition, venueCondition));
        }}
        handleDelete={(venueCondition) => {
          dispatch(deleteVenueConditionbyId(venueCondition.id));
        }}
        displayAttribute="description"
      />
    </>
  );
}
