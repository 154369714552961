import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { consumeBagItems, getBagItems } from "./bagSlice";
import Bold from "../../components/Text/Bold";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Button,
  TextField,
  Box,
} from "@mui/material";

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export default function StudentStampsComsumptionDialog({
  student,
  open,
  setOpen,
}) {
  const dispatch = useDispatch();

  const bagItems = useSelector((state) =>
    state.bag.items.filter((bagItem) => bagItem.isUsed === false)
  );

  const [numStampsConsume, setNumstampsConsume] = React.useState(null);
  const [reason, setReason] = React.useState("");

  React.useEffect(() => {
    if (student && student.id != undefined) {
      dispatch(getBagItems({ bagId: student.id }));
    }
  }, [student, dispatch]);

  const handleOnClose = () => {
    setOpen(false);
    setNumstampsConsume(null);
    setReason("");
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleOnClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle variant="h5" sx={{ marginTop: "20px" }}>
        Consume Stamp - {student.first_name} {student.last_name}
      </DialogTitle>
      <DialogContent>
        {bagItems.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {" "}
            <DialogContentText id="alert-dialog-description2">
              {`Number Of Stamp : ${bagItems.length}`}
            </DialogContentText>
            <Autocomplete
              options={range(1, bagItems.length)}
              value={numStampsConsume}
              sx={{ width: 300 }}
              getOptionLabel={(option) => option.toString()}
              renderInput={(params) => (
                <TextField {...params} label="Number Of Stamp To Be Comsumed" />
              )}
              onChange={(event, newValue) => {
                console.log(newValue);
                setNumstampsConsume(newValue);
              }}
            />
            <TextField
              value={reason}
              label={"Reason"}
              multiline={true}
              maxRows={10}
              minRows={5}
              onChange={(event) => {
                setReason(event.target.value);
              }}
            />
          </Box>
        ) : (
          <p>
            <Bold>
              {student.first_name} {student.last_name}
            </Bold>{" "}
            {"currently hasn't own any stamps"}
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!numStampsConsume || reason === ""}
          onClick={() => {
            dispatch(
              consumeBagItems({
                bagId: student.id,
                number: numStampsConsume,
                reason,
              })
            );
            handleOnClose();
          }}
        >
          Comfirm
        </Button>
        <Button onClick={handleOnClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
