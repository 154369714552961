import React from "react";

import ModuleHistoryPage from "./ModuleHistoryPage";
import ObjectiveHistoryPage from "./ObjectiveHistoryPage";
import PerformanceHistoryPage from "./PerformanceHistoryPage";
import TabWithContent from "../../../components/common/TabWithContent";
import useTab from "../../../hooks/useTab";

export default function AuditPanelPage() {
  const [tab, handleTabChange] = useTab("table", "auditPanel");

  const pages = [
    {
      label: "Module History",
      component: <ModuleHistoryPage />,
      route: "module",
    },
    {
      label: "Objective History",
      component: <ObjectiveHistoryPage />,
      route: "objective",
    },
    {
      label: "Goal History",
      component: <PerformanceHistoryPage />,
      route: "goal",
    },
  ];

  return (
    <TabWithContent pages={pages} tab={tab} handleTabChange={handleTabChange} />
  );
}
