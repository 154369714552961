import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { combineState, seperateState } from "../normalize";

const endpoint = endpoints["venueConditions"];

const slice = createSlice({
  name: "venueConditions",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    venueConditionListRequest: (venueConditions, action) => {
      venueConditions.loading = true;
    },
    venueConditionListReceived: (venueConditions, { payload }) => {
      seperateState(venueConditions, payload);
      venueConditions.loading = false;
    },
    venueConditionRequest: (venueConditions, action) => {
      venueConditions.loading = true;
    },
    venueConditionReceived: (venueConditions, { payload }) => {
      venueConditions.allIds.push(payload.id);
      venueConditions.byId[payload.id] = payload;
      venueConditions.loading = false;
    },
    venueConditionCreate: (venueConditions, { payload }) => {
      venueConditions.allIds.push(payload.id);
      venueConditions.byId[payload.id] = payload;
    },
    venueConditionCreateRollback: (venueConditions, { payload }) => {
      venueConditions.allIds = venueConditions.filter(
        (venueCondition) => venueCondition !== payload.id
      );
    },
    venueConditionUpdate: (venueConditions, { payload }) => {
      venueConditions.byId[payload.id] = payload;
    },
    venueConditionUpdateRollback: (venueConditions, { payload }) => {
      venueConditions.byId[payload.id] = payload;
    },
    venueConditionDelete: (venueConditions, { payload }) => {
      venueConditions.allIds = venueConditions.allIds.filter(
        (id) => id !== payload.id
      );
    },
    venueConditionDeleteRollback: (venueConditions, { payload }) => {
      venueConditions.allIds = [...venueConditions.allIds, payload.id];
    },
  },
});

const {
  venueConditionListRequest,
  venueConditionListReceived,
  venueConditionRequest,
  venueConditionReceived,
  venueConditionCreate,
  venueConditionUpdate,
  venueConditionUpdateRollback,
  venueConditionDelete,
  venueConditionDeleteRollback,
} = slice.actions;

// action
export const getVenueConditionList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: venueConditionListRequest.type,
    onSuccess: venueConditionListReceived.type,
  });

export const retrieveVenueCondition = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "get",
    onStart: venueConditionRequest.type,
    onSuccess: venueConditionReceived.type,
  });

export const createVenueCondition = (venueCondition) =>
  apiCallBegan({
    endpoint,
    method: "post",
    data: venueCondition,
    onSuccess: venueConditionCreate.type,
  });
export const updateVenueCondition = (preVenueCondition, venueCondition) =>
  apiCallBegan({
    endpoint: endpoint + venueCondition.id + "/",
    method: "patch",
    data: venueCondition,
    onStart: venueConditionUpdate.type,
    onStartPayload: venueCondition,
    onError: venueConditionUpdateRollback.type,
    onErrorPayload: preVenueCondition,
  });

export const deleteVenueConditionbyId = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "delete",
    onStart: venueConditionDelete.type,
    onStartPayload: { id },
    onError: venueConditionDeleteRollback.type,
    onErrorPayload: { id },
  });

// selector
export const getVenueConditions = (state) =>
  combineState(state.entities.venueConditions);

export const getVenueConditionLoadingStatus = (state) =>
  state.entities.venueConditions.loading;

export const getVenueConditionbyId = (id) => (state) =>
  state.entities.venueConditions.byId[id];

export default slice.reducer;
