import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getPerformanceHistoryList } from "../../../app/entities/performanceHistory";
import PerformanceHistoryTable from "../../../components/Table/PerformanceHistoryTable";

export default function PerformanceHistoryPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPerformanceHistoryList());
  }, [dispatch]);

  return <PerformanceHistoryTable />;
}
