import React from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function TabWithContent({ pages, tab, handleTabChange }) {
  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="basic tab">
            {pages.map(({ label, route }) => (
              <Tab key={label} label={label} id={label} value={route}></Tab>
            ))}
          </TabList>
        </Box>
        {pages.map(({ label, component, route }) => (
          <TabPanel key={label} value={route}>
            {component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
