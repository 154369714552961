import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import SchoolsBucket from "./SchoolsBucket";

export default function SchoolsBucketDialog({
  openDialog,
  setOpenDialog,
  dialogTitle,
  textFieldLabel,
  items,
  handleAdd,
  handleModify,
  handleDelete,
}) {
  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
      }}
      fullWidth
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <SchoolsBucket
          items={items}
          handleAdd={handleAdd}
          handleModify={handleModify}
          handleDelete={handleDelete}
          textFieldLabel={textFieldLabel}
        />
      </DialogContent>
      <DialogActions>
        <Button color="success" onClick={() => setOpenDialog(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
