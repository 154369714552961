import { combineReducers } from "redux";
import usersReducer from "./entities/users";
import studentsReducer from "./entities/students";
import teachersReducer from "./entities/teachers";
import profilesReducer from "./entities/profiles";
import categoriesReducer from "./entities/categories";
import venuesReducer from "./entities/venues";
import venueConditionsReducer from "./entities/venueConditions";
import moduleHistoryReducer from "./entities/moduleHistory";
import objectiveHistoryReducer from "./entities/objectiveHistory";
import performanceHistoryReducer from "./entities/performanceHistory";
import programmesReducer from "./entities/programmes";
import modulesReducer from "./entities/modules";
import objectivesReducer from "./entities/objectives";

export default combineReducers({
  users: usersReducer,
  students: studentsReducer,
  teachers: teachersReducer,
  profiles: profilesReducer,
  categories: categoriesReducer,
  venues: venuesReducer,
  programmes: programmesReducer,
  venueConditions: venueConditionsReducer,
  moduleHistory: moduleHistoryReducer,
  objectiveHistory: objectiveHistoryReducer,
  performanceHistory: performanceHistoryReducer,
  objectives: objectivesReducer,
  modules: modulesReducer,
});
