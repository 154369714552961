import React from "react";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "styled-components";
import EmptyItem from "../EmptyItem";

export default function CollaspeProgramme(props) {
  const { open, programmes, handleMenuSelect, selectedProgramme } = props;
  const theme = useTheme();

  const primaryColorIfSelect = (id) => {
    if (selectedProgramme !== null && selectedProgramme.id === id)
      return theme.palette.primary.main;
  };
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List disablePadding>
        {programmes.map((programme) => (
          <ListItemButton
            key={programme.id}
            sx={{ pl: 4 }}
            onClick={() => handleMenuSelect(programme)}
          >
            <ListItemText
              primary={`${programme.name} - lv. ${programme.level}`}
              sx={{ color: primaryColorIfSelect(programme.id) }}
            />
          </ListItemButton>
        ))}
        {programmes.length === 0 && <EmptyItem />}
      </List>
    </Collapse>
  );
}
