import { useState } from "react";
import { useDispatch } from "react-redux";
import { normalize_object, remove_unreleated_field } from "../app/normalize";

export function useEdit(
  dispatchEditFunction,
  { related_fields, should_normalize } = {}
) {
  const dispatch = useDispatch();
  const [prevObject, setPrevObject] = useState({});
  const [modifiedObject, setModifiedObject] = useState({});
  const [editMode, setEditMode] = useState(false);

  const handleEditOpen = (obj) => {
    setEditMode(true);

    if (related_fields) remove_unreleated_field(obj, related_fields);
    setPrevObject(obj);
    setModifiedObject(obj);
  };

  const handleEditClose = (isConfirm) => {
    let prev = prevObject;
    let modified = modifiedObject;

    if (should_normalize) {
      prev = normalize_object(prevObject);
      modified = normalize_object(modifiedObject);
    }

    setEditMode(false);
    if (isConfirm) dispatch(dispatchEditFunction(prev, modified));
  };

  return [
    editMode,
    prevObject,
    modifiedObject,
    setModifiedObject,
    handleEditOpen,
    handleEditClose,
  ];
}
