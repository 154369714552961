import * as React from "react";
import {
  Container,
  Box,
  CssBaseline,
  Typography,
  TextField,
  Avatar,
  Button,
} from "@mui/material/";
import { useDispatch } from "react-redux";
import PasswordIcon from "@mui/icons-material/Password";
import { sendEmailResetPassword } from "../user/userSlice";

export default function ForgotPasswordPage() {
  const [isWrongNewEmail, setIsWrongNewEmail] = React.useState(false);
  const [isSuccessSubmit, setIsSuccessSubmit] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(
      sendEmailResetPassword({
        email,
        setIsWrongNewEmail,
        setIsSuccessSubmit,
      })
    );
  };
  const helperTextReturn = () => {
    if (isWrongNewEmail) {setMessage("Invalid Email Format");}
    else if (isSuccessSubmit) { setMessage("Sucessfully Send");}
    else {setMessage("")};
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CssBaseline />
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <PasswordIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {"Forgot Password"}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              helperText={message}
              error={isWrongNewEmail}
              margin="normal"
              required
              fullWidth
              id="useremail"
              label="Email"
              name="Email"
              autoFocus
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <Button
              type="submit"
              fullWidth
              disabled={email === ""}
              variant="contained"
              id="loginInButton"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
