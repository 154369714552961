import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  changeLesson,
  createLesson,
  deleteLesson,
  setChanged,
} from "../lessonsSlice";
import ConfirmationDialog from "./ConfirmationDialog";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import TimePickerPanel from "./appointmentFormComponent/TimePickerPanel";
import SelectPanel from "./appointmentFormComponent/SelectPanel";
import SubmitPanel from "./appointmentFormComponent/SubmitPanel";

export default function BasicLayout({
  setFormVisibility,
  setTooltipVisibility,
  onFieldChange,
  appointmentData,
  textEditorComponent,
  resourceEditorComponent,
  booleanEditorComponent,
  labelComponent,
  dateEditorComponent,
  ...restProps
}) {
  const dispatch = useDispatch();
  const isNewAppointment = appointmentData.id === undefined;
  const allCourses = useSelector((state) => state.courses.courses);
  const allVenueChoices = useSelector((state) => state.lessons.venues);
  const venueConditionChoices = useSelector(
    (state) => state.lessons.venueConditions
  );
  const {
    selectedCategory,
    selectedProgramme,
    selectedLevel,
    selectedTeachers,
    selectedStudents,
    selectedVenueConditions,
    selectedVenue,
    selectedColor,
  } = useSelector((state) => state.lessons.appointmentFormData);

  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState("");

  const [categoryChoices, setCategoryChoices] = React.useState([]);
  const [programmeChoices, setProgrammeChoices] = React.useState([]);
  const [levelChoices, setLevelChoices] = React.useState([]);
  const [teacherChoices, setTeacherChoices] = React.useState([]);
  const [studentChoices, setStudentChoices] = React.useState([]);
  const [venueChoices, setVenueChoices] = React.useState([]);

  function allSelectedTeachersInCourse(selectedTeachers, allTeachers) {
    for (let selectedTeacher of selectedTeachers) {
      if (!allTeachers.find((teacher) => teacher.id === selectedTeacher.id)) {
        return false;
      }
    }
    return true;
  }

  React.useEffect(() => {
    setCategoryChoices([
      ...new Set(
        allCourses
          .filter(
            (course) =>
              (!selectedProgramme || course.name === selectedProgramme) &&
              (!selectedLevel || course.level === selectedLevel) &&
              (selectedTeachers.length === 0 ||
                allSelectedTeachersInCourse(
                  selectedTeachers,
                  course.teachers
                )) &&
              course.category_name
          )
          .map((course) => course.category_name)
      ),
    ]);
  }, [
    selectedCategory,
    selectedProgramme,
    selectedLevel,
    selectedTeachers,
    selectedStudents,
    selectedVenue,
    allCourses,
  ]);

  React.useEffect(() => {
    setProgrammeChoices([
      ...new Set(
        allCourses
          .filter(
            (course) =>
              (!selectedCategory ||
                course.category_name === selectedCategory) &&
              (!selectedLevel || course.level === selectedLevel) &&
              (selectedTeachers.length === 0 ||
                allSelectedTeachersInCourse(
                  selectedTeachers,
                  course.teachers
                )) &&
              course.name
          )
          .map((course) => course.name)
      ),
    ]);
  }, [
    selectedCategory,
    selectedProgramme,
    selectedLevel,
    selectedTeachers,
    selectedStudents,
    selectedVenue,
    allCourses,
  ]);

  React.useEffect(() => {
    setLevelChoices([
      ...new Set(
        allCourses
          .filter(
            (course) =>
              (!selectedCategory ||
                course.category_name === selectedCategory) &&
              (!selectedProgramme || course.name === selectedProgramme) &&
              (selectedTeachers.length === 0 ||
                allSelectedTeachersInCourse(
                  selectedTeachers,
                  course.teachers
                )) &&
              course.level
          )
          .map((course) => course.level)
      ),
    ]);
  }, [
    selectedCategory,
    selectedProgramme,
    selectedLevel,
    selectedTeachers,
    selectedStudents,
    selectedVenue,
    allCourses,
  ]);

  React.useEffect(() => {
    setTeacherChoices(
      allCourses
        .filter(
          (course) =>
            (!selectedCategory || course.category_name === selectedCategory) &&
            (!selectedProgramme || course.name === selectedProgramme) &&
            (!selectedLevel || course.level === selectedLevel) &&
            course.teachers.length > 0
        )
        .map((course) => course.teachers)
        .flat()
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        )
        .sort((teacher1, teacher2) => {
          return `${teacher1.first_name} ${teacher1.last_name}`.localeCompare(
            `${teacher2.first_name} ${teacher2.last_name}`
          );
        })
    );
  }, [
    selectedCategory,
    selectedProgramme,
    selectedLevel,
    selectedTeachers,
    selectedStudents,
    selectedVenue,
    allCourses,
  ]);

  React.useEffect(() => {
    setStudentChoices(
      allCourses
        .filter(
          (course) =>
            (!selectedCategory || course.category_name === selectedCategory) &&
            (!selectedProgramme || course.name === selectedProgramme) &&
            (!selectedLevel || course.level === selectedLevel) &&
            (selectedTeachers.length === 0 ||
              allSelectedTeachersInCourse(selectedTeachers, course.teachers)) &&
            course.students.length > 0
        )
        .map((course) => course.students)
        .flat()
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        )
        .sort((student1, student2) => {
          return `${student1.first_name} ${student1.last_name}`.localeCompare(
            `${student2.first_name} ${student2.last_name}`
          );
        })
    );
  }, [
    selectedCategory,
    selectedProgramme,
    selectedLevel,
    selectedTeachers,
    selectedStudents,
    selectedVenue,
    allCourses,
  ]);

  React.useEffect(() => {
    setVenueChoices(
      allVenueChoices.filter((venueChoice) => {
        const venueChoiceConditionsId = venueChoice.conditions.map(
          (venueCondition) => venueCondition.id
        );
        const selectedVenueConditionsId = selectedVenueConditions.map(
          (selectedVenueCondition) => selectedVenueCondition.id
        );
        const intersection = [...venueChoiceConditionsId].filter((element) =>
          selectedVenueConditionsId.includes(element)
        );

        return intersection.length === selectedVenueConditionsId.length;
      })
    );
  }, [selectedVenueConditions, allVenueChoices]);

  const addLesson =
    ({ startDate, endDate }) =>
    () => {
      const programmeToBeAdded = allCourses.find(
        (course) =>
          course.category_name === selectedCategory &&
          course.name === selectedProgramme &&
          course.level === selectedLevel
      );
      if (programmeToBeAdded && selectedColor) {
        dispatch(
          createLesson({
            programmeId: programmeToBeAdded.id,
            startDate,
            endDate,
            selectedTeachers,
            selectedStudents,
            selectedVenue,
            selectedColor,
          })
        );
        setFormVisibility((prevVisibility) => !prevVisibility);
      } else if (!programmeToBeAdded) {
        toast.error("Please select a valid course.");
      } else {
        toast.error("Please assign a color to this lesson.");
      }
    };

  const editLesson =
    ({ startDate, endDate }) =>
    () => {
      dispatch(
        changeLesson({
          lessonId: appointmentData.lessonId,
          startDate,
          endDate,
          selectedTeachers,
          selectedStudents,
          selectedVenue,
          selectedColor,
        })
      );
      setFormVisibility((prevVisibility) => !prevVisibility);
      setChanged(false);
    };

  const removeLesson = () => {
    dispatch(
      deleteLesson({
        lessonId: appointmentData.lessonId,
      })
    );
    setFormVisibility((prevVisibility) => !prevVisibility);
    setChanged(false);
  };

  return (
    <AppointmentForm.BasicLayout
      appointmentData={appointmentData}
      onFieldChange={onFieldChange}
      textEditorComponent={(props) => null}
      booleanEditorComponent={(props) => null}
      resourceEditorComponent={(props) => null}
      labelComponent={(props) => null}
      dateEditorComponent={(props) => null}
      {...restProps}
    >
      <ConfirmationDialog
        action={action}
        component="Form"
        open={open}
        setOpen={setOpen}
        removeLesson={removeLesson}
        setTooltipVisibility={setTooltipVisibility}
      />

      <AppointmentForm.Label text="Lesson Info" type="title" />
      <TimePickerPanel
        appointmentData={appointmentData}
        onFieldChange={onFieldChange}
      />
      <SelectPanel
        isNewAppointment={isNewAppointment}
        choices={{
          categoryChoices,
          programmeChoices,
          levelChoices,
          teacherChoices,
          studentChoices,
          venueConditionChoices,
          venueChoices,
        }}
        appointmentData={appointmentData}
      />
      <SubmitPanel
        isNewAppointment={isNewAppointment}
        appointmentData={appointmentData}
        addLesson={addLesson}
        editLesson={editLesson}
        setAction={setAction}
        setOpen={setOpen}
      />
    </AppointmentForm.BasicLayout>
  );
}
