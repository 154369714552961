import React, { useState } from "react";
import { ListItem, ListItemIcon, TextField, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { createObjective } from "../../../../app/entities/objectives";
import { useDispatch } from "react-redux";

export default function ObjectiveInput(props) {
  const { module } = props;
  const dispatch = useDispatch();
  const [objective, setObjective] = useState({ name: "" });

  const handleTextFieldChange = (event) => {
    setObjective({ name: event.target.value });
  };

  const handleAdd = () => {
    dispatch(createObjective({ ...objective, module: module.id }));
    setObjective({ name: "" });
  };

  return (
    <ListItem>
      <TextField
        label="Objective Name"
        value={objective.name}
        onChange={handleTextFieldChange}
        variant="filled"
        fullWidth
      />
      <ListItemIcon sx={{ marginLeft: "1rem" }}>
        <IconButton onClick={handleAdd}>
          <AddIcon />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
}
