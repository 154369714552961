import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import {
  updateUserPassword,
  updateUserEmail,
  updateUserPhone,
} from "./userSlice";
import { isValidEmail } from "../../validator/validateString";

function CheckNewPassword(verifyNewPassword, newPassword) {
  if (
    verifyNewPassword !== "" &&
    newPassword !== "" &&
    verifyNewPassword === newPassword
  ) {
    return true;
  }
  return false;
}

export default function UserProfileReset() {
  const [openReset, setOpenReset] = React.useState(false);
  const [openResetAlertConfirm, setOpenResetAlertConfirm] =
    React.useState(false);
  const [openResetAlertClose, setOpenResetAlertClose] = React.useState(false);

  const handleCloseResetAlertComfirmYes = () => {
    const isPasswordVerified = CheckNewPassword(
      newPasswordRef.current.value,
      verifyNewPasswordRef.current.value
    );
    if (isPasswordVerified) {
      const oldPassword = passwordRef.current.value;
      const newPassword = newPasswordRef.current.value;

      dispatch(
        updateUserPassword({
          oldPassword,
          newPassword,
          setIsWrongNewPassword,
        })
      );
    }
    if (
      phoneRef.current.value !== "" &&
      phoneRef.current.value !== profile.phone
    ) {
      const newPhone = phoneRef.current.value;
      dispatch(updateUserPhone({ newPhone, setSuccessPhoneChange }));
    }
    if (emailRef.current.value !== "" && emailRef.current.value !== email) {
      const newEmail = emailRef.current.value;
      dispatch(updateUserEmail({ newEmail, setIsWrongNewEmail }));
    }

    setOpenResetAlertConfirm(false);
    setOpenReset(false);
  };

  const handleCloseReset = () => {
    setIsWrongNewPassword(false);
    setIsWrongNewEmail(false);
    setSuccessPhoneChange(false);
    setIsPasswordVerified(true);
    setOpenReset(false);
  };

  const dispatch = useDispatch();
  const { email, profile } = useSelector((state) => state.user);

  const [isWrongNewPassword, setIsWrongNewPassword] = React.useState(false);
  const [isWrongNewEmail, setIsWrongNewEmail] = React.useState(false);
  const [isNoChange, setIsNoChange] = React.useState(true);
  const [successPhoneChange, setSuccessPhoneChange] = React.useState(false);
  const [isPasswordVerified, setIsPasswordVerified] = React.useState(true);

  const isError =
    isWrongNewPassword ||
    isWrongNewEmail ||
    successPhoneChange ||
    !isPasswordVerified;

  function checkNoChange() {
    setIsNoChange(
      emailRef.current.value === email &&
        phoneRef.current.value === profile.phone &&
        (passwordRef.current.value === "" ||
          newPasswordRef.current.value === "")
    );
  }

  const passwordRef = React.useRef("");
  const newPasswordRef = React.useRef("");
  const verifyNewPasswordRef = React.useRef("");
  const phoneRef = React.useRef("");
  const emailRef = React.useRef("");

  return (
    <>
      {profile ? (
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setOpenReset(true);
            }}
            sx={{ marginLeft: "10px" }}
          >
            User Profile Reset
          </Button>
          <Dialog
            open={openReset}
            onClose={() => {
              if (isNoChange) {
                setOpenReset(false);
              } else {
                setOpenResetAlertClose(true);
              }
            }}
            BackdropProps={{
              style: { backgroundColor: "gray", opacity: 0.95 },
            }}
            fullWidth={true}
          >
            <>
              <DialogTitle
                variant="h5"
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              >
                {"Reset User Profile"}
              </DialogTitle>
              <DialogContent>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    error={isWrongNewPassword}
                    sx={{ marginBottom: "20px", marginTop: "10px" }}
                    label="Password"
                    inputRef={passwordRef}
                    defaultValue={""}
                    helperText={
                      isWrongNewPassword ? "Incorrect Existing Password" : ""
                    }
                    aria-describedby=""
                    onChange={(event) => {
                      checkNoChange();
                    }}
                    type="password"
                  />
                  <TextField
                    error={isWrongNewPassword || !isPasswordVerified}
                    sx={{ marginBottom: "20px" }}
                    label="New Password"
                    defaultValue={""}
                    helperText={
                      isWrongNewPassword && !isPasswordVerified
                        ? "Incorrect Existing Password"
                        : isPasswordVerified
                        ? ""
                        : "Not Equal to New Password"
                    }
                    inputRef={newPasswordRef}
                    aria-describedby=""
                    onChange={(event) => {
                      if (
                        event.target.value !==
                        verifyNewPasswordRef.current.value
                      ) {
                        setIsPasswordVerified(false);
                      } else {
                        setIsPasswordVerified(true);
                      }
                      checkNoChange();
                    }}
                    type="password"
                  />
                  <TextField
                    error={!isPasswordVerified}
                    sx={{ marginBottom: "20px" }}
                    label="Verify New Password"
                    defaultValue={""}
                    helperText={
                      isPasswordVerified ? "" : "Not Equal to New Password"
                    }
                    inputRef={verifyNewPasswordRef}
                    aria-describedby="haha"
                    onChange={(event) => {
                      if (newPasswordRef.current.value !== event.target.value) {
                        setIsPasswordVerified(false);
                      } else {
                        setIsPasswordVerified(true);
                      }
                      checkNoChange();
                    }}
                    type="password"
                  />
                  <TextField
                    error={successPhoneChange}
                    sx={{ marginBottom: "20px" }}
                    defaultValue={profile.phone}
                    label="Phone"
                    inputRef={phoneRef}
                    aria-describedby=""
                    onChange={() => {
                      checkNoChange();
                    }}
                  />
                  <TextField
                    error={isWrongNewEmail}
                    sx={{ marginBottom: "20px" }}
                    defaultValue={email}
                    label="Email"
                    inputRef={emailRef}
                    aria-describedby=""
                    helperText={isWrongNewEmail ? "Invalid Email Format" : ""}
                    onChange={(event) => {
                      if (
                        isValidEmail(event.target.value) ||
                        event.target.value === ""
                      ) {
                        setIsWrongNewEmail(false);
                      } else {
                        setIsWrongNewEmail(true);
                      }
                      checkNoChange();
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  disabled={isNoChange || isError}
                  onClick={() => {
                    setOpenResetAlertConfirm(true);
                  }}
                >
                  Confirm
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (isNoChange) {
                      setOpenReset(false);
                    } else {
                      setOpenResetAlertClose(true);
                    }
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </>
          </Dialog>

          <Dialog
            open={openResetAlertConfirm}
            onClose={() => {
              setOpenResetAlertConfirm(false);
            }}
          >
            <DialogContent>{"Are you sure about that?"}</DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  handleCloseResetAlertComfirmYes();
                  setIsNoChange(true);
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenResetAlertConfirm(false);
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openResetAlertClose}
            onClose={() => {
              setOpenResetAlertClose(false);
            }}
          >
            <DialogContent>{"Are you sure about that?"}</DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  handleCloseReset();
                  setIsNoChange(true);
                  setOpenResetAlertClose(false);
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenResetAlertClose(false);
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    </>
  );
}
