import * as React from "react";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";

export default function TablePagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    pageCount > 1 && (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        siblingCount={2}
        boundaryCount={2}
      />
    )
  );
}

// import React from "react";
// import _ from "lodash";
// import { PropTypes } from "prop-types";

// function Pagination(props) {
//   return (
//     <nav aria-label="Page navigation example">
//       <ul class="pagination">
//         <li class="page-item">
//           <a class="page-link" aria-label="Previous">
//             <span aria-hidden="true">&laquo;</span>
//           </a>
//         </li>
//         {_.range(1, props.pageNumber + 1).map((page) => (
//           <li class="page-item">
//             <a className="page-link">{page}</a>
//           </li>
//         ))}
//         <li class="page-item">
//           <a class="page-link" aria-label="Next">
//             <span aria-hidden="true">&raquo;</span>
//           </a>
//         </li>
//       </ul>
//     </nav>
//   );
// }

// Pagination.propTypes = {
//   pageNumber: PropTypes.number,
//   currentPage: PropTypes.number,
// };

// export default Pagination;
