import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getTeacherList } from "../../../app/entities/teachers";
import TeacherTable from "../../../components/Table/TeacherTable";

export default function TeacherTablePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeacherList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <TeacherTable />
    </React.Fragment>
  );
}
