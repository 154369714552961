import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const axios = require("axios");

export const getTokenFromServer = createAsyncThunk(
  "user/getTokenFromServer",
  async ({ username, password, setIsWrongPassword, setServerError }) => {
    return axios
      .post(`${process.env.REACT_APP_URL}/auth/token/login/`, {
        username,
        password,
      })
      .then((response) => response.data);
  }
);

export const userLogout = createAsyncThunk(
  "user/userLogout",
  async (arg, { getState }) => {
    const state = getState();
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/auth/token/logout/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {},
    }).then((response) => response.data);
  }
);

export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/auth/users/me/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/profiles/me/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const getMySettings = createAsyncThunk(
  "user/getMySettings",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/mysetting/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

// export const getUserStudentInfo = createAsyncThunk(
//   "user/getUserStudentInfo",
//   async (arg, { getState }) => {
//     const state = getState();
//     return axios({
//       method: "get",
//       url: `${process.env.REACT_APP_URL}/students/me/`,
//       headers: {
//         Authorization: state.user.token,
//       },
//       params: {},
//     }).then((response) => response.data);
//   }
// );

export const getUserTeacherInfo = createAsyncThunk(
  "user/getUserTeacherInfo",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/teachers/me/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const updateUserPassword = createAsyncThunk(
  "user/updateUserPassword",
  async ({ oldPassword, newPassword, setIsWrongNewPassword }, { getState }) => {
    const state = getState();
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/auth/users/set_password/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        new_password: newPassword,
        current_password: oldPassword,
      },
    }).then((response) => response.data);

    return { response };
  }
);

export const updateUserPhone = createAsyncThunk(
  "user/updateUserPhone",
  async ({ newPhone, setSuccessPhoneChange }, { getState }) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/profiles/me/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        phone: newPhone,
      },
    }).then((response) => response.data);
  }
);

export const updateUserEmail = createAsyncThunk(
  "user/updateUserEmail",
  async ({ newEmail, setIsWrongNewEmail }, { getState }) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/auth/users/me/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        email: newEmail,
      },
    }).then((response) => response.data);
  }
);

export const updateUserTeacherInfo = createAsyncThunk(
  "user/updateUserTeacherInfo",
  async ({ data }, { getState }) => {
    const state = getState();
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_URL}/mysetting/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { ...data },
    }).then((response) => response.data);
  }
);

export const updateMySettings = createAsyncThunk(
  "user/updateMySettings",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_URL}/mysetting/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        settings: { ...arg },
      },
    }).then((response) => response.data);
  }
);

export const uploadUserPhoto = createAsyncThunk(
  "user/uploadUserPhoto",
  async ({ newPhoto }, { getState }) => {
    const state = getState();
    const formData = new FormData();
    formData.append("photo", newPhoto);
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/profiles/me/`,
      headers: {
        Authorization: state.user.token,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((response) => response.data);
  }
);

export const sendEmailResetPassword = createAsyncThunk(
  "user/sendEmailResetPassword",
  async ({ email, setIsWrongNewEmail, setIsSuccessSubmit }) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/auth/users/reset_password/`,
      data: { email },
    }).then((response) => response.data);
  }
);

export const sendResetPasswordConfirm = createAsyncThunk(
  "user/sendResetPasswordConfirm",
  async ({ data }) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/auth/users/reset_password_confirm/`,
      data: data,
    }).then((response) => response.data);
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userName: "",
    userId: "",
    email: "",
    token: null,
    first_name: "",
    last_name: "",
    signedIn: false,
    is_superuser: false,
    is_teacher: false,
    is_student: false,
    profile: null,
    teachers: null,
    hasLoaded: false,
    isLoading: false,
    mySettings: {
      primaryColor: "green",
      errorColor: "red",
    },
  },
  reducers: {
    getTokenFromLocalStorage: (state) => {
      const token = localStorage.getItem("token");
      if (token !== "null") {
        state.token = JSON.parse(token);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTokenFromServer.fulfilled, (state, action) => {
      state.token = `Token ${action.payload.auth_token}`;
      localStorage.setItem(
        "token",
        JSON.stringify(`Token ${action.payload.auth_token}`)
      );
      action.meta.arg.setIsWrongPassword(false);
      action.meta.arg.setServerError("");
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      const {
        email,
        id,
        username,
        is_student,
        is_teacher,
        first_name,
        last_name,
        is_superuser,
      } = action.payload;
      state.userId = id;
      state.userName = username;
      state.email = email;
      state.signedIn = true;
      state.is_superuser = is_superuser;
      state.is_student = is_student;
      state.is_teacher = is_teacher;
      state.first_name = first_name;
      state.last_name = last_name;
      state.hasLoaded = true;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      if (action.error.code !== "ERR_BAD_REQUEST") {
        toast.error(action.error.message);
      }
      state.signedIn = false;
      state.hasLoaded = true;
    });
    builder.addCase(getTokenFromServer.rejected, (state, action) => {
      if (action.error.code === "ERR_BAD_REQUEST") {
        action.meta.arg.setIsWrongPassword(true);
        action.meta.arg.setServerError("");
        toast.error("Wrong Password");
      } else {
        // state.message = action.error.message;
        action.meta.arg.setIsWrongPassword(false);
        action.meta.arg.setServerError(action.error.message);
        toast.error(action.error.message);
      }
    });
    builder.addCase(getMySettings.fulfilled, (state, action) => {
      state.mySettings = { ...state.mySettings, ...action.payload.settings };
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {
      state.userName = "";
      state.userId = "";
      state.email = "";
      state.token = "";
      state.first_name = "";
      state.last_name = "";
      state.signedIn = false;
      state.is_superuser = false;
      state.is_student = false;
      state.is_teacher = false;
      state.profile = null;
      state.teachers = null;
      localStorage.setItem("token", null);
      state.hasLoaded = false;
      state.isLoading = false;
      state.mySettings = { primaryColor: "green", errorColor: "red" };
      toast.success("Logout Successfully");
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getUserProfile.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserTeacherInfo.fulfilled, (state, action) => {
      state.teachers = action.payload;
    });
    builder.addCase(updateUserPassword.fulfilled, (state, action) => {
      action.meta.arg.setIsWrongNewPassword(false);
      toast.success("Update Password Successfully");
    });
    builder.addCase(updateUserPassword.rejected, (state, action) => {
      action.meta.arg.setIsWrongNewPassword(true);
      toast.error("Failed to Update Password");
    });
    builder.addCase(updateUserEmail.fulfilled, (state, action) => {
      action.meta.arg.setIsWrongNewEmail(false);
      state.email = action.payload.email;
      toast.success("Update Email Successfully");
    });
    builder.addCase(updateUserEmail.rejected, (state, action) => {
      action.meta.arg.setIsWrongNewEmail(true);
    });
    builder.addCase(updateUserPhone.fulfilled, (state, action) => {
      action.meta.arg.setSuccessPhoneChange(false);
      state.profile.phone = action.payload.phone;
      toast.success("Update Phone Number Successfully");
    });
    builder.addCase(updateMySettings.fulfilled, (state, action) => {
      state.mySettings = action.payload.settings;
    });
    builder.addCase(updateUserTeacherInfo.fulfilled, (state, action) => {
      state.teachers = action.payload;
      toast.success("Update Email Successfully");
    });
    builder.addCase(uploadUserPhoto.fulfilled, (state, action) => {
      state.profile.photo = action.payload.photo;
      toast.success("Update Photo Successfully");
    });
    builder.addCase(sendEmailResetPassword.fulfilled, (state, action) => {
      action.meta.arg.setIsWrongNewEmail(false);
      action.meta.arg.setIsSuccessSubmit(true);
      toast.success("Success");
    });
    builder.addCase(sendEmailResetPassword.rejected, (state, action) => {
      action.meta.arg.setIsWrongNewEmail(true);
      action.meta.arg.setIsSuccessSubmit(false);
      toast.error("Unsuccess");
    });
    builder.addCase(sendResetPasswordConfirm.fulfilled, (state, action) => {
      toast.success("Success");
    });
    builder.addCase(sendResetPasswordConfirm.rejected, (state, action) => {
      toast.error("Unsuccess");
    });
  },
});

export const { getTokenFromLocalStorage } = userSlice.actions;

export default userSlice.reducer;
