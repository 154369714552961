import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const axios = require("axios");

export const getBags = createAsyncThunk(
  "bag/getBags",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/bags/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const getBagItems = createAsyncThunk(
  "bag/getBagItems",
  async ({ bagId }, { getState }) => {
    const state = getState();

    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/bags/${bagId}/items/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const consumeBagItems = createAsyncThunk(
  "bag/consumeBagItems",
  async ({ bagId, number, reason }, { getState }) => {
    const state = getState();

    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/bags/${bagId}/consume/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        number: number,
        reason: reason,
      },
    }).then((response) =>
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL}/bags/${bagId}/items/`,
        headers: {
          Authorization: state.user.token,
        },
        params: {},
      }).then((response) => response.data)
    );
  }
);

export const bagSlice = createSlice({
  name: "bag",
  initialState: { items: [], isLoading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBagItems.fulfilled, (state, action) => {
      state.items = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getBagItems.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBags.fulfilled, (state, action) => {
      state.bags = action.payload;
    });
    builder.addCase(consumeBagItems.fulfilled, (state, action) => {
      state.items = action.payload;
      toast.success("Consume bag items Success");
    });
  },
});

export default bagSlice.reducer;
