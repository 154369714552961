import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Button,
  InputAdornment,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getStamps, createStamp } from "../../stamps/stampsSlice";
import AdminStamp from "../../stamps/AdminStamp";
import ImageCrop from "../../../components/ImageCrop/ImageCrop";
import SearchIcon from "@mui/icons-material/Search";

export default function StampAdditionPage() {
  const dispatch = useDispatch();
  const stamps = useSelector((state) => state.stamps.stamps);

  const [filterfield, setFilterfield] = React.useState("");
  const [newStampName, setNewStampName] = React.useState("");
  const [newStampDescription, setNewStampDescription] = React.useState("");
  const [newStampPhoto, setNewStampPhoto] = React.useState("");
  const [openAddStampDialog, setOpenAddStampDialog] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState("");

  React.useEffect(() => {
    dispatch(getStamps());
  }, [dispatch]);

  const onClose = () => {
    setOpenAddStampDialog(false);
    setNewStampName("");
    setNewStampDescription("");
    setNewStampPhoto("");
    setImgSrc("");
  };
  return (
    <Box>
      <TextField
        id="standard-required"
        value={filterfield}
        placeholder="Search ..."
        variant="standard"
        sx={{ display: "block" }}
        onChange={(event) => {
          setFilterfield(event.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Card
        sx={{
          margin: "10px 15px",
          width: "200px",
          height: "319.48px",
          display: "inline-block",
          boxShadow: "10px 5px 5px rgba(0, 0, 0, 0.35)",
          background: "rgb(245, 245, 245)",
          borderRadius: "15px",
          "&:hover": {
            transform: "scale(1.1)",
            boxShadow: "none",
            border: "1px solid Tomato",
            boxShadow: "rgba(0, 0, 0, 0.35) 5px 5px 15px",
            transition: "0.3s",
          },
        }}
        onClick={() => {
          setOpenAddStampDialog(true);
        }}
      >
        <CardContent
          sx={{
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AddCircleOutlineIcon
            fontSize="large"
            color="action"
            sx={{ fontSize: 70 }}
          />
        </CardContent>
      </Card>
      {stamps
        .filter((stamp) => !filterfield || stamp.name.includes(filterfield))
        .map((stamp, index) => (
          <AdminStamp key={Math.random()} stamp={stamp} />
        ))}

      <Dialog
        open={openAddStampDialog}
        onClose={onClose}
        BackdropProps={{
          style: { backgroundColor: "gray", opacity: 0.95 },
        }}
        fullWidth
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageCrop
              handleSubmit={(file) => {
                setNewStampPhoto(file);
                const reader = new FileReader();
                reader.addEventListener("load", () =>
                  setImgSrc(reader.result ? reader.result.toString() : "")
                );
                reader.readAsDataURL(file);
              }}
            />
            <Box
              component="img"
              sx={{
                maxHeight: "264px",
                maxWidth: "264px",
              }}
              src={imgSrc}
            />
            {imgSrc && (
              <>
                <TextField
                  key="name"
                  error={false}
                  label="Name"
                  value={newStampName}
                  helperText={""}
                  onChange={(event) => {
                    setNewStampName(event.target.value);
                  }}
                  sx={{ width: "100%" }}
                />

                <TextField
                  key={"description"}
                  error={false}
                  value={newStampDescription}
                  label={"Description"}
                  helperText={""}
                  multiline={true}
                  maxRows={10}
                  minRows={5}
                  onChange={(event) => {
                    setNewStampDescription(event.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {imgSrc && (
            <Button
              variant="contained"
              disabled={!(newStampName && newStampDescription && newStampPhoto)}
              onClick={() => {
                dispatch(
                  createStamp({
                    name: newStampName,
                    description: newStampDescription,
                    image: newStampPhoto,
                  })
                );
                onClose();
              }}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
