import React, { useState } from "react";
import { List, Divider } from "@mui/material";
import { useSelector } from "react-redux";

import { getCategories } from "../../../../app/entities/categories";
import { getProgrammesDetail } from "../../../../app/entities/programmes";
import MenuHeader from "./MenuHeader";
import CollaspeProgramme from "./CollaspeProgramme";
import { compareByField } from "../../../../tool";
import CategoryListItem from "./CategoryListItem";

export default function CategoryMenu(props) {
  const { handleMenuSelect, selectedProgramme } = props;
  const [open, setOpen] = useState({});
  const categories = useSelector(getCategories);
  const programmes = useSelector(getProgrammesDetail);

  return (
    <React.Fragment>
      <List sx={{ width: "20vw" }}>
        <MenuHeader />
        {categories.sort(compareByField("name")).map((category) => (
          <React.Fragment key={category.id}>
            <CategoryListItem
              category={category}
              selectedProgramme={selectedProgramme}
              open={open}
              setOpen={setOpen}
            />
            <CollaspeProgramme
              open={open[category.id]}
              handleMenuSelect={handleMenuSelect}
              programmes={programmes.filter(
                (programme) => programme.category === category.id
              )}
              selectedProgramme={selectedProgramme}
            />
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );
}
