import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const axios = require("axios");

export const getCourses = createAsyncThunk(
  "courses/getCourses",
  async (arg, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/programmes/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    courses: [],
    allCategories: {},
    allProgrammes: {},
    allLevels: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourses.fulfilled, (state, action) => {
      state.courses = action.payload;
      action.payload.forEach((course) => {
        const { name, level, category_name } = course;
        state.allCategories[category_name] = 0;
        state.allProgrammes[name] = 0;
        state.allLevels[level] = 0;
      });
    });
  },
});

export default coursesSlice.reducer;
