import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteAlertDialog from "../../../../components/common/DeleteAlertDialog";
import { useDispatch } from "react-redux";
import { deleteProgrammebyId } from "../../../../app/entities/programmes";

export default function DeleteProgrammeButton(props) {
  const { programme, setProgramme } = props;
  const dispatch = useDispatch();
  const [deleteMode, setDeleteMode] = useState(false);

  const handleClick = () => {
    setDeleteMode(true);
  };

  const handleClose = (isDelete) => {
    setDeleteMode(false);
    if (isDelete) {
      dispatch(deleteProgrammebyId(programme.id));
      setProgramme(null);
    }
  };

  if (programme !== null)
    return (
      <>
        <Button
          variant="outlined"
          color="error"
          sx={{ marginLeft: "2rem", marginBottom: "1.5rem" }}
          onClick={handleClick}
        >
          Delete Course
        </Button>
        <DeleteAlertDialog
          open={deleteMode}
          handleClose={handleClose}
          targetName={programme.name}
        />
      </>
    );
}
