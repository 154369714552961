import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import DeleteAlertDialog from "../../../components/common/DeleteAlertDialog";

import {
  deleteVenuebyId,
  getVenueList,
  updateVenue,
} from "../../../app/entities/venues";
import VenueEditDialog from "../../../components/EditDialog/VenueEditDialog";
import VenueAddBar from "../../../components/AddBar/VenueAddBar";
import VenueTable from "../../../components/Table/VenueTable";
import { getVenueConditionList } from "../../../app/entities/venueConditions";
import { write_fields } from "../../../config";
import useDeleteAlert from "../../../hooks/useDeleteAlert";
import { useEdit } from "../../../hooks/useEdit";
import useTableToolBar from "../../../hooks/useTableToolBar";
import Slide from "@mui/material/Slide";

export default function VenueTablePage() {
  const dispatch = useDispatch();
  const [showAddBar, handleCreateRecord, searchValue, setSearchValue] =
    useTableToolBar("venue");

  const [
    editMode,
    selectedEditVenue,
    modifiedVenue,
    setModifiedVenue,
    handleEditOpen,
    handleEditClose,
  ] = useEdit(updateVenue, {
    related_fields: write_fields["venues"],
    should_normalize: true,
  });

  const [alertMode, selectedDeleteVenue, handleDeleteOpen, handleDeleteClose] =
    useDeleteAlert(deleteVenuebyId);

  useEffect(() => {
    dispatch(getVenueList());
    dispatch(getVenueConditionList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <VenueTable
        handleCreateRecord={handleCreateRecord}
        handleEditOpen={handleEditOpen}
        handleDeleteOpen={handleDeleteOpen}
      />
      <Slide direction="up" in={showAddBar} mountOnEnter unmountOnExit>
        <VenueAddBar setSearchValue={setSearchValue} />
      </Slide>
      <DeleteAlertDialog
        targetName={selectedDeleteVenue.name}
        open={alertMode}
        handleClose={handleDeleteClose}
      />
      <VenueEditDialog
        preValues={selectedEditVenue}
        values={modifiedVenue}
        setValues={setModifiedVenue}
        targetName={selectedEditVenue.name}
        open={editMode}
        handleClose={handleEditClose}
      />
    </React.Fragment>
  );
}
