import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getStudentList } from "../../../app/entities/students";
import StudentTable from "../../../components/Table/StudentTable";
import StudentStampsComsumptionDialog from "../../bag/StudentStampsComsumptionDialog";

export default function StudentTablePage() {
  const dispatch = useDispatch();
  const [selectedGiftStudent, setSelectedGiftStudent] = useState({});
  const [openGiftDialog, setOpenGiftDialog] = React.useState(false);

  const handleGiftOpen = (student) => {
    setSelectedGiftStudent(student);
    setOpenGiftDialog(true);
  };

  useEffect(() => {
    dispatch(getStudentList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <StudentTable handleGiftOpen={handleGiftOpen} />
      <StudentStampsComsumptionDialog
        student={selectedGiftStudent}
        open={openGiftDialog}
        setOpen={setOpenGiftDialog}
      />
    </React.Fragment>
  );
}
