import React from "react";
import { Typography } from "@mui/material";

export default function Bold(props) {
  return (
    <Typography component={"span"} sx={{ fontWeight: "bold" }}>
      {props.children}
    </Typography>
  );
}
