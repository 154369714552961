import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { postExpectation, postPerformance, postScore } from "../lessonSlice";

export default function EvaluationSelect({
  type,
  selectValues,
  studentComment,
  objectiveItem,
}) {
  const dispatch = useDispatch();
  const isSuperUser = useSelector((state) => state.user.is_superuser);
  const present =
    useSelector((state) => state.lesson.comments).find(
      (comment) => comment.id === studentComment.id
    ).status === "Y";

  const label =
    type === "expectation"
      ? "Expectation"
      : type === "performance"
      ? "Performance"
      : "Score";

  let selectedValue = studentComment.objectiveItems.find(
    (objItem) => objItem.id === objectiveItem.id
  )[type];

  if (type === "expectation") {
    selectedValue = !selectedValue
      ? ""
      : selectedValue === "E"
      ? "Exceeded expectation"
      : selectedValue === "M"
      ? "Meet expectation"
      : "Below expectation";
  } else if (type === "performance") {
    selectedValue = !selectedValue
      ? ""
      : selectedValue === "O"
      ? "Outperform"
      : selectedValue === "S"
      ? "Standard"
      : "Underperform";
  } else {
    selectedValue = !selectedValue ? "" : selectedValue;
  }

  const handleChange = (event) => {
    if (type === "expectation") {
      dispatch(
        postExpectation({
          objectiveItem,
          commentId: studentComment.id,
          expectation: event.target.value.slice(0, 1),
        })
      );
    } else if (type === "performance") {
      dispatch(
        postPerformance({
          objectiveItem,
          commentId: studentComment.id,
          performance: event.target.value.slice(0, 1),
        })
      );
    } else {
      dispatch(
        postScore({
          objectiveItem,
          commentId: studentComment.id,
          score: event.target.value,
        })
      );
    }
  };

  return (
    <FormControl
      sx={{ minWidth: 120, margin: 0 }}
      disabled={!present || isSuperUser}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={selectedValue}
        autoWidth
        onChange={(event) => handleChange(event)}
      >
        {selectValues.map((value) => {
          return (
            <MenuItem key={Math.random()} value={value}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
