import React from "react";
import { useSelector } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import HistoryIcon from "@mui/icons-material/History";

import Table from "../common/Table";
import { getModuleHistoryDisplay } from "../../app/entities/moduleHistory";
import { getModuleHistoryLoadingStatus } from "../../app/entities/moduleHistory";

export default function ModuleHistoryTable(props) {
  const { handleEditOpen } = props;
  const moduleHistory = useSelector(getModuleHistoryDisplay);
  const loading = useSelector(getModuleHistoryLoadingStatus);

  const columns = [
    {
      field: "history_date",
      headerName: "Date",
      flex: 0.8,
    },
    {
      field: "history_type",
      headerName: "Action",
      flex: 0.4,
    },
    {
      field: "username",
      headerName: "Tracked User",
      flex: 0.6,
    },
    {
      field: "is_teacher",
      headerName: "Teacher",
      type: "boolean",
      flex: 0.4,
    },
    {
      field: "is_student",
      headerName: "Student",
      type: "boolean",
      flex: 0.4,
    },
    {
      field: "programme_name",
      headerName: "Module Programme",
      flex: 1.2,
    },
    {
      field: "name",
      headerName: "Module Name",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.3,
      getActions: ({ row }) => {
        const handleEdit = () => {
          handleEditOpen(row);
        };
        return [
          <GridActionsCellItem
            icon={<HistoryIcon />}
            label="history"
            onClick={handleEdit}
          />,
        ];
      },
    },
  ];

  return (
    <Table
      initialState={{
        sorting: {
          sortModel: [{ field: "history_date", sort: "desc" }],
        },
      }}
      rows={moduleHistory}
      columns={columns}
      loading={loading}
      getRowId={(row) => row.history_id}
      tableName="module_history"
    />
  );
}
