import React, { forwardRef } from "react";
import Joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import {
  getVenueConditions,
  createVenueCondition,
  updateVenueCondition,
  deleteVenueConditionbyId,
} from "../../../../app/entities/venueConditions";
import BucketDialog from "../../../../components/EditDialog/BucketDialog";

function VenueConditionAddDialog(props, ref) {
  const { openDialog, onClose } = props;
  const dispatch = useDispatch();

  const venueConditions = useSelector(getVenueConditions);

  return (
    <BucketDialog
      dialogTitle={"Venue Conditions"}
      openDialog={openDialog}
      setOpenDialog={onClose}
      textFieldLabel={"Venue Conditions"}
      items={venueConditions}
      handleAdd={(inputValue) => {
        dispatch(createVenueCondition({ description: inputValue }));
      }}
      handleModify={(preVenueCondition, venueCondition) => {
        dispatch(updateVenueCondition(preVenueCondition, venueCondition));
      }}
      handleDelete={(venueCondition) => {
        dispatch(deleteVenueConditionbyId(venueCondition.id));
      }}
      displayAttribute="description"
    />
  );
}

export default forwardRef(VenueConditionAddDialog);
