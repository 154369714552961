import * as React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Backdrop, CircularProgress } from "@mui/material";
import { paths } from "../../config";
import { getLessons } from "./lessonsSlice";
import LessonsTable from "./LessonsTable";
import LessonsFilter from "./LessonsFilter";
import { getNextWeek } from "../../tool";

const columns = [
  { id: "category", label: "Category" },
  { id: "programme", label: "Programme" },
  { id: "level", label: "Level" },
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
  { id: "venue", label: "Venue" },
  { id: "detail", label: "Detail" },
];

export default function LessonsPage() {
  const dispatch = useDispatch();
  const lessons = useSelector((state) => state.lessons.lessons);
  const categories = Object.keys(
    useSelector((state) => state.courses.allCategories)
  );
  const programmes = Object.keys(
    useSelector((state) => state.courses.allProgrammes)
  ).filter((programme) => programme !== "null");
  const levels = Object.keys(
    useSelector((state) => state.courses.allLevels)
  ).filter((level) => level !== "null");
  const students = useSelector((state) => state.courses.courses)
    .map((course) => course.students)
    .flat()
    .filter(
      (value, index, self) => index === self.findIndex((s) => s.id === value.id)
    )
    .sort((student1, student2) => {
      return `${student1.first_name} ${student1.last_name}`.localeCompare(
        `${student2.first_name} ${student2.last_name}`
      );
    });
  const isLoading = useSelector((state) => state.lessons.loading);

  const currentDate = new Date();
  const [filter, setFilter] = React.useState({
    startDate: new Date(),
    endDate: getNextWeek(),
    categories: [],
    programmes: [],
    levels: [],
    students: [],
  });

  React.useEffect(() => {
    filter.startDate &&
      filter.endDate &&
      dispatch(
        getLessons({
          startDatetime: filter.startDate,
          endDateTime: filter.endDate,
        })
      );
  }, [dispatch, filter.startDate, filter.endDate]);

  React.useEffect(() => {
    filter.categories.length === 0 && setFilter({ ...filter, categories });
    filter.programmes.length === 0 && setFilter({ ...filter, programmes });
    filter.levels.length === 0 && setFilter({ ...filter, levels });
  }, [categories, programmes, levels]);

  const rows = [];
  lessons
    .filter((lesson) =>
      filter.categories.includes(lesson.programme.category_name)
    )
    .filter((lesson) => filter.programmes.includes(lesson.programme.name))
    .filter((lesson) =>
      filter.levels.includes(lesson.programme.level.toString())
    )
    .filter(
      (lesson) =>
        filter.students.length === 0 ||
        lesson.students.find((lessonStudent) =>
          filter.students
            .map((filterStudent) => filterStudent.id)
            .includes(lessonStudent.id)
        )
    )
    .forEach((lesson) => {
      const startDate = new Date(lesson.start_datetime);
      const endDate = new Date(lesson.end_datetime);
      const date = startDate.toLocaleDateString();
      const startTime = startDate.toLocaleString("zh-HK", {
        hour12: false,
        timeStyle: "short",
      });
      const endTime = endDate.toLocaleString("zh-HK", {
        hour12: false,
        timeStyle: "short",
      });

      rows.push({
        ...lesson,
        category: lesson.programme.category_name,
        programme: lesson.programme.name,
        level: lesson.programme.level,
        date,
        time: `${startTime} - ${endTime}`,
        venue: lesson.venue ? lesson.venue.name : "TBC",
        detail: (
          <Button
            component={Link}
            to={`/${paths["lessonDetail"](lesson.programme.id, lesson.id)}`}
          >
            View Detail
          </Button>
        ),
      });
    });

  return (
    <Box sx={{ padding: 2 }}>
      {LessonsFilter({
        filter,
        setFilter,
        categories,
        programmes,
        levels,
        students,
      })}

      {!isLoading ? (
        <LessonsTable columns={columns} rows={rows} />
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  );
}
