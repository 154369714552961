import { createSlice, createSelector } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import {
  combineState,
  joinState,
  normalize,
  seperateState,
  annontate,
  list_of_obj_to_str,
  is_loading,
} from "../normalize";
import { pipe } from "lodash/fp";

const endpoint = endpoints["venues"];

const slice = createSlice({
  name: "venues",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    venueListRequest: (venues, action) => {
      venues.loading = true;
    },
    venueListReceived: (venues, { payload }) => {
      payload = normalize(payload);
      seperateState(venues, payload);
      venues.loading = false;
    },
    venueRequest: (venues, action) => {
      venues.loading = true;
    },
    venueReceived: (venues, { payload }) => {
      venues.allIds.push(payload.id);
      venues.byId[payload.id] = payload;
      venues.loading = false;
    },
    venueCreate: (venues, { payload }) => {
      venues.allIds.push(payload.id);
      venues.byId[payload.id] = payload;
    },
    venueCreateRollback: (venues, { payload }) => {
      venues.allIds = venues.filter((venue) => venue !== payload.id);
    },
    venueUpdate: (venues, { payload }) => {
      venues.byId[payload.id] = payload;
    },
    venueUpdateRollback: (venues, { payload }) => {
      venues.byId[payload.id] = payload;
    },
    venueDelete: (venues, { payload }) => {
      venues.allIds = venues.allIds.filter((id) => id !== payload.id);
    },
    venueDeleteRollback: (venues, { payload }) => {
      venues.allIds = [...venues.allIds, payload.id];
    },
  },
});

const {
  venueListRequest,
  venueListReceived,
  venueRequest,
  venueReceived,
  venueCreate,
  venueUpdate,
  venueUpdateRollback,
  venueDelete,
  venueDeleteRollback,
} = slice.actions;

// action
export const getVenueList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: venueListRequest.type,
    onSuccess: venueListReceived.type,
  });

export const retrieveVenue = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "get",
    onStart: venueRequest.type,
    onSuccess: venueReceived.type,
  });

export const createVenue = (venue) =>
  apiCallBegan({
    endpoint,
    method: "post",
    data: venue,
    onSuccess: venueCreate.type,
    createdRecordName: `venue "${venue.name}"`,
  });

export const updateVenue = (preVenue, venue) =>
  apiCallBegan({
    endpoint: endpoint + venue.id + "/",
    method: "patch",
    data: venue,
    onStart: venueUpdate.type,
    onStartPayload: venue,
    onError: venueUpdateRollback.type,
    onErrorPayload: preVenue,
  });

export const deleteVenuebyId = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "delete",
    onStart: venueDelete.type,
    onStartPayload: { id },
    onError: venueDeleteRollback.type,
    onErrorPayload: { id },
  });

// selector
export const getVenues = (state) => combineState(state.entities.venues);

export const getVenuesDetail = createSelector(
  (state) => state.entities.venues,
  (state) => state.entities.venueConditions,
  (venues, conditions) => {
    if (is_loading(venues, conditions)) return [];
    const extend_detail = pipe(
      combineState,
      joinState("conditions", conditions.byId),
      annontate(
        "display_conditions",
        list_of_obj_to_str("conditions", "description")
      )
    );
    return extend_detail(venues);
  }
);

export const getVenueLoadingStatus = (state) => state.entities.venues.loading;

export const getVenuebyId = (id) => (state) => state.entities.venues.byId[id];

export default slice.reducer;
