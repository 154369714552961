import { createSelector, createSlice } from "@reduxjs/toolkit";
import { pipe } from "lodash/fp";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import {
  seperateState,
  combineState,
  normalize,
  is_loading,
  annontate,
  extract_field_byMapping,
  joinState,
  count,
  list_of_obj_to_str,
  multiple_sort,
} from "../normalize";

const endpoint = endpoints["programmes"];

const slice = createSlice({
  name: "programmes",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    programmeListRequest: (programmes, action) => {
      programmes.loading = true;
    },
    programmeListReceived: (programmes, { payload }) => {
      payload = normalize(payload);
      seperateState(programmes, payload);
      programmes.loading = false;
    },
    programmeCreate: (programmes, { payload }) => {
      programmes.allIds.push(payload.id);
      programmes.byId[payload.id] = payload;
    },
    programmeCreateRollback: (programmes, { payload }) => {
      programmes.allIds = programmes.filter(
        (programme) => programme !== payload.id
      );
    },
    programmeUpdate: (programmes, { payload }) => {
      programmes.byId[payload.id] = {
        ...programmes.byId[payload.id],
        ...payload,
      };
    },
    programmeUpdateRollback: (programmes, { payload }) => {
      programmes.byId[payload.id] = payload;
    },
    programmeDelete: (programmes, { payload }) => {
      programmes.allIds = programmes.allIds.filter((id) => id !== payload.id);
    },
    programmeDeleteRollback: (programmes, { payload }) => {
      programmes.allIds = [...programmes.allIds, payload.id];
    },
  },
});

const {
  programmeListRequest,
  programmeListReceived,
  programmeCreate,
  programmeCreateRollback,
  programmeUpdate,
  programmeUpdateRollback,
  programmeDelete,
  programmeDeleteRollback,
} = slice.actions;

// action
export const getProgrammeList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: programmeListRequest.type,
    onSuccess: programmeListReceived.type,
  });

export const createProgramme = (programme) =>
  apiCallBegan({
    endpoint,
    method: "post",
    data: programme,
    onSuccess: programmeCreate.type,
    createdRecordName:
      `course "${programme.name}` +
      (programme.level ? ` - level ${programme.level}"` : '"'),
  });

export const updateProgramme = (preProgramme, programme) =>
  apiCallBegan({
    endpoint: endpoint + programme.id + "/",
    method: "patch",
    data: programme,
    onStart: programmeUpdate.type,
    onStartPayload: programme,
    onError: programmeUpdateRollback.type,
    onErrorPayload: preProgramme,
  });

export const deleteProgrammebyId = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "delete",
    onStart: programmeDelete.type,
    onStartPayload: { id },
    onError: programmeDeleteRollback.type,
    onErrorPayload: { id },
  });

// selector
export const getProgrammes = (state) => combineState(state.entities.programmes);

export const getProgrammesDetail = createSelector(
  (state) => state.entities.programmes,
  (state) => state.entities.categories,
  (state) => state.entities.users,
  (state) => state.entities.venueConditions,
  (programmes, categories, users, venueConditions) => {
    if (is_loading(programmes, categories, users, venueConditions)) return [];
    const extend_detail = pipe(
      combineState,
      joinState("teachers", users.byId),
      joinState("students", users.byId),
      joinState("venue_conditions", venueConditions.byId),
      annontate(
        "category_name",
        extract_field_byMapping(
          "category",
          "name",
          categories.byId,
          "No Category"
        )
      ),
      annontate("student_count", count("students")),
      annontate("teacher_count", count("teachers")),
      annontate(
        "display_conditions",
        list_of_obj_to_str("venue_conditions", "description")
      ),
      multiple_sort(["category_name", "name", "level"])
    );
    return extend_detail(programmes);
  }
);

export const getProgrammeLoadingStatus = (state) =>
  state.entities.programmes.loading;

export const getProgrammebyId = (id) => (state) =>
  state.entities.programmes.byId[id];

export default slice.reducer;
