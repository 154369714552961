import React, { forwardRef } from "react";
import Addbar from "../common/Addbar";
import Joi from "joi-browser";
import { createVenue } from "../../app/entities/venues";
import { useDispatch } from "react-redux";

function VenueAddBar(props, ref) {
  const { setSearchValue } = props;
  const dispatch = useDispatch();

  const columns = [
    {
      field: "name",
      headerName: "Venue Name",
      flex: 1,
      required: true,
      focus: true,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 3,
    },
  ];

  const schema = {
    name: Joi.string().required(),
  };

  return (
    <Addbar
      ref={ref}
      columns={columns}
      schema={schema}
      onSubmit={(value) => {
        dispatch(createVenue(value));
      }}
    />
  );
}

export default forwardRef(VenueAddBar);
