import React, { useState, useMemo, useEffect } from "react";
import Joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CreateDialog from "../common/CreateDialog";
import BucketDialog from "../EditDialog/BucketDialog";
import { createProgramme } from "../../app/entities/programmes";
import { normalize_object } from "../../app/normalize";
import { getCategories, getCategoryList } from "../../app/entities/categories";
import { getUserList } from "../../app/entities/users";
import { getVenueConditionList } from "../../app/entities/venueConditions";
import { getUsers } from "../../app/entities/users";
import {
  getVenueConditions,
  createVenueCondition,
  updateVenueCondition,
  deleteVenueConditionbyId,
} from "../../app/entities/venueConditions";

export default function ProgrammeCreateDialog(props) {
  const { open, setOpen } = props;
  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  const [openVenueConditionsDialog, setOpenVenueConditionsDialog] =
    React.useState(false);

  const handleClose = (isCreate) => {
    if (isCreate) {
      dispatch(createProgramme(normalize_object(values)));
    }
    setOpen(false);
    setValues({});
  };

  const categories = useSelector(getCategories);
  const users = useSelector(getUsers);
  const venueConditions = useSelector(getVenueConditions);

  const students = useMemo(
    () => users.filter((user) => user.is_student),
    [users]
  );
  const teachers = useMemo(
    () => users.filter((user) => user.is_teacher),
    [users]
  );

  const columns = [
    {
      render_type: "select",
      field: "category",
      headerName: "Category",
      choices: categories,
      required: true,
    },
    {
      field: "name",
      headerName: "Course Name",
      required: true,
    },
    {
      field: "level",
      headerName: "Course Level",
    },
    {
      field: "description",
      headerName: "Description",
    },
    {
      render_type: "autocomplete",
      field: "teachers",
      headerName: "Teachers",
      choices: teachers,
      multiple: true,
      displayLabel: (obj) => obj.first_name + " " + obj.last_name,
    },
    {
      render_type: "autocomplete",
      field: "students",
      headerName: "Students",
      choices: students,
      multiple: true,
      displayLabel: (obj) => obj.first_name + " " + obj.last_name,
    },
    {
      render_type: "autocomplete",
      field: "venue_conditions",
      headerName: "Venue Conditions",
      choices: venueConditions,
      multiple: true,
      displayLabel: (obj) => obj.description,
      extraEndAdornment: (
        <IconButton
          color="primary"
          onClick={() => {
            setOpenVenueConditionsDialog(true);
          }}
        >
          <AddIcon />
        </IconButton>
      ),
    },
  ];

  const schema = {
    category: Joi.number().required(),
    name: Joi.string().required(),
    level: Joi.string().empty(""),
    description: Joi.string().empty(""),
  };

  useEffect(() => {
    dispatch(getCategoryList);
    dispatch(getUserList);
    dispatch(getVenueConditionList);
  }, []);

  return (
    <>
      <CreateDialog
        targetName="Course"
        columns={columns}
        schema={schema}
        values={values}
        setValues={setValues}
        open={open}
        handleClose={handleClose}
      />
      <BucketDialog
        dialogTitle={"Venue Conditions"}
        openDialog={openVenueConditionsDialog}
        setOpenDialog={setOpenVenueConditionsDialog}
        textFieldLabel={"Venue Conditions"}
        items={venueConditions}
        handleAdd={(inputValue) => {
          dispatch(createVenueCondition({ description: inputValue }));
        }}
        handleModify={(preVenueCondition, venueCondition) => {
          dispatch(updateVenueCondition(preVenueCondition, venueCondition));
        }}
        handleDelete={(venueCondition) => {
          dispatch(deleteVenueConditionbyId(venueCondition.id));
        }}
        displayAttribute="description"
      />
    </>
  );
}
