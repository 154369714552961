import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import {
  getSchoolList,
  createSchool,
  modifySchool,
  deleteSchool,
} from "../schools/schoolsSlice";
import StudentProfileEditingDialog from "./StudentProfileEditingDialog";
import SchoolsBucketDialog from "../schools/SchoolsBucketDialog";

export default function StudentProfileEditing({ studentUserId }) {
  const dispatch = useDispatch();
  const [openStudentProfileEditingDialog, setOpenStudentProfileEditingDialog] =
    React.useState(false);
  const [openSchoolEditingDialog, setOpenSchoolEditingDialog] =
    React.useState(false);
  const [modifiedSchoolsList, setModifiedSchoolsList] = React.useState([]);

  const schoolsList = useSelector((state) => state.schools.schoolsList);
  const studentSchool_items = useSelector((state) => state.student.schools);

  React.useEffect(() => {
    if (studentSchool_items) {
      setModifiedSchoolsList(
        studentSchool_items.map((school_item) => {
          return {
            id: school_item.id,
            school: school_item.school,
            start_date: school_item.start_date,
            selectEndTimeOption: school_item.end_date ? "Graduated" : "Present",
            end_date: school_item.end_date,
          };
        })
      );
    }
  }, [studentSchool_items]);

  React.useEffect(() => {
    dispatch(getSchoolList());
  }, [studentUserId, dispatch]);

  return (
    <>
      {schoolsList && studentSchool_items && (
        <>
          <Button
            variant="contained"
            onClick={() => {
              setOpenStudentProfileEditingDialog(true);
            }}
            sx={{ marginLeft: "10px", marginTop: "20px", marginBottom: "10px" }}
          >
            Edit
          </Button>
          {StudentProfileEditingDialog(
            openStudentProfileEditingDialog,
            setOpenStudentProfileEditingDialog,
            setOpenSchoolEditingDialog,
            modifiedSchoolsList,
            dispatch,
            studentUserId,
            schoolsList,
            setModifiedSchoolsList
          )}

          <SchoolsBucketDialog
            dialogTitle={"School"}
            openDialog={openSchoolEditingDialog}
            setOpenDialog={setOpenSchoolEditingDialog}
            textFieldLabel={"School"}
            items={schoolsList}
            handleAdd={(name, type) => {
              dispatch(createSchool({ name, type }));
            }}
            handleModify={(id, name, type) => {
              dispatch(modifySchool({ id, name, type }));
            }}
            handleDelete={(id) => {
              dispatch(deleteSchool({ id }));
            }}
          />
        </>
      )}
    </>
  );
}
