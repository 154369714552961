import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import BasicSelect from "../../components/common/BasicSelect";
import { SELECT_NONE } from "../../config";

export default function Courses() {
  const navigate = useNavigate();

  const courses = useSelector((state) => state.courses.courses);
  const allCategories = useSelector((state) => state.courses.allCategories);
  const allProgrammes = useSelector((state) => state.courses.allProgrammes);
  const allLevels = useSelector((state) => state.courses.allLevels);

  const [selectedCategory, setSelectedCategory] = React.useState(SELECT_NONE);
  const [selectedProgramme, setSelectedProgramme] = React.useState(SELECT_NONE);
  const [selectedLevel, setSelectedLevel] = React.useState(SELECT_NONE);
  const [sortCriteria, setSortCriteria] = React.useState("Category");

  function CourseFilters() {
    return (
      <Box
        sx={{
          marginBottom: 2,
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <BasicSelect
          label="Category"
          choices={Object.keys(allCategories).map((category) => ({
            id: category,
            name: category,
          }))}
          selectedValue={selectedCategory}
          handleChange={(event) => {
            setSelectedCategory(event.target.value);
          }}
        />
        <BasicSelect
          label="Programme"
          choices={Object.keys(allProgrammes).map((programme) => ({
            id: programme,
            name: programme,
          }))}
          selectedValue={selectedProgramme}
          handleChange={(event) => {
            setSelectedProgramme(event.target.value);
          }}
        />
        <BasicSelect
          label="Level"
          choices={Object.keys(allLevels).map((level) => ({
            id: level,
            name: level === "null" ? "N/A" : level,
          }))}
          selectedValue={selectedLevel}
          handleChange={(event) => {
            setSelectedLevel(event.target.value);
          }}
        />
        <BasicSelect
          label="Sort By"
          choices={["Category", "Programme", "Level"].map((sortCriteria) => ({
            id: sortCriteria,
            name: sortCriteria,
          }))}
          selectedValue={sortCriteria}
          handleChange={(event) => {
            setSortCriteria(event.target.value);
          }}
        />
      </Box>
    );
  }

  function CoursesTable() {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ skewY: "45deg", color: "red" }}>
              <TableCell>Category</TableCell>
              <TableCell>Programme</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Detail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses
              .filter(
                (course) =>
                  selectedCategory === SELECT_NONE ||
                  course.category_name === selectedCategory
              )
              .filter(
                (course) =>
                  selectedProgramme === SELECT_NONE ||
                  course.name === selectedProgramme
              )
              .filter(
                (course) =>
                  selectedLevel === SELECT_NONE ||
                  (course.level === null && selectedLevel === "null") ||
                  (course.level && course.level.toString() === selectedLevel)
              )
              .sort((course1, course2) => {
                return sortCriteria === "Programme"
                  ? !course1.name ||
                      !course2.name ||
                      course1.name.localeCompare(course2.name)
                  : sortCriteria === "Level"
                  ? !course1.level ||
                    !course2.level ||
                    course1.level
                      .toString()
                      .localeCompare(course2.level.toString())
                  : !course1.category_name ||
                    !course2.category_name ||
                    course1.category_name.localeCompare(course2.category_name);
              })
              .map((course) => (
                <React.Fragment key={Math.random()}>
                  <TableRow>
                    <TableCell>{course.category_name}</TableCell>
                    <TableCell>{course.name}</TableCell>
                    <TableCell>{course.level}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          const params = { tab: 0 };
                          navigate(
                            `/course/${course.id}/?${createSearchParams(
                              params
                            )}`
                          );
                        }}
                      >
                        View Detail
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <CourseFilters />
      <CoursesTable />
    </Box>
  );
}
