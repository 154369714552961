import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import axios from "axios";
import { combineState, seperateState } from "../normalize";

const endpoint = endpoints["profiles"];

const slice = createSlice({
  name: "profiles",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    profileListRequest: (profiles, action) => {
      profiles.loading = true;
    },
    profileListReceived: (profiles, { payload }) => {
      seperateState(profiles, payload);
      profiles.loading = false;
    },
    profileRequest: (profiles, action) => {
      profiles.loading = true;
    },
    profileReceived: (profiles, { payload }) => {
      profiles.allIds.push(payload.user_id);
      profiles.byId[payload.user_id] = payload;
      profiles.loading = false;
    },
    profileCreate: (profiles, { payload }) => {
      profiles.allIds.push(payload.id);
      profiles.byId[payload.id] = payload;
    },
    profileCreateRollback: (profiles, { payload }) => {
      profiles.allIds = profiles.filter((profile) => profile !== payload.id);
    },
    profileUpdate: (profiles, { payload }) => {
      profiles.byId[payload.id] = { ...profiles.byId[payload.id], ...payload };
    },
    profileUpdateRollback: (profiles, { payload }) => {
      profiles.byId[payload.id] = payload;
    },
    profileDelete: (profiles, { payload }) => {
      profiles.allIds = profiles.allIds.filter((id) => id !== payload.id);
    },
    profileDeleteRollback: (profiles, { payload }) => {
      profiles.allIds = [...profiles.allIds, payload.id];
    },
    photoReceived: (profiles, { payload }) => {
      profiles.byId[payload.user_id] = payload;
    },
  },
});

const {
  profileListRequest,
  profileListReceived,
  profileRequest,
  profileReceived,
  profileCreate,
  profileUpdate,
  profileUpdateRollback,
  profileDelete,
  profileDeleteRollback,
  photoReceived,
} = slice.actions;

// action
export const getProfileList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: profileListRequest.type,
    onSuccess: profileListReceived.type,
  });

export const retrieveProfile = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "get",
    onStart: profileRequest.type,
    onSuccess: profileReceived.type,
  });

export const createProfile = (profile) =>
  apiCallBegan({
    endpoint,
    method: "post",
    data: profile,
    onSuccess: profileCreate.type,
  });

export const updateProfile = (preProfile, profile) =>
  apiCallBegan({
    endpoint: endpoint + profile.id + "/",
    method: "patch",
    data: profile,
    onStart: profileUpdate.type,
    onStartPayload: profile,
    onError: profileUpdateRollback.type,
    onErrorPayload: preProfile,
  });

export const uploadPhotoById = (id, newPhoto) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "patch",
    data: newPhoto,
    onSuccess: photoReceived.type,
  });

export const deleteProfilebyId = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "delete",
    onStart: profileDelete.type,
    onStartPayload: { id },
    onError: profileDeleteRollback.type,
    onErrorPayload: { id },
  });

// export const uploadPhotoById = (id, photo) => async () => {
//   response = await axios.request({
//     url: endpoint + id + "/",
//     method: "patch",
//     data: photo,
//     headers: {
//       Authorization: state.user.token,
//       "Content-Type": "multipart/form-data",
//     },
//   });
// };

// selector
export const getProfiles = (state) => combineState(state.entities.profiles);

export const getProfileLoadingStatus = (state) =>
  state.entities.profiles.loading;

export const getProfilebyId = (id) => (state) =>
  state.entities.profiles.byId[id];

export default slice.reducer;
