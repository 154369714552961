import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getObjectiveHistoryList } from "../../../app/entities/objectiveHistory";
import ObjectiveHistoryTable from "../../../components/Table/ObjectiveHistoryTable";
import ObjectiveHistoryInfoDialog from "../../../components/InfoDialog/ObjectiveHistoryInfoDialog";

export default function ObjectiveHistoryPage() {
  const [viewDetailMode, setViewDetailMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getObjectiveHistoryList());
  }, [dispatch]);

  const handleEditOpen = (objectiveHistory) => {
    setViewDetailMode(true);
    setSelectedUser(objectiveHistory);
  };

  const handleEditClose = () => {
    setViewDetailMode(false);
  };

  return (
    <React.Fragment>
      <ObjectiveHistoryTable handleEditOpen={handleEditOpen} />
      <ObjectiveHistoryInfoDialog
        values={selectedUser}
        open={viewDetailMode}
        handleClose={handleEditClose}
      />
    </React.Fragment>
  );
}
