import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Fab,
  Checkbox,
  FormControlLabel,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  Badge
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CommentTextField from "./components/CommentTextField";
import EvaluationSelect from "./components/EvaluationSelect";
import GoalSelect from "./components/GoalSelect";
import LessonGoalTag from "./components/LessonGoalTag";
import StampDialog from "./StampDialog";
import { togglePresent } from "./lessonSlice";
import BagStamp from "../bag/BagStamp";
import {
  expectationValues,
  performanceValues,
  scoreValues,
} from "../../config";

export default function Comment({ studentComment, selectedObjectives }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const student = studentComment.student;
  const isStudent = useSelector((state) => state.user.is_student);
  const isSuperUser = useSelector((state) => state.user.is_superuser);
  const present =
    useSelector((state) => state.lesson.comments).find(
      (comment) => comment.id === studentComment.id
    ).status === "Y";

  const formattedObjectiveItems = {};
  const allModules = useSelector((state) => state.course.modules);
  allModules.forEach((module) => {
    if (selectedObjectives[module.id]) {
      formattedObjectiveItems[module.id] = {
        moduleName: module.name,
        objectiveItems: [],
      };
    }
  });
  studentComment.objectiveItems.forEach((objectiveItem) => {
    formattedObjectiveItems[objectiveItem.objective.module].objectiveItems.push(
      objectiveItem
    );
  });

  const studentStamps = studentComment.bag_items.map(
    (bagItem) => bagItem.stamp
  );
  const stamps = useSelector((state) => state.stamps.stamps);
  const [selectedStamps, setSelectedStamps] = React.useState([
    ...studentStamps,
  ]);

  const expectation = {
    E: "Exceed expectation",
    M: "Meet expectation",
    B: "Below expectation",
  };
  const performance = {
    O: "Outperform",
    S: "Standard",
    U: "Underperform",
  };

  if (isStudent && present) {
    return (
      <Paper
        elevation={3}
        sx={{ margin: 2, padding: 2, minWidth: "500px", display: "flex" }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "25%" }}>Module</TableCell>
                <TableCell sx={{ width: "25%" }}>Objective</TableCell>
                <TableCell align="center" sx={{ width: "15%" }}>
                  Expectation
                </TableCell>
                <TableCell align="center" sx={{ width: "15%" }}>
                  Performance
                </TableCell>
                <TableCell align="center" sx={{ width: "10%" }}>
                  Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(formattedObjectiveItems).map((moduleId) => {
                const module = formattedObjectiveItems[moduleId];
                return module ? (
                  <React.Fragment key={Math.random()}>
                    <TableRow>
                      <TableCell
                        rowSpan={
                          module.objectiveItems.length === 0
                            ? 1
                            : module.objectiveItems.length
                        }
                      >
                        {module.moduleName}
                      </TableCell>

                      {module.objectiveItems.length > 0 ? (
                        <>
                          <TableCell>
                            {module.objectiveItems[0].objective.name}
                          </TableCell>
                          <TableCell align="center">
                            {expectation[module.objectiveItems[0].expectation]}
                          </TableCell>
                          <TableCell align="center">
                            {performance[module.objectiveItems[0].performance]}
                          </TableCell>
                          <TableCell align="center">
                            {module.objectiveItems[0].score}
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                    {module.objectiveItems.slice(1).length > 0
                      ? module.objectiveItems.slice(1).map((objectiveItem) => {
                          return (
                            <TableRow key={Math.random()}>
                              <TableCell>
                                {objectiveItem.objective.name}
                              </TableCell>
                              <TableCell align="center">
                                {expectation[objectiveItem.expectation]}
                              </TableCell>
                              <TableCell align="center">
                                {performance[objectiveItem.performance]}
                              </TableCell>
                              <TableCell align="center">
                                {objectiveItem.score}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </React.Fragment>
                ) : null;
              })}
              <TableRow>
                <TableCell>Goal</TableCell>
                <TableCell colSpan={4}>
                  <LessonGoalTag studentComment={studentComment} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Comment</TableCell>
                <TableCell colSpan={4}>
                  {studentComment.remark || "No Comment"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Stamp</TableCell>
                <TableCell colSpan={4}>
                  <Box>
                    {studentComment.bag_items.map((stamp) => {
                      return (
                        <BagStamp
                          key={Math.random()}
                          stamp={stamp.stamp}
                          number={null}
                        />
                      );
                    })}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  } else if (isStudent && !present) {
    return (
      <Box
        elevation={3}
        sx={{ margin: 2, padding: 1, minWidth: "500px", display: "flex" }}
      >
        <Typography>You are absent from this lesson</Typography>
      </Box>
    );
  } else {
    //isTeacher || isSuperUser
    return (
      <Paper
        elevation={3}
        sx={{ margin: 2, padding: 2, minWidth: "500px", display: "flex" }}
      >
        <TableContainer>
          {StampDialog(
            open,
            setOpen,
            selectedStamps,
            stamps,
            setSelectedStamps,
            studentStamps,
            student,
            studentComment
          )}
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ width: "25%" }}
                >{`${student.first_name} ${student.last_name}`}</TableCell>
                <TableCell style={{ width: "25%" }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    disabled={isSuperUser}
                    label="present"
                    checked={present}
                    onChange={(event) =>
                      dispatch(
                        togglePresent({
                          present: event.target.checked ? "Y" : "N",
                          commentId: studentComment.id,
                        })
                      )
                    }
                  />
                </TableCell>
                <TableCell align="center" style={{ width: "15%" }}>
                  Expectation
                </TableCell>
                <TableCell align="center" style={{ width: "15%" }}>
                  Performance
                </TableCell>
                <TableCell align="center" style={{ width: "10%" }}>
                  Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(formattedObjectiveItems).map((moduleId) => {
                const module = formattedObjectiveItems[moduleId];
                return module ? (
                  <React.Fragment key={Math.random()}>
                    <TableRow>
                      <TableCell
                        rowSpan={
                          module.objectiveItems.length === 0
                            ? 1
                            : module.objectiveItems.length
                        }
                      >
                        {module.moduleName}
                      </TableCell>

                      {module.objectiveItems.length > 0 ? (
                        <>
                          <TableCell>
                            {module.objectiveItems[0].objective.name}
                          </TableCell>
                          <TableCell align="center">
                            <EvaluationSelect
                              type="expectation"
                              selectValues={expectationValues}
                              studentComment={studentComment}
                              objectiveItem={module.objectiveItems[0]}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <EvaluationSelect
                              type="performance"
                              selectValues={performanceValues}
                              studentComment={studentComment}
                              objectiveItem={module.objectiveItems[0]}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <EvaluationSelect
                              type="score"
                              selectValues={scoreValues}
                              studentComment={studentComment}
                              objectiveItem={module.objectiveItems[0]}
                            />
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                    {module.objectiveItems.slice(1).length > 0
                      ? module.objectiveItems.slice(1).map((objectiveItem) => {
                          return (
                            <TableRow key={Math.random()}>
                              <TableCell>
                                {objectiveItem.objective.name}
                              </TableCell>
                              <TableCell align="center">
                                <EvaluationSelect
                                  type="expectation"
                                  selectValues={expectationValues}
                                  studentComment={studentComment}
                                  objectiveItem={objectiveItem}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <EvaluationSelect
                                  type="performance"
                                  selectValues={performanceValues}
                                  studentComment={studentComment}
                                  objectiveItem={objectiveItem}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <EvaluationSelect
                                  type="score"
                                  selectValues={scoreValues}
                                  studentComment={studentComment}
                                  objectiveItem={objectiveItem}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </React.Fragment>
                ) : null;
              })}

              <TableRow>
                <TableCell>Comment</TableCell>
                <TableCell colSpan={4}>
                  <CommentTextField studentComment={studentComment} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Goal</TableCell>
                <TableCell>
                  <GoalSelect studentComment={studentComment} />
                </TableCell>
                <TableCell colSpan={2}>
                  <LessonGoalTag studentComment={studentComment} />
                </TableCell>
                <TableCell>
                  <Badge badgeContent={selectedStamps.length} color="primary">
                    <Fab
                      variant="extended"
                      color="primary"
                      disabled={!present}
                      onClick={() => setOpen(true)}
                    >
                    { ! isSuperUser &&
                      <AddCircleOutlineIcon sx={{ mr: 1 }} />
                    }
                      STAMP
                    </Fab>
                  </Badge>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}
