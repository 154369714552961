import * as React from "react";
import {
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMySettings,
  getTokenFromServer,
  getUserInfo,
} from "../user/userSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { paths } from "../../config";
import usePassword from "../../hooks/usePassword";

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signedIn = useSelector((state) => state.user.signedIn);
  const isStudent = useSelector((state) => state.user.is_student);
  const [isWrongPassword, setIsWrongPassword] = React.useState(false);
  const [serverError, setServerError] = React.useState("");
  const [showPasswordType, passwordAdornment] = usePassword();

  React.useEffect(() => {
    if (signedIn && !isStudent) {
      navigate(`/${paths["timeTable"]}`, { replace: true });
    }
    if (signedIn && isStudent) {
      navigate(`/${paths["lessons"]}`, { replace: true });
    }
  }, [signedIn, navigate, isStudent]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let username = data.get("user");
    let password = data.get("password");
    await dispatch(
      getTokenFromServer({
        username,
        password,
        setIsWrongPassword,
        setServerError,
      })
    );
    await dispatch(getUserInfo());
    await dispatch(getMySettings());
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            error={isWrongPassword || serverError !== ""}
            helperText={
              !isWrongPassword && serverError === ""
                ? ""
                : isWrongPassword
                ? "UserName or Password is Wrong"
                : serverError
            }
            margin="normal"
            required
            fullWidth
            id="user"
            label="UserName"
            name="user"
            autoComplete="user"
            autoFocus
          />
          <TextField
            error={isWrongPassword || serverError !== ""}
            helperText={
              !isWrongPassword && serverError === ""
                ? ""
                : isWrongPassword
                ? "UserName or Password is Wrong"
                : serverError
            }
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPasswordType()}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: passwordAdornment(),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            id="loginInButton"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>

          <Grid container>
            <Grid item xs>
              <Link href={`${paths["forgotpassword"]}`} variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
