import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { addGoal, createGoal, modifyGoal, deleteGoal } from "../lessonSlice";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import BucketDialog from "../../../components/EditDialog/BucketDialog";

export default function GoalSelect({ studentComment }) {
  const isSuperUser = useSelector((state) => state.user.is_superuser);
  const present =
    useSelector((state) => state.lesson.comments).find(
      (comment) => comment.id === studentComment.id
    ).status === "Y";
  const allGoals = useSelector((state) => state.lesson.goals);
  const [selectedGoal, setSelectedGoal] = React.useState(null);
  const [openGoalDialog, setOpenGoalDialog] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Autocomplete
        multiple={false}
        options={allGoals}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Goals"
            InputProps={{
              ...params.InputProps,
              endAdornment: {
                ...params.InputProps.endAdornment,
                props: {
                  ...params.InputProps.endAdornment.props,
                  children: [
                    <InputAdornment
                      key={Math.random()}
                      position="end"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setOpenGoalDialog(true);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                      {params.InputProps.endAdornment.props.children}
                    </InputAdornment>,
                  ],
                },
              },
            }}
            disabled={!present || isSuperUser}
          />
        )}
        disabled={!present || isSuperUser}
        value={selectedGoal}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          if (
            studentComment.performanceItems.find(
              (performanceItem) => performanceItem.performance.id === value.id
            )
          ) {
            toast.error("You have added this goal.");
          } else {
            setSelectedGoal(value);
            dispatch(addGoal({ comment: studentComment, goal: value }));
          }
        }}
      />
      <BucketDialog
        dialogTitle={"Goal"}
        openDialog={openGoalDialog}
        setOpenDialog={setOpenGoalDialog}
        textFieldLabel={"Goal"}
        items={allGoals}
        handleAdd={(inputValue) => {
          dispatch(createGoal({ name: inputValue }));
        }}
        handleModify={(preGoal, goal) => {
          dispatch(modifyGoal({ id: goal.id, name: goal.name }));
        }}
        handleDelete={(goal) => {
          dispatch(deleteGoal({ id: goal.id }));
        }}
        displayAttribute="name"
      />
    </>
  );
}
