import axios from "axios";
import * as actions from "../api";

const api =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const {
      endpoint,
      method,
      data,
      onStart,
      onStartPayload,
      onStart2,
      onStart2Payload,
      onSuccess,
      onSuccess2,
      onError,
      onErrorPayload,
      onError2,
      onError2Payload,
      createdRecordName,
    } = action.payload;

    if (onStart) dispatch({ type: onStart, payload: onStartPayload });
    if (onStart2) dispatch({ type: onStart2, payload: onStart2Payload });

    next(action);

    const auth_header = { Authorization: getState().user.token };
    const headers =
      typeof data !== FormData
        ? auth_header
        : { ...auth_header, "Content-Type": "multipart/form-data" };

    try {
      const response = await axios.request({
        url: endpoint,
        method,
        data,
        headers: headers,
      });

      // general success action
      dispatch(
        actions.apiCallSuccess({
          status: response.status,
          data: response.data,
          createdRecordName,
        })
      );

      // specific success action
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
      // specific continuous success action
      if (onSuccess2) dispatch({ type: onSuccess2, payload: response.data });
    } catch (error) {
      // general fail action
      dispatch(
        actions.apiCallFailed({
          message: error.message,
          status: error.response.status,
          data: error.response.data,
        })
      );

      // specific fail action
      if (onError)
        dispatch({
          type: onError,
          payload: onErrorPayload,
        });
      if (onError2)
        dispatch({
          type: onError2,
          payload: onError2Payload,
        });
    }
  };

export default api;
