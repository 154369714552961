import React, { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  TextField,
  Collapse,
  List,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { createModule } from "../../../../app/entities/modules";
import { useDispatch } from "react-redux";

export default function CreateModuleItem(props) {
  const { openModule, programme_id } = props;
  const dispatch = useDispatch();
  const [newModule, setNewModule] = useState({ name: "" });

  const handleTextFieldChange = (event) => {
    setNewModule({ name: event.target.value });
  };

  const handleAddButtonClick = () => {
    dispatch(createModule(programme_id, newModule));
    setNewModule({ name: "" });
  };

  return (
    <Collapse in={openModule}>
      <List>
        <ListItem>
          <TextField
            label="Module Name"
            value={newModule.name}
            onChange={handleTextFieldChange}
            fullWidth
          />
          <ListItemIcon sx={{ marginLeft: "1rem" }}>
            <IconButton onClick={handleAddButtonClick}>
              <AddIcon />
            </IconButton>
          </ListItemIcon>
        </ListItem>
      </List>
    </Collapse>
  );
}
