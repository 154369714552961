import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CollectionStamp from "./CollectionStamp";
import {
  Box,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { getBagItems } from "./bagSlice";
import { getStamps } from "../stamps/stampsSlice";
import question_mark from "../../image/questionmark.png";

export default function Collection() {
  const dispatch = useDispatch();
  const { studentUserId } = useParams();
  const { bagItems } = useSelector((state) => state.bag);
  const { stamps, isLoading } = useSelector((state) => state.stamps);

  const [stampsCollection, setStampsCollection] = React.useState({});
  const [filterfield, setFilterfield] = React.useState("");

  React.useEffect(() => {
    if (stamps && bagItems) {
      const newStampsCollection = {};
      stamps.forEach((stamp) => {
        newStampsCollection[stamp.id] = {
          collected: false,
          stamp: stamp,
        };
      });

      bagItems.forEach((bagItem) => {
        if (newStampsCollection[bagItem.stamp.id]) {
          if (newStampsCollection[bagItem.stamp.id].collected === false) {
            newStampsCollection[bagItem.stamp.id].collected = true;
          }
        }
      });
      setStampsCollection(newStampsCollection);
    }
  }, [bagItems, stamps]);

  React.useEffect(() => {
    if (studentUserId) {
      dispatch(getStamps());
      dispatch(getBagItems({ bagId: studentUserId }));
    }
  }, [studentUserId, dispatch]);

  return !isLoading ? (
    <Box>
      <TextField
        id="standard-required"
        label="Name Filter"
        value={filterfield}
        variant="standard"
        sx={{ display: "block" }}
        onChange={(event) => {
          setFilterfield(event.target.value);
        }}
      />
      {stamps
        .filter(
          (stamp) =>
            !filterfield ||
            (stamp.name.includes(filterfield) &&
              stampsCollection[stamp.id].collected)
        )
        .map((stamp) =>
          stampsCollection[stamp.id] && stampsCollection[stamp.id].collected ? (
            <CollectionStamp key={Math.random()} stamp={stamp} had={true} />
          ) : (
            <CollectionStamp
              key={Math.random()}
              stamp={{
                description: "??????",
                id: stamp.id,
                image: question_mark,
                name: "???",
              }}
            />
          )
        )}
    </Box>
  ) : (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
