import React from "react";

import InfoDialog from "../common/InfoDialog";

export default function ObjectiveHistoryInfoDialog(props) {
  const { values, open, handleClose } = props;

  const columns = [
    {
      field: "name",
      headerName: "Objective Name",
    },
    {
      field: "creator_username",
      headerName: "Creator",
    },
  ];

  return (
    <InfoDialog
      columns={columns}
      values={values}
      targetName="Objective"
      open={open}
      handleClose={handleClose}
    />
  );
}
