import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const axios = require("axios");

export const getSchoolList = createAsyncThunk(
  "schools.getSchoolList",
  async (arg, { getState }) => {
    const state = getState();
    return await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/schools/`,
      headers: {
        Authorization: state.user.token,
      },
    }).then((response) => response.data);
  }
);

export const createSchool = createAsyncThunk(
  "schools.createSchool",
  async ({ name, type }, { getState }) => {
    const state = getState();
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/schools/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { name, type },
    }).then((response) => response.data);
  }
);
export const modifySchool = createAsyncThunk(
  "schools.modifySchool",
  async ({ id, name, type }, { getState }) => {
    const state = getState();
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_URL}/schools/${id}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { name, type },
    }).then((response) => response.data);
  }
);
export const deleteSchool = createAsyncThunk(
  "schools.deleteSchool",
  async ({ id }, { getState }) => {
    const state = getState();
    return await axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}/schools/${id}/`,
      headers: {
        Authorization: state.user.token,
      },
    }).then((response) => response.data);
  }
);

export const schoolsSlice = createSlice({
  name: "schools",
  initialState: {},
  reducers: {
    CleanSchoolsList: (state) => {
      state = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSchoolList.fulfilled, (state, action) => {
      state.schoolsList = action.payload;
    });
    builder.addCase(createSchool.fulfilled, (state, action) => {
      state.schoolsList.push(action.payload);
      toast.success("Created New School Record Success");
    });
    builder.addCase(modifySchool.fulfilled, (state, action) => {
      state.schoolsList = state.schoolsList
        .filter((school) => school.id !== action.meta.arg.id)
        .concat([action.payload]);
      toast.success("Modify School Record Success");
    });
    builder.addCase(deleteSchool.fulfilled, (state, action) => {
      state.schoolsList = state.schoolsList.filter(
        (school) => school.id !== action.meta.arg.id
      );
      toast.success("Delete School Record Success");
    });
  },
});

export default schoolsSlice.reducer;
