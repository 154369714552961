import { createSlice, createSelector } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import {
  combineState,
  joinState,
  normalize,
  seperateState,
  annontate,
  list_of_obj_to_str,
  is_loading,
} from "../normalize";
import { pipe } from "lodash/fp";

const endpoint = endpoints["modules"];

const slice = createSlice({
  name: "modules",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    moduleListRequest: (modules, action) => {
      modules.loading = true;
    },
    moduleListReceived: (modules, { payload }) => {
      payload = normalize(payload);
      seperateState(modules, payload);
      modules.loading = false;
    },
    moduleRequest: (modules, action) => {
      modules.loading = true;
    },
    moduleReceived: (modules, { payload }) => {
      modules.allIds.push(payload.id);
      modules.byId[payload.id] = payload;
      modules.loading = false;
    },
    moduleCreate: (modules, { payload }) => {
      modules.allIds.push(payload.id);
      modules.byId[payload.id] = { ...payload, objectives: [] };
    },
    moduleCreateRollback: (modules, { payload }) => {
      modules.allIds = modules.filter((module) => module !== payload.id);
    },
    moduleUpdate: (modules, { payload }) => {
      modules.byId[payload.id] = payload;
    },
    moduleUpdateRollback: (modules, { payload }) => {
      modules.byId[payload.id] = payload;
    },
    moduleDelete: (modules, { payload }) => {
      modules.allIds = modules.allIds.filter((id) => id !== payload.id);
    },
    moduleDeleteRollback: (modules, { payload }) => {
      modules.allIds = [...modules.allIds, payload.id];
    },
    moduleObjectiveCreate: (modules, { payload }) => {
      modules.byId[payload.module].objectives.push(payload.id);
    },
    moduleObjectiveDelete: (modules, { payload }) => {
      const module = modules.byId[payload.module];
      modules.byId[payload.module] = {
        ...module,
        objectives: module.objectives.filter(
          (objective) => objective !== payload.id
        ),
      };
    },
    moduleObjectiveDeleteRollback: (modules, { payload }) => {
      modules.byId[payload.module].objectives.push(payload.id);
    },
  },
});

const {
  moduleListRequest,
  moduleListReceived,
  moduleRequest,
  moduleReceived,
  moduleCreate,
  moduleUpdate,
  moduleUpdateRollback,
  moduleDelete,
  moduleDeleteRollback,
} = slice.actions;

export const {
  moduleObjectiveCreate,
  moduleObjectiveDelete,
  moduleObjectiveDeleteRollback,
} = slice.actions;

// action
export const getModuleList = (programmeId) =>
  apiCallBegan({
    endpoint: endpoint(programmeId),
    method: "get",
    onStart: moduleListRequest.type,
    onSuccess: moduleListReceived.type,
  });

export const retrieveModule = (programmeId, id) =>
  apiCallBegan({
    endpoint: endpoint(programmeId) + id + "/",
    method: "get",
    onStart: moduleRequest.type,
    onSuccess: moduleReceived.type,
  });

export const createModule = (programmeId, module) =>
  apiCallBegan({
    endpoint: endpoint(programmeId),
    method: "post",
    data: module,
    onSuccess: moduleCreate.type,
  });

export const updateModule = (programmeId, preModule, module) =>
  apiCallBegan({
    endpoint: endpoint(programmeId) + module.id + "/",
    method: "patch",
    data: module,
    onStart: moduleUpdate.type,
    onStartPayload: module,
    onError: moduleUpdateRollback.type,
    onErrorPayload: preModule,
  });

export const deleteModulebyId = (programmeId, id) =>
  apiCallBegan({
    endpoint: endpoint(programmeId) + id + "/",
    method: "delete",
    onStart: moduleDelete.type,
    onStartPayload: { id },
    onError: moduleDeleteRollback.type,
    onErrorPayload: { id },
  });

// selector
export const getModules = (state) => combineState(state.entities.modules);

export const getModulesDetail = createSelector(
  (state) => state.entities.modules,
  (state) => state.entities.objectives,
  (modules, objectives) => {
    if (is_loading(modules, objectives)) return [];
    const extend_detail = pipe(
      combineState,
      joinState("objectives", objectives.byId)
    );
    return extend_detail(modules);
  }
);

export const getModuleLoadingStatus = (state) => state.entities.modules.loading;

export const getModulebyId = (id) => (state) => state.entities.modules.byId[id];

export default slice.reducer;
