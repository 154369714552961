import { toast } from "react-toastify";
import { apiCallFailed, apiCallSuccess } from "../api";

const onFailed = (action) => {
  const { data, message, status } = action.payload;
  console.error(action.payload);
  console.table(action.payload.data);
  if (status >= 400 && status < 500) {
    for (let key in data)
      toast.error(
        `${key}: ${Array.isArray(data[key]) ? data[key][0] : data[key]}`
      );
  } else {
    toast.error("Failed to connect server");
  }
};

const onSuccess = (action) => {
  const { data, status, createdRecordName } = action.payload;
  if (status == 201 && createdRecordName)
    toast.success(`Created ${createdRecordName} successfully!`);
};

const middleToast = (store) => (next) => (action) => {
  if (action.type === apiCallFailed.type) onFailed(action);
  else if (action.type === apiCallSuccess.type) onSuccess(action);
  return next(action);
};

export default middleToast;
