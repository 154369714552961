import Joi from "joi-browser";

export const validateProperty = ({ field, value }, schema) => {
  const obj = { [field]: value };
  const propertySchema = { [field]: schema[field] };
  const { error } = Joi.validate(obj, propertySchema);
  return error ? error.details[0].message : null;
};

export const validate = (obj, schema) => {
  // perform validate
  const options = { abortEarly: false, allowUnknown: true };
  const { error } = Joi.validate(obj, schema, options);
  if (!error) return null;

  // reconstruct errors object
  const errors = {};
  for (let item of error.details) errors[item.path[0]] = item.message;
  return errors;
};
