import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Bold from "../Text/Bold";

export default function DeleteAlertDialog({ targetName, open, handleClose }) {
  const recordName = <Bold>{targetName}</Bold> || "This record";

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure to delete?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description2">
          {recordName} will be deleted forever!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="success" onClick={() => handleClose(true)}>
          Yes
        </Button>
        <Button color="error" onClick={() => handleClose(false)}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
