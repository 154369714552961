import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import courseReducer from "../features/course/courseSlice";
import lessonReducer from "../features/lesson/lessonSlice";
import studentReducer from "../features/student/studentSlice";
import coursesReducer from "../features/courses/coursesSlice";
import lessonsReducer from "../features/lessons/lessonsSlice";
import schoolsReducer from "../features/schools/schoolsSlice";
import bagReducer from "../features/bag/bagSlice";
import stampsReducer from "../features/stamps/stampsSlice";
import teacherReducer from "../features/teacher/teacherSlice";

import entitiesReducer from "./entities";
import uiReducer from "./ui.js";

import middleToast from "./middleware/toast";
import api from "./middleware/api";

export const store = configureStore({
  reducer: {
    // Common
    user: userReducer,
    course: courseReducer,
    lessons: lessonsReducer,
    lesson: lessonReducer,
    bag: bagReducer,
    student: studentReducer,
    courses: coursesReducer,
    schools: schoolsReducer,
    stamps: stampsReducer,
    teacher: teacherReducer,

    // Database storage
    entities: entitiesReducer,

    // UI state
    ui: uiReducer,
  },
  middleware: [...getDefaultMiddleware(), middleToast, api],
});
