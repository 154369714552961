import React from "react";
import { Collapse, List } from "@mui/material";

import ObjectiveInput from "./ObjectiveInput";
import ObjectiveListItem from "./ObjectiveListItem";

export default function CollaspeObjective(props) {
  const { open, module, objectives } = props;

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List disablePadding>
        {objectives.map((objective) => (
          <ObjectiveListItem key={objective.id} objective={objective} />
        ))}
        <ObjectiveInput module={module} />
      </List>
    </Collapse>
  );
}
