import React from "react";
import {
  Dialog,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ImageCrop from "../../components/ImageCrop/ImageCrop";
import { updateStamp } from "./stampsSlice";

export default function AdminStamp({ stamp }) {
  const [openAddStampDialog, setOpenAddStampDialog] = React.useState(false);
  const [modifiedStampName, setModifiedStampName] = React.useState("");
  const [modifiedStampDescription, setModifiedStampDescription] =
    React.useState("");
  const [modifiedStampPhoto, setModifiedStampPhoto] = React.useState(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setModifiedStampName(stamp.name);
    setModifiedStampDescription(stamp.description);
  }, [stamp]);

  return (
    <>
      {stamp && (
        <>
          <Card
            sx={{
              margin: "10px 15px",
              width: "200px",
              height: "auto",
              display: "inline-block",
              background:
                "radial-gradient(circle, rgba(196,230,255,0.7189469537815126) 0%, rgba(187,238,251,0.9402354691876751) 52%, rgba(84,174,240,0.7189469537815126) 93%)",
              boxShadow: "10px 5px 5px rgba(0, 0, 0, 0.35)",
              borderRadius: "15px",
              "&:hover": {
                transform: "scale(1.1)",
                boxShadow: "none",
                border: "2.5px solid Tomato",
                boxShadow: "rgba(0, 0, 0, 0.35) 5px 5px 15px",
                transition: "0.3s",
              },
            }}
            onClick={() => {
              setOpenAddStampDialog(true);
            }}
          >
            <CardContent>
              <CardMedia
                component="img"
                image={stamp.image}
                alt={stamp.description}
                sx={{
                  maxHeight: "200px",
                  maxWidth: "200px",
                }}
              />
              <Box sx={{ marginTop: "10px" }}>
                <Typography gutterBottom variant="h5" component="div">
                  {stamp.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    whiteSpace: "pre-line",
                    overflow: "auto",
                    height: "60px",
                  }}
                >
                  {stamp.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Dialog
            open={openAddStampDialog}
            onClose={() => {
              setOpenAddStampDialog(false);
              setModifiedStampName(stamp.name);
              setModifiedStampDescription(stamp.description);
              setModifiedStampPhoto(null);
              setImgSrc(null);
            }}
            BackdropProps={{
              style: { backgroundColor: "gray", opacity: 0.95 },
            }}
            fullWidth
          >
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "20px",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageCrop
                  handleSubmit={(file) => {
                    setModifiedStampPhoto(file);
                    const reader = new FileReader();
                    reader.addEventListener("load", () =>
                      setImgSrc(reader.result ? reader.result.toString() : "")
                    );
                    reader.readAsDataURL(file);
                  }}
                />
                <Box
                  component="img"
                  sx={{
                    maxHeight: "264px",
                    maxWidth: "264px",
                  }}
                  src={imgSrc}
                />
                <TextField
                  key="name"
                  error={false}
                  label="Name"
                  value={modifiedStampName}
                  helperText={""}
                  onChange={(event) => {
                    setModifiedStampName(event.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
                <TextField
                  key={"description"}
                  error={false}
                  value={modifiedStampDescription}
                  label={"Description"}
                  helperText={""}
                  multiline={true}
                  maxRows={10}
                  minRows={5}
                  onChange={(event) => {
                    setModifiedStampDescription(event.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              {!(
                modifiedStampName === stamp.name &&
                modifiedStampDescription === stamp.description &&
                imgSrc == null
              ) && (
                <Button
                  variant="contained"
                  disabled={
                    modifiedStampName === stamp.name &&
                    modifiedStampDescription === stamp.description &&
                    imgSrc == null
                  }
                  onClick={() => {
                    dispatch(
                      updateStamp({
                        name: modifiedStampName,
                        description: modifiedStampDescription,
                        image: modifiedStampPhoto,
                        id: stamp.id,
                      })
                    );
                  }}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
