import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRoutePath, updateRoutePath } from "../app/ui/routes";

export default function useTab(tabName, reduxRouteName) {
  const path = useSelector(getRoutePath(reduxRouteName));
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const handleChange = (event, newTab) => {
    setSearchParams({ [tabName]: newTab });
    dispatch(updateRoutePath(reduxRouteName, newTab));
  };

  useEffect(() => {
    const queryValue = queryParams.get(tabName);
    if (queryValue !== null) {
      dispatch(updateRoutePath(reduxRouteName, queryValue));
    }
  }, [location.search]);

  useEffect(() => {
    const queryValue = queryParams.get(tabName);
    if (queryValue) {
      dispatch(updateRoutePath(reduxRouteName, queryValue));
    } else if (path) {
      setSearchParams({ [tabName]: path }, { replace: true });
    }
  }, []);

  return [path, handleChange];
}
