import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  getShowAddBarStatus,
  updateShowAddBarStatus,
} from "../app/ui/showAddBar";

export default function useTableToolBar(name) {
  const [showAddBarStatus, setShowAddBarStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleShowAddBar = () => setShowAddBarStatus(!showAddBarStatus);

  // const dispatch = useDispatch();
  // const showAddBarStatus = useSelector(getShowAddBarStatus(name));

  // const handleShowAddBar = () => {
  //   dispatch(updateShowAddBarStatus(name, !showAddBarStatus));
  // };

  return [showAddBarStatus, handleShowAddBar, searchValue, setSearchValue];
}
