import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const axios = require("axios");

export const getLessonDetail = createAsyncThunk(
  "lesson/getLessonDetail",
  async ({ programmeId, lessonId }, { getState }) => {
    const state = getState();
    const lessonInfo = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/lessons/${lessonId}/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);

    const courseInfo = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);

    const comments = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/comments/`,
      headers: {
        Authorization: state.user.token,
      },
      params: { lesson_id: lessonId },
    }).then((response) => response.data);

    const objectiveItemsPromises = comments.map((comment) =>
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL}/comments/${comment.id}/objective_items`,
        headers: {
          Authorization: state.user.token,
        },
        params: {},
      }).then((response) => ({
        commentId: comment.id,
        objectiveItems: response.data,
      }))
    );
    const objectiveItemsForEachComments = await Promise.all(
      objectiveItemsPromises
    );

    const performanceItemsPromises = comments.map((comment) =>
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL}/comments/${comment.id}/performance_items`,
        headers: {
          Authorization: state.user.token,
        },
        params: {},
      }).then((response) => ({
        commentId: comment.id,
        performanceItems: response.data,
      }))
    );
    const performanceItemsForEachComments = await Promise.all(
      performanceItemsPromises
    );

    return {
      courseInfo,
      lessonInfo,
      comments,
      objectiveItemsForEachComments,
      performanceItemsForEachComments,
    };
  }
);

export const getGoals = createAsyncThunk(
  "lesson/getGoals",
  async (args, { getState }) => {
    const state = getState();
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/performances/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {},
    }).then((response) => response.data);
  }
);

export const createGoal = createAsyncThunk(
  "lesson/createGoal",
  async ({ name }, { getState }) => {
    const state = getState();
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/performances/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { name },
    }).then((response) => response.data);
  }
);

export const modifyGoal = createAsyncThunk(
  "lesson/modifyGoal",
  async ({ id, name }, { getState }) => {
    const state = getState();
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_URL}/performances/${id}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { name },
    }).then((response) => response.data);
  }
);

export const deleteGoal = createAsyncThunk(
  "lesson/deleteGoal",
  async ({ id }, { getState }) => {
    const state = getState();
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}/performances/${id}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {},
    }).then((response) => response.data);
  }
);

export const addLessonModule = createAsyncThunk(
  "lesson/addLessonModule",
  async ({ programmeId, lessonId, selectedModules }, { getState }) => {
    const state = getState();
    const response = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/lessons/${lessonId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        modules: selectedModules.map((selectedModule) => selectedModule.id),
      },
    }).then((response) => response.data);

    return { selectedModules, response };
  }
);

export const removeLessonModule = createAsyncThunk(
  "lesson/removeLessonModule",
  async ({ programmeId, lessonId, selectedModules }, { getState }) => {
    const state = getState();
    const response = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/lessons/${lessonId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        modules: selectedModules.map((selectedModule) => selectedModule.id),
      },
    }).then((response) => response.data);

    return { selectedModules, response };
  }
);

export const updateObjective = createAsyncThunk(
  "lesson/updateObjective",
  async ({ programmeId, lessonId, objectiveIds, commentIds }, { getState }) => {
    const state = getState();
    await axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/lessons/${lessonId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        objectives: objectiveIds,
      },
    }).then((response) => response.data);

    const objectiveItemsPromises = commentIds.map((commentId) =>
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL}/comments/${commentId}/objective_items/`,
        headers: {
          Authorization: state.user.token,
        },
        params: {},
      }).then((response) => ({
        commentId,
        objectiveItems: response.data,
      }))
    );
    return Promise.all(objectiveItemsPromises);
  }
);

export const addGoal = createAsyncThunk(
  "lesson/addGoal",
  async ({ comment, goal }, { getState }) => {
    const state = getState();
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/comments/${comment.id}/performance_items/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        performance: goal.id,
      },
    }).then((response) => ({
      commentId: comment.id,
      performanceItemId: response.data.id,
      goal,
    }));
  }
);

export const removeGoal = createAsyncThunk(
  "lesson/removeGoal",
  async ({ commentId, performanceItemId }, { getState }) => {
    const state = getState();
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}/comments/${commentId}/performance_items/${performanceItemId}`,
      headers: {
        Authorization: state.user.token,
      },
      data: {},
    });
    return { commentId, performanceItemId };
  }
);

export const postExpectation = createAsyncThunk(
  "lesson/postExpectation",
  async ({ objectiveItem, commentId, expectation }, { getState }) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/comments/${commentId}/objective_items/${objectiveItem.id}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        expectation,
      },
    }).then((response) => ({
      commentId,
      oldObjectiveItem: objectiveItem,
      newObjectiveItem: response.data,
    }));
  }
);

export const postPerformance = createAsyncThunk(
  "lesson/postPerformance",
  async ({ objectiveItem, commentId, performance }, { getState }) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/comments/${commentId}/objective_items/${objectiveItem.id}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        performance,
      },
    }).then((response) => ({
      commentId,
      oldObjectiveItem: objectiveItem,
      newObjectiveItem: response.data,
    }));
  }
);

export const postScore = createAsyncThunk(
  "lesson/postScore",
  async ({ objectiveItem, commentId, score }, { getState }) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/comments/${commentId}/objective_items/${objectiveItem.id}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        score,
      },
    }).then((response) => ({
      commentId,
      oldObjectiveItem: objectiveItem,
      newObjectiveItem: response.data,
    }));
  }
);

export const togglePresent = createAsyncThunk(
  "lesson/togglePresent",
  async ({ present, commentId }, { getState }) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/comments/${commentId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        status: present,
      },
    }).then((response) => response.data);
  }
);

export const giveComment = createAsyncThunk(
  "lesson/giveComment",
  async ({ remark, commentId }, { getState }) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/comments/${commentId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        remark,
      },
    }).then((response) => response.data);
  }
);

export const giveStamp = createAsyncThunk(
  "lesson/giveStamp",
  async ({ studentId, stamp, stampId, commentId }, { getState }) => {
    const state = getState();
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/bags/${studentId}/items/`,
      headers: {
        Authorization: state.user.token,
      },
      data: {
        stamp: stampId,
        comment_id: commentId,
        isUsed: false,
      },
    }).then((response) => response.data);
  }
);

export const removeStamp = createAsyncThunk(
  "lesson/removeStamp",
  async ({ commentId, studentId, bagItemId }, { getState }) => {
    const state = getState();
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}/bags/${studentId}/items/${bagItemId}/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const lessonSlice = createSlice({
  name: "lesson",
  initialState: {
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLessonDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLessonDetail.fulfilled, (state, action) => {
      state.loading = false;

      const {
        courseInfo,
        lessonInfo,
        comments,
        objectiveItemsForEachComments,
        performanceItemsForEachComments,
      } = action.payload;

      state.courseInfo = {};
      state.courseInfo.category = courseInfo.category_name;
      state.courseInfo.programme = courseInfo.name;
      state.courseInfo.level = courseInfo.level;

      state.info = lessonInfo;

      state.comments = comments;

      objectiveItemsForEachComments.forEach((objectiveItemsForTheComment) => {
        var comment = state.comments.find(
          (comment) => comment.id === objectiveItemsForTheComment.commentId
        );
        if (comment)
          comment.objectiveItems = objectiveItemsForTheComment.objectiveItems;
      });

      state.comments.forEach(
        (comment, index) =>
          (comment.performanceItems =
            performanceItemsForEachComments[index].performanceItems)
      );
    });
    builder.addCase(addLessonModule.fulfilled, (state, action) => {
      const { selectedModules } = action.payload;
      state.info.modules = selectedModules;
    });
    builder.addCase(removeLessonModule.fulfilled, (state, action) => {
      const { selectedModules } = action.payload;
      state.info.modules = selectedModules;
      state.comments = state.comments.map((comment) => ({
        ...comment,
        objectiveItems: comment.objectiveItems.filter((objectiveItem) =>
          selectedModules
            .map((selectedModule) => selectedModule.id)
            .includes(objectiveItem.objective.module)
        ),
      }));
    });
    builder.addCase(updateObjective.fulfilled, (state, action) => {
      action.payload.forEach((objectiveItemsForTheComment) => {
        var comment = state.comments.find(
          (comment) => comment.id === objectiveItemsForTheComment.commentId
        );
        if (comment)
          comment.objectiveItems = objectiveItemsForTheComment.objectiveItems;
      });
    });
    builder.addCase(getGoals.fulfilled, (state, action) => {
      state.goals = action.payload;
    });
    builder.addCase(createGoal.fulfilled, (state, action) => {
      state.goals.push(action.payload);
    });
    builder.addCase(modifyGoal.fulfilled, (state, action) => {
      // state.goals = state.goals
      //   .filter((goal) => goal.id !== action.payload.id)
      //   .push(action.payload);
    });
    builder.addCase(deleteGoal.fulfilled, (state, action) => {
      state.goals = state.goals.filter(
        (goal) => goal.id !== action.meta.arg.id
      );
    });
    builder.addCase(addGoal.fulfilled, (state, action) => {
      const { commentId, performanceItemId, goal } = action.payload;

      state.comments
        .find((comment) => comment.id === commentId)
        .performanceItems.push({ id: performanceItemId, performance: goal });
    });
    builder.addCase(removeGoal.fulfilled, (state, action) => {
      const { commentId, performanceItemId } = action.payload;

      state.comments.find(
        (comment) => comment.id === commentId
      ).performanceItems = state.comments
        .find((comment) => comment.id === commentId)
        .performanceItems.filter(
          (performanceItem) => performanceItem.id !== performanceItemId
        );
    });
    builder.addCase(postExpectation.fulfilled, (state, action) => {
      let { commentId, oldObjectiveItem, newObjectiveItem } = action.payload;
      newObjectiveItem = {
        ...newObjectiveItem,
        objective: oldObjectiveItem.objective,
      };

      const commentToBeUpdated = state.comments.find(
        (comment) => comment.id === commentId
      );
      commentToBeUpdated.objectiveItems = commentToBeUpdated.objectiveItems.map(
        (objectiveItem) =>
          objectiveItem.id === oldObjectiveItem.id
            ? newObjectiveItem
            : objectiveItem
      );
    });
    builder.addCase(postPerformance.fulfilled, (state, action) => {
      let { commentId, oldObjectiveItem, newObjectiveItem } = action.payload;
      newObjectiveItem = {
        ...newObjectiveItem,
        objective: oldObjectiveItem.objective,
      };

      const commentToBeUpdated = state.comments.find(
        (comment) => comment.id === commentId
      );
      commentToBeUpdated.objectiveItems = commentToBeUpdated.objectiveItems.map(
        (objectiveItem) =>
          objectiveItem.id === oldObjectiveItem.id
            ? newObjectiveItem
            : objectiveItem
      );
    });
    builder.addCase(postScore.fulfilled, (state, action) => {
      let { commentId, oldObjectiveItem, newObjectiveItem } = action.payload;
      newObjectiveItem = {
        ...newObjectiveItem,
        objective: oldObjectiveItem.objective,
      };

      const commentToBeUpdated = state.comments.find(
        (comment) => comment.id === commentId
      );
      commentToBeUpdated.objectiveItems = commentToBeUpdated.objectiveItems.map(
        (objectiveItem) =>
          objectiveItem.id === oldObjectiveItem.id
            ? newObjectiveItem
            : objectiveItem
      );
    });
    builder.addCase(togglePresent.fulfilled, (state, action) => {
      const { id, status } = action.payload;
      state.comments.find((comment) => comment.id === id).status = status;
    });
    builder.addCase(giveComment.fulfilled, (state, action) => {
      const { id, remark } = action.payload;
      state.comments.find((comment) => comment.id === id).remark = remark;
    });
    builder.addCase(giveStamp.fulfilled, (state, action) => {
      const { commentId, stamp } = action.meta.arg;
      state.comments
        .find((comment) => comment.id === commentId)
        .bag_items.push({ ...action.payload, stamp });
    });
    builder.addCase(removeStamp.fulfilled, (state, action) => {
      const { commentId, studentId, bagItemId } = action.meta.arg;
      state.comments.find((comment) => comment.id === commentId).bag_items =
        state.comments
          .find((comment) => comment.id === commentId)
          .bag_items.filter((bagItem) => bagItem.id !== bagItemId);
    });
  },
});

export const { addModules } = lessonSlice.actions;

export default lessonSlice.reducer;
