import * as React from "react";
import { useSelector } from "react-redux";
import { Typography, Paper, Chip, Avatar } from "@mui/material/";
import { Link } from "react-router-dom";
import { paths } from "../../config";

export default function Students() {
  const students = useSelector((state) => state.course.courseInfo.students);
  const sortedStudents = students
    ? [...students].sort((student1, student2) => {
        return `${student1.first_name} ${student1.last_name}`.localeCompare(
          `${student2.first_name} ${student2.last_name}`
        );
      })
    : null;

  return (
    <Paper sx={{ marginTop: 2, padding: 2 }}>
      <Typography variant="h6">Students:</Typography>
      {sortedStudents
        ? sortedStudents.map((student) => (
            <Chip
              key={Math.random()}
              // avatar={<Avatar alt="" src="/static/images/avatar/1.jpg" />}
              clickable
              label={`${student.first_name} ${student.last_name.toUpperCase()}`}
              component={Link}
              to={`/${paths["studentProfile"](student.id)}`}
              sx={{ margin: "5px 10px" }}
            />
          ))
        : null}
    </Paper>
  );
}
