import React, { useState } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import TopicIcon from "@mui/icons-material/Topic";

import CreateModuleItem from "./CreateModuleItem";

export default function ModuleHeader(props) {
  const { programme_id } = props;
  const [openModule, setOpenModule] = useState(false);

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setOpenModule(!openModule)}>
          <ListItemIcon>
            <TopicIcon />
          </ListItemIcon>
          <ListItemText primary="Create Module" />
        </ListItemButton>
      </ListItem>
      <CreateModuleItem programme_id={programme_id} openModule={openModule} />
      <Divider />
    </>
  );
}
