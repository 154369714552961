import React, { useState, useMemo, forwardRef } from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useForm from "../../../../hooks/useForm";
import { validate } from "../../../../validator/common";

function AddDialog(props, ref) {
  const {
    columns,
    onSubmit,
    schema,
    openDialog,
    onClose,
    title,
    values,
    setValues,
  } = props;

  const [errors, setErrors, renderField] = useForm(
    values,
    setValues,
    schema,
    "outlined"
  );
  const disableButton = useMemo(
    () => Boolean(validate(values, schema)),
    [values, schema]
  );

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        onClose();
        setErrors({});
      }}
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          flexDirection: "column",
          display: "flex",
          gap: 2,
          padding: 4,
        }}
      >
        {columns.map((column) => renderField(column))}
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<AddIcon />}
          size="small"
          onClick={() => {
            setValues({});
            onSubmit(values);
          }}
          disabled={disableButton}
        >
          Add Record
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default forwardRef(AddDialog);
