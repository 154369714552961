import React, { useState, useMemo, useEffect } from "react";
import { IconButton } from "@mui/material";
import Joi from "joi-browser";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";

import { getCategories } from "../../../../app/entities/categories";
import { getUsers } from "../../../../app/entities/users";
import {
  getVenueConditions,
  createVenueCondition,
  updateVenueCondition,
  deleteVenueConditionbyId,
} from "../../../../app/entities/venueConditions";
import BucketDialog from "../../../../components/EditDialog/BucketDialog";
import EditPaper from "../../../../components/common/EditPaper";
import { updateProgramme } from "../../../../app/entities/programmes";
import { normalize_object } from "../../../../app/normalize";

export default function ProgrammeInfo(props) {
  const { preValues, editMode, setEditMode, setProgramme } = props;
  const dispatch = useDispatch();
  const [modifiedProgramme, setModifiedProgramme] = useState(null);
  const [openVenueConditionsDialog, setOpenVenueConditionsDialog] =
    useState(false);

  const categories = useSelector(getCategories);
  const users = useSelector(getUsers);
  const venueConditions = useSelector(getVenueConditions);

  const students = useMemo(
    () => users.filter((user) => user.is_student),
    [users]
  );
  const teachers = useMemo(
    () => users.filter((user) => user.is_teacher),
    [users]
  );

  const saveAction = () => {
    let prev = normalize_object(preValues);
    let modified = normalize_object(modifiedProgramme);
    dispatch(updateProgramme(prev, modified));
    setProgramme(modifiedProgramme);
  };

  const columns = [
    {
      render_type: "select",
      field: "category",
      headerName: "Category",
      choices: categories,
      required: true,
    },
    {
      field: "name",
      headerName: "Course Name",
      required: true,
    },
    {
      field: "level",
      headerName: "Course Level",
    },
    {
      field: "description",
      headerName: "Description",
    },
    {
      render_type: "autocomplete",
      field: "teachers",
      headerName: "Teachers",
      choices: teachers,
      multiple: true,
      displayLabel: (obj) => obj.first_name + " " + obj.last_name,
    },
    {
      render_type: "autocomplete",
      field: "students",
      headerName: "Students",
      choices: students,
      multiple: true,
      displayLabel: (obj) => obj.first_name + " " + obj.last_name,
    },
    {
      render_type: "autocomplete",
      field: "venue_conditions",
      headerName: "Venue Conditions",
      choices: venueConditions,
      multiple: true,
      displayLabel: (obj) => obj.description,
      extraEndAdornment: (
        <IconButton
          color="primary"
          onClick={() => {
            setOpenVenueConditionsDialog(true);
          }}
        >
          <AddIcon />
        </IconButton>
      ),
    },
  ];

  const schema = {
    name: Joi.string().required(),
    level: Joi.string().empty(""),
    description: Joi.string().empty(""),
  };

  useEffect(() => {
    setModifiedProgramme(preValues);
  }, [preValues]);

  if ((preValues !== null) & (modifiedProgramme !== null))
    return (
      <>
        <EditPaper
          columns={columns}
          schema={schema}
          preValues={preValues}
          values={modifiedProgramme}
          setValues={setModifiedProgramme}
          saveAction={saveAction}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        <BucketDialog
          dialogTitle={"Venue Conditions"}
          openDialog={openVenueConditionsDialog}
          setOpenDialog={setOpenVenueConditionsDialog}
          textFieldLabel={"Venue Conditions"}
          items={venueConditions}
          handleAdd={(inputValue) => {
            dispatch(createVenueCondition({ description: inputValue }));
          }}
          handleModify={(preVenueCondition, venueCondition) => {
            dispatch(updateVenueCondition(preVenueCondition, venueCondition));
          }}
          handleDelete={(venueCondition) => {
            dispatch(deleteVenueConditionbyId(venueCondition.id));
          }}
          displayAttribute="description"
        />
      </>
    );
}
