import * as React from "react";
import { Typography, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getTeacherDetail } from "./teacherSlice";

export default function TeacherInfo({ teacherUserId }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTeacherDetail({ teacherUserId }));
  }, [teacherUserId, dispatch]);

  const teacher = useSelector((state) => state.teacher.teacher);

  return (
    <>
      <Typography variant="h5" textAlign="center">
        Teacher Information
      </Typography>
      {teacher ? (
        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              marginRight: "20px",
              textAlign: "right",
            }}
          >
            <Typography variant="body1" key={Math.random()}>
              {"Degree :"}
            </Typography>
            <Typography variant="body1" key={Math.random()}>
              {"Start Working Date :"}
            </Typography>
            <Typography variant="body1" key={Math.random()}>
              {"Termination Date :"}
            </Typography>
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <Typography variant="body1" key={Math.random()}>
              {teacher.degree ? teacher.degree : "N/A"}
            </Typography>
            <Typography variant="body1" key={Math.random()}>
              {teacher.start_working_date ? teacher.start_working_date : "N/A"}
            </Typography>
            <Typography variant="body1" key={Math.random()}>
              {teacher.termination_date ? teacher.termination_date : "N/A"}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </>
  );
}
