import React, { forwardRef } from "react";
import Addbar from "../common/Addbar";
import Joi from "joi-browser";
import { createUser } from "../../app/entities/users";
import { useDispatch } from "react-redux";

function UserAddBar(props, ref) {
  const { setSearchValue } = props;
  const dispatch = useDispatch();

  const roleChoices = [
    { id: "student", name: "Student" },
    { id: "teacher", name: "Teacher" },
  ];

  const columns = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      required: true,
      focus: true,
    },
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      required: true,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      required: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      type: "email",
      flex: 1.5,
    },
    {
      field: "password",
      headerName: "Password",
      type: "password",
      flex: 1.1,
      required: true,
      random: true,
    },
    {
      render_type: "select",
      field: "user_type",
      headerName: "Role",
      choices: roleChoices,
      flex: 0.7,
      required: true,
    },
  ];

  const schema = {
    user_type: Joi.string().required(),
    username: Joi.string().alphanum().required(),
    first_name: Joi.string().required(),
    last_name: Joi.string().required(),
    email: Joi.string().empty("").email(),
    password: Joi.string().required(),
  };

  return (
    <Addbar
      ref={ref}
      columns={columns}
      schema={schema}
      onSubmit={(value) => {
        dispatch(createUser(value));
      }}
    />
  );
}

export default forwardRef(UserAddBar);
