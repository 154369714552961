import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  Divider,
  DialogActions,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { getUserTeacherInfo, updateUserTeacherInfo } from "./userSlice";

export default function UserTeacherProfileEditing() {
  const [openTeacherProfileEditDialog, setOpenTeacherProfileEditDialog] =
    React.useState(false);
  const [
    openTeacherProfileEditComfirmWarningDialog,
    setOpenTeacherProfileEditComfirmWarningDialog,
  ] = React.useState(false);

  const dispatch = useDispatch();
  const teacher = useSelector((state) => state.user.teachers);
  const [modifyTeacher, setModifyTeacher] = React.useState({});

  React.useEffect(() => {
    dispatch(getUserTeacherInfo());
  }, [dispatch]);

  React.useEffect(() => {
    if (teacher !== null) {
      setModifyTeacher({
        degree: teacher.degree,
        start_working_date: teacher.start_working_date,
        termination_date: teacher.termination_date,
        isWorking: teacher.termination_date ? "Not Working" : "Working",
      });
    }
  }, [teacher]);

  return teacher && modifyTeacher !== {} ? (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpenTeacherProfileEditDialog(true);
        }}
        sx={{ marginLeft: "10px", marginTop: "20px", marginBottom: "10px" }}
      >
        Edit
      </Button>
      <Dialog
        open={openTeacherProfileEditDialog}
        onClose={() => setOpenTeacherProfileEditDialog(false)}
        fullWidth={true}
      >
        <DialogTitle
          variant="h5"
          sx={{ marginBottom: "20px", marginTop: "20px" }}
        >
          {"Teacher Profile Edit"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
              gap: "20px",
            }}
          >
            <TextField
              label="Degree"
              value={modifyTeacher.degree || null}
              onChange={(event) => {
                setModifyTeacher({
                  degree: event.target.value,
                  start_working_date: modifyTeacher.start_working_date,
                  termination_date: modifyTeacher.termination_date,
                  isWorking: modifyTeacher.isWorking,
                });
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} value={null}>
              <MobileDatePicker
                key={"start_working_date"}
                inputFormat="dd/MM/yyyy"
                label={"Start Working Date"}
                onChange={(newDate) => {
                  setModifyTeacher({
                    degree: modifyTeacher.degree,
                    start_working_date: newDate.toISOString().split("T")[0],
                    termination_date: modifyTeacher.termination_date,
                    isWorking: modifyTeacher.isWorking,
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
                value={
                  modifyTeacher.start_working_date
                    ? modifyTeacher.start_working_date
                    : null
                }
              />
            </LocalizationProvider>
            <Autocomplete
              id="isWorking"
              options={["Working", "Not Working"]}
              value={modifyTeacher.isWorking}
              renderInput={(params) => (
                <TextField {...params} label="Working Status" />
              )}
              onChange={(event, value) => {
                if (value === "Not Working") {
                  setModifyTeacher({
                    degree: modifyTeacher.degree,
                    start_working_date: modifyTeacher.start_working_date,
                    termination_date: null,
                    isWorking: value,
                  });
                } else {
                  setModifyTeacher({
                    degree: modifyTeacher.degree,
                    start_working_date: modifyTeacher.start_working_date,
                    termination_date: modifyTeacher.termination_date,
                    isWorking: value,
                  });
                }
              }}
            />
            {modifyTeacher.isWorking === "Not Working" && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  key={"termination_date"}
                  inputFormat="dd/MM/yyyy"
                  label={"Termination Date"}
                  onChange={(newDate) => {
                    setModifyTeacher({
                      degree: modifyTeacher.degree,
                      start_working_date: modifyTeacher.start_working_date,
                      termination_date: newDate.toISOString().split("T")[0],
                      isWorking: modifyTeacher.isWorking,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    modifyTeacher.termination_date
                      ? modifyTeacher.termination_date
                      : null
                  }
                />
              </LocalizationProvider>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpenTeacherProfileEditComfirmWarningDialog(true);
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenTeacherProfileEditDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openTeacherProfileEditComfirmWarningDialog}
        onClose={() => {
          setOpenTeacherProfileEditComfirmWarningDialog(false);
        }}
        fullWidth={true}
      >
        <DialogContent>{"Are you sure about that?"}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const data = {
                degree: modifyTeacher.degree,
                start_working_date: modifyTeacher.start_working_date,
                termination_date: modifyTeacher.termination_date,
              };
              dispatch(
                updateUserTeacherInfo({data})
              );
              setOpenTeacherProfileEditComfirmWarningDialog(false);
              setOpenTeacherProfileEditDialog(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenTeacherProfileEditComfirmWarningDialog(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
}
