import * as React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetail } from "./courseSlice";
import Students from "./Students";
import Teachers from "./Teachers";
import CourseInfo from "./CourseInfo";
import Module from "./Modules";
import PropTypes from "prop-types";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import useTab from "../../hooks/useTab";
import TabWithContent from "../../components/common/TabWithContent";
import ModuleInfo from "../admin/CoursePannel/ModuleInfo/ModuleInfo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function CoursePage() {
  // url setting
  const [tab, handleTabChange] = useTab("view", "course");

  const { courseId } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.course.loading);
  const isTeacher = useSelector((state) => state.user.is_teacher);
  const isStudent = useSelector((state) => state.user.is_student);
  const isSuperUser = useSelector((state) => state.user.is_superuser);

  React.useEffect(() => {
    dispatch(getCourseDetail({ programmeId: courseId }));
  }, [courseId, dispatch]);

  const pages = [
    {
      label: "Course Information",
      component: (
        <>
          <CourseInfo />
          <Students />
          {isSuperUser && <Teachers />}
        </>
      ),
      route: "courseInfo",
    },
  ];

  if (isSuperUser || isTeacher)
    pages.push({
      label: "Course Modules",
      component: (
        <>
          {(isSuperUser || isTeacher) && <ModuleInfo programme_id={courseId} />}
        </>
      ),
      route: "courseModules",
    });

  return (
    <Box sx={{ width: "100%" }}>
      {!isLoading ? (
        <TabWithContent
          pages={pages}
          tab={tab}
          handleTabChange={handleTabChange}
        />
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  );
}
