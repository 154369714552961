import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const axios = require("axios");

export const getCourseDetail = createAsyncThunk(
  "course/getCourseDetail",
  async ({ programmeId }, { getState }) => {
    const state = getState();

    const courseInfo = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);

    const modules = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/modules/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);

    return { courseInfo, modules };
  }
);

export const addObjective = createAsyncThunk(
  "course/addObjective",
  async ({ programmeId, moduleId, objectiveName }, { getState }) => {
    const state = getState();
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/modules/${moduleId}/objectives/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { name: objectiveName, target_stamp_number: 0 },
    }).then((response) => ({ moduleId, objective: response.data }));
  }
);

export const modifyObjective = createAsyncThunk(
  "course/modifyObjective",
  async (
    { programmeId, moduleId, objectiveName, objectiveId },
    { getState }
  ) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/modules/${moduleId}/objectives/${objectiveId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { name: objectiveName },
    }).then((response) => ({ moduleId, objective: response.data }));
  }
);

export const deleteObjective = createAsyncThunk(
  "course/deleteObjective",
  async ({ programmeId, moduleId, objectiveId }, { getState }) => {
    const state = getState();
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/modules/${moduleId}/objectives/${objectiveId}/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const modifyModule = createAsyncThunk(
  "course/modifyModule",
  async ({ programmeId, moduleId, moduleName }, { getState }) => {
    const state = getState();
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/modules/${moduleId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { name: moduleName },
    }).then((response) => response.data);
  }
);

export const addModule = createAsyncThunk(
  "course/addModule",
  async ({ programmeId, moduleName }, { getState }) => {
    const state = getState();
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/modules/`,
      headers: {
        Authorization: state.user.token,
      },
      data: { name: moduleName },
    }).then((response) => response.data);
  }
);

export const deleteModule = createAsyncThunk(
  "course/deleteModule",
  async ({ programmeId, moduleId }, { getState }) => {
    const state = getState();
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}/programmes/${programmeId}/modules/${moduleId}/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const courseSlice = createSlice({
  name: "course",
  initialState: { loading: true, courseInfo: {}, modules: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourseDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCourseDetail.fulfilled, (state, action) => {
      state.loading = false;

      const { courseInfo, modules } = action.payload;
      state.courseInfo = courseInfo;
      state.modules = modules;
    });

    builder.addCase(addObjective.fulfilled, (state, action) => {
      const { moduleId, objective } = action.payload;
      state.modules
        .find((module) => module.id === moduleId)
        .objectives.push(objective);
    });

    builder.addCase(modifyObjective.fulfilled, (state, action) => {
      const { moduleId, objective } = action.payload;
      const newObjective = state.modules
        .find((module) => module.id === moduleId)
        .objectives.map((obj) => (obj.id === objective.id ? objective : obj));
      state.modules.find((module) => module.id === moduleId).objectives =
        newObjective;
    });

    builder.addCase(deleteObjective.fulfilled, (state, action) => {
      const { moduleId, objectiveId } = action.meta.arg;
      const newObjectives = state.modules
        .find((module) => module.id === moduleId)
        .objectives.filter((objective) => objective.id !== objectiveId);
      state.modules.find((module) => module.id === moduleId).objectives =
        newObjectives;
    });

    builder.addCase(modifyModule.fulfilled, (state, action) => {
      const { id, name } = action.payload;
      state.modules.find((module) => module.id === id).name = name;
    });

    builder.addCase(addModule.fulfilled, (state, action) => {
      const module = action.payload;
      module.objectives = [];
      state.modules.push(module);
    });

    builder.addCase(deleteModule.fulfilled, (state, action) => {
      state.modules = state.modules.filter(
        (module) => module.id !== action.meta.arg.moduleId
      );
    });
  },
});

export default courseSlice.reducer;
