import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { getProfilebyId } from "../../app/entities/profiles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { getUserbyId } from "../../app/entities/users";
import Joi from "joi-browser";
import { validate, validateProperty } from "../../validator/common";
import { updateUser } from "../../app/entities/users";
import { updateProfile } from "../../app/entities/profiles";
import { lte } from "lodash";

export default function GeneralProfileEditing({ userId }) {
  const profile = useSelector(getProfilebyId(userId));
  const user = useSelector(getUserbyId(userId));
  const dispatch = useDispatch();

  const [openProfileEditing, setProfileEditing] = React.useState(false);
  const [modifiedProfile, setModifiedProfile] = React.useState({});
  const [
    openProfileEditingComfirmWarning,
    setOpenProfileEditingComfirmWarning,
  ] = React.useState(false);
  const [initProfile, setInitProfile] = React.useState({});

  React.useEffect(() => {
    if (profile && user) {
      const obj = {
        first_name: user.first_name,
        last_name: user.last_name,
        date_of_birth: profile.date_of_birth,
        gender: profile.gender,
        phone: profile.phone,
        email: user.email,
        primary_contact_name: profile.primary_contact_name,
        primary_contact_relationship: profile.primary_contact_relationship,
        primary_contact_number: profile.primary_contact_number,
        secondary_contact_name: profile.secondary_contact_name,
        secondary_contact_relationship: profile.secondary_contact_relationship,
        secondary_contact_number: profile.secondary_contact_number,
        remark: profile.remark,
      };
      setModifiedProfile(obj);
      setInitProfile(obj);
    }
  }, [profile, user]);

  const { is_teacher, is_superuser } = useSelector((state) => state.user);

  const [errors, setErrors] = React.useState({});

  const handleDateOfBirthChange = () => {};

  const schema = {
    first_name: Joi.string().empty("").alphanum(),
    last_name: Joi.string().empty("").alphanum(),
    phone: Joi.string().empty(""),
    date_of_birth: null,
    gender: null,
    email: Joi.string().empty("").email(),
    primary_contact_name: Joi.string().empty("").alphanum(),
    primary_contact_relationship: Joi.string().empty("").alphanum(),
    primary_contact_number: Joi.string().empty(""),
    secondary_contact_name: Joi.string().empty("").alphanum(),
    secondary_contact_relationship: Joi.string().empty("").alphanum(),
    secondary_contact_number: Joi.string().empty(""),
    remark: Joi.string().empty(""),
  };

  const handleTextChange = (value, field) => {
    const newValues = { ...modifiedProfile };
    const newErrors = { ...errors };

    setModifiedProfile({ ...newValues, [field]: value });
    // handle validate
    const errorMessage = validateProperty({ field, value }, schema);
    if (errorMessage) {
      newErrors[field] = errorMessage;
    } else {
      delete newErrors[field];
    }
    setErrors(newErrors);
  };

  const editingTextFieldListAfterDOB =
    profile && user
      ? [
          {
            label: "Phone",
            field: "phone",
            defaultValue: profile.phone,
            helperText: "",
            style: { marginBottom: "20px" },
            error: false,
            multiline: false,
            minRows: null,
            maxRows: null,
          },
          {
            label: "Email",
            field: "email",
            defaultValue: user.email,
            helperText: "",
            style: { marginBottom: "20px" },
            error: false,
            multiline: false,
            minRows: null,
            maxRows: null,
          },
          {
            label: "Primary Contact Name",
            field: "primary_contact_name",
            inputRef: null,
            defaultValue: profile.primary_contact_name,
            helperText: "",
            style: { marginBottom: "20px" },
            error: false,
            onBlur: null,
            multiline: false,
            minRows: null,
            maxRows: null,
          },
          {
            label: "Primary Contact Relationship",
            field: "primary_contact_relationship",
            inputRef: null,
            defaultValue: profile.primary_contact_relationship,
            helperText: "",
            style: { marginBottom: "20px" },
            error: false,
            onBlur: null,
            multiline: false,
            minRows: null,
            maxRows: null,
          },
          {
            label: "Primary Contact Number",
            field: "primary_contact_number",
            inputRef: null,
            defaultValue: profile.primary_contact_number,
            helperText: "",
            style: { marginBottom: "20px" },
            error: false,
            onBlur: null,
            multiline: false,
            minRows: null,
            maxRows: null,
          },
          {
            label: "Secondary Contact Name",
            field: "secondary_contact_name",
            inputRef: null,
            defaultValue: profile.secondary_contact_name,
            helperText: "",
            style: { marginBottom: "20px" },
            error: false,
            onBlur: null,
            multiline: false,
            minRows: null,
            maxRows: null,
          },
          {
            label: "Secondary Contact Relationship",
            field: "secondary_contact_relationship",
            inputRef: null,
            defaultValue: profile.secondary_contact_relationship,
            helperText: "",
            style: { marginBottom: "20px" },
            error: false,
            onBlur: null,
            multiline: false,
            minRows: null,
            maxRows: null,
          },
          {
            label: "Secondary Contact Number",
            field: "secondary_contact_number",
            inputRef: null,
            defaultValue: profile.secondary_contact_number,
            helperText: "",
            style: { marginBottom: "20px" },
            error: false,
            onBlur: null,
            multiline: false,
            minRows: null,
            maxRows: null,
          },
          is_teacher || is_superuser
            ? {
                label: "",
                field: "remark",
                inputRef: null,
                defaultValue: profile.remark,
                helperText: "",
                style: { marginBottom: "20px" },
                error: false,
                onBlur: null,
                multiline: true,
                minRows: 5,
                maxRows: 10,
              }
            : null,
        ]
      : null;

  const handleCloseProfileEditing = () => {
    setProfileEditing(false);
  };
  return (
    <>
      {profile && user && editingTextFieldListAfterDOB ? (
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setProfileEditing(true);
            }}
            sx={{ marginLeft: "10px", marginTop: "20px", marginBottom: "10px" }}
          >
            Edit
          </Button>
          <Dialog
            open={openProfileEditing}
            onClose={() => {
              setProfileEditing(false);
              if (profile && user) {
                const obj = {
                  first_name: user.first_name,
                  last_name: user.last_name,
                  date_of_birth: profile.date_of_birth,
                  gender: profile.gender,
                  phone: profile.phone,
                  email: user.email,
                  primary_contact_name: profile.primary_contact_name,
                  primary_contact_relationship:
                    profile.primary_contact_relationship,
                  primary_contact_number: profile.primary_contact_number,
                  secondary_contact_name: profile.secondary_contact_name,
                  secondary_contact_relationship:
                    profile.secondary_contact_relationship,
                  secondary_contact_number: profile.secondary_contact_number,
                  remark: profile.remark,
                };
                setModifiedProfile(obj);
                setInitProfile(obj);
                setErrors({});
              }
            }}
            fullWidth={true}
            BackdropProps={{
              style: { backgroundColor: "gray", opacity: 0.95 },
            }}
          >
            <DialogTitle
              variant="h5"
              sx={{ marginBottom: "20px", marginTop: "20px" }}
            >
              {"Profile Edit"}
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "20px",
                  gap: "20px",
                }}
              >
                <TextField
                  key="first_name"
                  error={Boolean(errors["first_name"])}
                  label="First Name"
                  value={modifiedProfile["first_name"] || ""}
                  helperText={errors["first_name"]}
                  onChange={(event) => {
                    handleTextChange(event.target.value, "first_name");
                  }}
                />
                <TextField
                  key="last_name"
                  error={Boolean(errors["last_name"])}
                  value={modifiedProfile["last_name"] || ""}
                  helperText={errors["last_name"]}
                  label="Last Name"
                  onChange={(event) => {
                    handleTextChange(event.target.value, "last_name");
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    key={"date_of_birth"}
                    inputFormat="dd/MM/yyyy"
                    label={"Date of Birth"}
                    onChange={(newDate) => {
                      handleTextChange(newDate, "date_of_birth");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    value={modifiedProfile["date_of_birth"] || Date.now()}
                  />
                </LocalizationProvider>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={modifiedProfile["gender"] || "F"}
                    onChange={(event) => {
                      handleTextChange(event.target.value, "gender");
                    }}
                  >
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="Male"
                    />
                  </RadioGroup>
                </FormControl>
                {editingTextFieldListAfterDOB.map(
                  ({ label, field, multiline, minRows, maxRows }, index) => (
                    <TextField
                      key={field}
                      error={Boolean(errors[field])}
                      value={modifiedProfile[field] || ""}
                      label={label}
                      helperText={errors[field]}
                      multiline={multiline}
                      maxRows={maxRows}
                      minRows={minRows}
                      onChange={(event) => {
                        handleTextChange(event.target.value, field);
                      }}
                    />
                  )
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                disabled={
                  initProfile === modifiedProfile ||
                  Object.keys(errors).length > 0
                }
                onClick={() => {
                  setOpenProfileEditingComfirmWarning(true);
                }}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleCloseProfileEditing();
                  if (profile && user) {
                    const obj = {
                      first_name: user.first_name,
                      last_name: user.last_name,
                      date_of_birth: profile.date_of_birth,
                      gender: profile.gender,
                      phone: profile.phone,
                      email: user.email,
                      primary_contact_name: profile.primary_contact_name,
                      primary_contact_relationship:
                        profile.primary_contact_relationship,
                      primary_contact_number: profile.primary_contact_number,
                      secondary_contact_name: profile.secondary_contact_name,
                      secondary_contact_relationship:
                        profile.secondary_contact_relationship,
                      secondary_contact_number:
                        profile.secondary_contact_number,
                      remark: profile.remark,
                    };
                    setModifiedProfile(obj);
                    setInitProfile(obj);
                    setErrors({});
                  }
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openProfileEditingComfirmWarning}
            onClose={() => {
              setOpenProfileEditingComfirmWarning(false);
            }}
          >
            <DialogContent>{"Are you sure about that?"}</DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  const preUser = {
                    id: userId,
                    username: user.username,
                    first_name: user.firstName,
                    last_name: user.lastName,
                    email: user.email,
                    is_superuser: user.is_superuser,
                    is_teacher: user.is_teacher,
                    is_student: user.is_student,
                  };
                  const newUser = {
                    id: userId,
                    username: user.username,
                    first_name: modifiedProfile["first_name"],
                    last_name: modifiedProfile["last_name"],
                    email: modifiedProfile["email"],
                    is_superuser: user.is_superuser,
                    is_teacher: user.is_teacher,
                    is_student: user.is_student,
                  };
                  dispatch(updateUser(preUser, newUser));
                  const preProfile = {
                    id: userId,
                    date_of_birth: profile.date_of_birth,
                    gender: profile.gender,
                    phone: profile.phone,
                    primary_contact_name: profile.primary_contact_name,
                    primary_contact_number: profile.primary_contact_number,
                    primary_contact_relationship:
                      profile.primary_contact_relationship,
                    secondary_contact_name: profile.secondary_contact_name,
                    secondary_contact_number: profile.secondary_contact_number,
                    secondary_contact_relationship:
                      profile.secondary_contact_relationship,
                    remark: profile.remark,
                  };
                  const newProfile = {
                    id: userId,
                    date_of_birth: modifiedProfile.date_of_birth,
                    gender: modifiedProfile.gender,
                    phone: modifiedProfile.phone,
                    primary_contact_name: modifiedProfile.primary_contact_name,
                    primary_contact_number:
                      modifiedProfile.primary_contact_number,
                    primary_contact_relationship:
                      modifiedProfile.primary_contact_relationship,
                    secondary_contact_name:
                      modifiedProfile.secondary_contact_name,
                    secondary_contact_number:
                      modifiedProfile.secondary_contact_number,
                    secondary_contact_relationship:
                      modifiedProfile.secondary_contact_relationship,
                    remark: modifiedProfile.remark,
                  };
                  dispatch(updateProfile(preProfile, newProfile));
                  setOpenProfileEditingComfirmWarning(false);
                  handleCloseProfileEditing();
                  setErrors({});
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenProfileEditingComfirmWarning(false);
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    </>
  );
}
