import * as React from "react";
import { TextField, Box, Button, InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export default function BasicAutocomplete({
  options,
  label,
  multiple,
  selectedValues,
  handleChange,
  variant,
  displayLabel,
  flex,
  extraEndAdornment,
  disabled,
  otherStyle,
  required,
}) {
  return (
    <Autocomplete
      disabled={disabled}
      multiple={multiple}
      filterSelectedOptions
      options={options}
      value={selectedValues}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) =>
        value.id ? option.id === value.id : option === value
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: {
              ...params.InputProps.endAdornment,
              props: {
                ...params.InputProps.endAdornment.props,
                children: [
                  <InputAdornment
                    key={Math.random()}
                    position="end"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    {extraEndAdornment}
                    {params.InputProps.endAdornment.props.children}
                  </InputAdornment>,
                ],
              },
            },
          }}
          disabled={disabled}
        />
      )}
      getOptionLabel={
        displayLabel
          ? (option) => displayLabel(option)
          : (option) => option.name
      }
      sx={{ flex, ...otherStyle }}
    />
  );
}
