import * as React from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  Divider,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  addSchoolStudying,
  updateSchoolStudying,
  deleteSchoolStudying,
} from "./studentSlice";

export default function StudentProfileEditingDialog(
  openStudentProfileEditingDialog,
  setOpenStudentProfileEditingDialog,
  setOpenSchoolEditingDialog,
  modifiedSchoolsList,
  dispatch,
  studentUserId,
  schoolsList,
  setModifiedSchoolsList
) {
  return (
    <Dialog
      open={openStudentProfileEditingDialog}
      onClose={() => setOpenStudentProfileEditingDialog(false)}
      fullWidth={true}
    >
      <DialogTitle
        variant="h5"
        sx={{ marginBottom: "20px", marginTop: "20px" }}
      >
        {"Student Profile Edit"}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "20px",
            gap: "20px",
          }}
        >
          {modifiedSchoolsList.map((schoolstudying, index) => (
            <React.Fragment key={Math.random()}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{`schools ${index + 1}`}</Typography>
                <IconButton
                  aria-label="remove"
                  onClick={() => {
                    dispatch(
                      deleteSchoolStudying({
                        studentId: studentUserId,
                        school_itemsId: schoolstudying.id,
                      })
                    );
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
              <Autocomplete
                id="school"
                options={schoolsList}
                getOptionLabel={(option) => `${option.name} (${option.id})`}
                groupBy={(option) => {
                  if (option.type === "K") return "Kindergarten";
                  else if (option.type === "P") return "Primary School";
                  else if (option.type === "S") return "Secondary School";
                  else return "University";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"school"}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: {
                        ...params.InputProps.endAdornment,
                        props: {
                          ...params.InputProps.endAdornment.props,
                          children: [
                            <InputAdornment
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                              key={Math.random()}
                              position="end"
                            >
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setOpenSchoolEditingDialog(true);
                                }}
                              >
                                <AddIcon />
                              </IconButton>

                              {params.InputProps.endAdornment.props.children}
                            </InputAdornment>,
                          ],
                        },
                      },
                    }}
                  />
                )}
                value={schoolstudying.school}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  const schoolId = value ? value.id : null;
                  const newData = {
                    start_date: schoolstudying.start_date,
                    end_date: schoolstudying.end_date,
                    school: schoolId,
                  };
                  dispatch(
                    updateSchoolStudying({
                      studentId: studentUserId,
                      school_itemsId: schoolstudying.id,
                      school_object: value,
                      data: newData,
                    })
                  );
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  key={"start_date"}
                  inputFormat="dd/MM/yyyy"
                  label={"Start Date"}
                  onChange={(newDate) => {
                    const newData = {
                      start_date: newDate.toISOString().split("T")[0],
                      end_date: schoolstudying.end_date,
                      school: schoolstudying.school
                        ? schoolstudying.school.id
                        : null,
                    };
                    dispatch(
                      updateSchoolStudying({
                        studentId: studentUserId,
                        school_itemsId: schoolstudying.id,
                        school_object: schoolstudying.school,
                        data: newData,
                      })
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  value={schoolstudying.start_date}
                />
              </LocalizationProvider>
              <Autocomplete
                id="end_time"
                options={["Present", "Graduated"]}
                value={schoolstudying.selectEndTimeOption}
                renderInput={(params) => (
                  <TextField {...params} label="schools" />
                )}
                onChange={(event, value) => {
                  schoolstudying.selectEndTimeOption = value;
                  if (value === "Present" || value === null) {
                    const newData = {
                      start_date: schoolstudying.start_date,
                      end_date: null,
                      school: schoolstudying.school
                        ? schoolstudying.school.id
                        : null,
                    };
                    dispatch(
                      updateSchoolStudying({
                        studentId: studentUserId,
                        school_itemsId: schoolstudying.id,
                        school_object: schoolstudying.school,
                        data: newData,
                      })
                    );
                  }
                  setModifiedSchoolsList([...modifiedSchoolsList]);
                }}
              />
              {schoolstudying.selectEndTimeOption === "Graduated" && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    key={"end_date"}
                    inputFormat="dd/MM/yyyy"
                    label={"End Date"}
                    onChange={(newDate) => {
                      const newData = {
                        start_date: schoolstudying.start_date,
                        end_date: newDate.toISOString().split("T")[0],
                        school: schoolstudying.school
                          ? schoolstudying.school.id
                          : null,
                      };
                      dispatch(
                        updateSchoolStudying({
                          studentId: studentUserId,
                          school_itemsId: schoolstudying.id,
                          school_object: schoolstudying.school,
                          data: newData,
                        })
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    value={schoolstudying.end_date}
                  />
                </LocalizationProvider>
              )}
              <Divider light />
            </React.Fragment>
          ))}
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <IconButton
              aria-label="add"
              onClick={() => {
                dispatch(
                  addSchoolStudying({
                    studentId: studentUserId,
                    data: {},
                  })
                );
              }}
            >
              <AddIcon />
            </IconButton>
            <Button
              variant="contained"
              onClick={() => {
                setOpenStudentProfileEditingDialog(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
