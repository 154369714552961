import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Box,
  Paper,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  retrieveProfile,
  getProfilebyId,
  uploadPhotoById,
} from "../../app/entities/profiles";
import { retrieveUser, getUserbyId } from "../../app/entities/users";
import ImageCrop from "../../components/ImageCrop/ImageCrop";

export default function GeneralProfile({ userId }) {
  const dispatch = useDispatch();
  const { is_teacher, is_superuser } = useSelector((state) => state.user);

  React.useEffect(() => {
    dispatch(retrieveProfile(userId));
    dispatch(retrieveUser(userId));
  }, []);

  // const [width, setWidth] = React.useState(window.innerWidth);
  // const breakpoint = 1100;

  // React.useEffect(() => {
  //   window.addEventListener("resize", () => setWidth(window.innerWidth));
  // }, [width]);

  const profile = useSelector(getProfilebyId(userId));
  const user = useSelector(getUserbyId(userId));
  const ImageRef = React.useRef();
  const [photoUpload, setPhotoUpload] = React.useState("");
  const [openImageDialog, setOpenImageDialog] = React.useState(false);

  const profileDetails =
    profile && user
      ? [
          {
            name: "Name",
            detail:
              user.first_name &&
              user.last_name &&
              user.first_name.length > 0 &&
              user.last_name.length > 0
                ? user.first_name + " " + user.last_name
                : "N/A",
          },
          {
            name: "User Name",
            detail:
              user.username && user.username.length > 0 ? user.username : "N/A",
          },
          {
            name: "Date of Birth",
            detail:
              profile.date_of_birth && profile.date_of_birth.length > 0
                ? profile.date_of_birth
                : "N/A",
          },
          {
            name: "Gender",
            detail:
              profile.gender && profile.gender.length > 0
                ? profile.gender
                : "N/A",
          },
          {
            name: "Phone",
            detail:
              profile.phone && profile.phone.length > 0 ? profile.phone : "N/A",
          },
          {
            name: "Email",
            detail: user.email && user.email.length > 0 ? user.email : "N/A",
          },
          {
            name: "Primary Contact Name",
            detail:
              profile.primary_contact_name &&
              profile.primary_contact_name.length > 0
                ? profile.primary_contact_name
                : "N/A",
          },
          {
            name: "Primary Contact Relationship",
            detail:
              profile.primary_contact_relationship &&
              profile.primary_contact_relationship.length > 0
                ? profile.primary_contact_relationship
                : "N/A",
          },
          {
            name: "Primary Contact Number",
            detail:
              profile.primary_contact_number &&
              profile.primary_contact_number.length > 0
                ? profile.primary_contact_number
                : "N/A",
          },
          {
            name: "Secondary Contact Name",
            detail:
              profile.secondary_contact_name &&
              profile.secondary_contact_name.length > 0
                ? profile.secondary_contact_name
                : "N/A",
          },
          {
            name: "Secondary Contact Relationship",
            detail:
              profile.secondary_contact_relationship &&
              profile.secondary_contact_relationship.length > 0
                ? profile.secondary_contact_relationship
                : "N/A",
          },
          {
            name: "Secondary Contact Number",
            detail:
              profile.secondary_contact_number &&
              profile.secondary_contact_number.length > 0
                ? profile.secondary_contact_number
                : "N/A",
          },
          {
            name: "Remark",
            detail: profile.remark ? profile.remark : "",
          },
        ]
      : null;

  return (
    <>
      {profile && user ? (
        <Box>
          <Typography variant="h5" textAlign="center">
            Profile
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Table>
                <TableBody>
                  {profileDetails.map((item) => {
                    if (item.name !== "Remark") {
                      return (
                        <TableRow key={Math.random()}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.detail}</TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow key={Math.random()}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                whiteSpace: "pre-line",
                                overflow: "auto",
                                maxHeight: "200px",
                              }}
                            >
                              {item.detail}
                            </Typography>
                            {/*<TextField
                              key={"remark"}
                              value={item.detail}
                              multiline={true}
                              maxRows={10}
                              minRows={5}
                              disabled={true}
                          />*/}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </Box>

            <Box>
              <Box sx={{ display: "inline-block" }}>
                {profile.photo ? (
                  <>
                    {/*<img
                    style={{
                      maxWidth: "400px",
                      maxHeight: "400px",
                      marginTop: "10px",
                      display: "block",
                    }}
                    alt=""
                    src={profile.photo}
                  />*/}
                    <Avatar
                      src={profile.photo}
                      sx={{ width: 256, height: 256 }}
                    />
                  </>
                ) : (
                  <div>{"No Photo Available"}</div>
                )}
                <Button
                  variant="contained"
                  component="label"
                  sx={{ marginTop: "20px", display: "block" }}
                  onClick={() => {
                    setOpenImageDialog(true);
                  }}
                >
                  {`Change Photo`}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
      {profile && (
        <Dialog
          open={openImageDialog}
          onClose={() => {
            setOpenImageDialog(false);
          }}
          fullWidth
        >
          <DialogContent>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Avatar src={profile.photo} sx={{ width: 256, height: 256 }} />

                <ImageCrop
                  handleSubmit={(file) => {
                    const formData = new FormData();
                    formData.append("photo", file);
                    dispatch(uploadPhotoById(userId, formData));
                    setOpenImageDialog(false);
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
