import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { pipe } from "lodash/fp";
import {
  annontate,
  extract_field,
  format_action,
  format_datetime,
} from "../normalize";

const endpoint = endpoints["moduleHistory"];

const slice = createSlice({
  name: "moduleHistory",
  initialState: {
    data: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    moduleHistoryListRequest: (moduleHistory, action) => {
      moduleHistory.loading = true;
    },
    moduleHistoryListReceived: (moduleHistory, { payload }) => {
      moduleHistory.data = payload;
      moduleHistory.loading = false;
    },
  },
});

const { moduleHistoryListRequest, moduleHistoryListReceived } = slice.actions;

// action
export const getModuleHistoryList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: moduleHistoryListRequest.type,
    onSuccess: moduleHistoryListReceived.type,
  });

// selector
export const getModuleHistory = (state) => state.entities.moduleHistory.data;

export const getModuleHistoryDisplay = (state) => {
  const history_display = pipe(
    annontate("username", extract_field("history_user", "username")),
    annontate("is_student", extract_field("history_user", "is_student")),
    annontate("is_teacher", extract_field("history_user", "is_teacher")),
    annontate("history_date", format_datetime("history_date")),
    annontate("history_type", format_action("history_type")),
    annontate("programme_name", (item) =>
      item.programme
        ? `${item.programme.category_name} - ${item.programme.name} - level ${item.programme.level}`
        : "Programme Deleted"
    )
  );
  return history_display(state.entities.moduleHistory.data);
};

export const getModuleHistoryLoadingStatus = (state) =>
  state.entities.moduleHistory.loading;

export default slice.reducer;
