import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  CardContent,
  Badge,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { giveStamp, removeStamp } from "./lessonSlice";

export default function StampDialog(
  open,
  setOpen,
  selectedStamps,
  stamps,
  setSelectedStamps,
  studentStamps,
  student,
  studentComment
) {
  const dispatch = useDispatch();
  const [filterfield, setFilterfield] = React.useState("");

  const is_superuser = useSelector((state) => state.user.is_superuser);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSelectedStamps([...studentStamps]);
        setOpen(false);
      }}
      BackdropProps={{
        style: { backgroundColor: "gray", opacity: 0.95 },
      }}
      fullWidth
    >
      <DialogTitle>
        <Badge badgeContent={selectedStamps.length} color="primary">
          <Typography>Stamps</Typography>
        </Badge>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <TextField
          id="standard-required"
          label="Name Filter"
          value={filterfield}
          variant="standard"
          onChange={(event) => {
            setFilterfield(event.target.value);
          }}
        />
        <Box>
          {stamps
            .filter((stamp) => !filterfield || stamp.name.includes(filterfield))
            .map((stamp) => {
              const cardStyle = selectedStamps.find(
                (selectedStamp) => selectedStamp.id === stamp.id
              )
                ? {
                    width: "30%",
                    display: "inline-block",
                    margin: "5px",
                    borderStyle: "solid",
                    boxSizing: "border-box",
                  }
                : {
                    width: "30%",
                    display: "inline-block",
                    margin: "5px",
                    boxSizing: "border-box",
                  };
              return (
                <Card
                  key={Math.random()}
                  sx={cardStyle}
                  onClick={() => {
                    if (!is_superuser) {
                      if (
                        selectedStamps.find(
                          (selectedStamp) => selectedStamp.id === stamp.id
                        )
                      ) {
                        setSelectedStamps(
                          selectedStamps.filter(
                            (selectedStamp) => selectedStamp.id !== stamp.id
                          )
                        );
                      } else {
                        setSelectedStamps([...selectedStamps, stamp]);
                      }
                    }
                  }}
                >
                  <Avatar
                    alt=""
                    src={stamp.image}
                    sx={{ width: "100%", height: "auto" }}
                  />
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {stamp.name}
                  </CardContent>
                </Card>
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelectedStamps([...studentStamps]);
            setOpen(false);
          }}
        >
          CANCEL
        </Button>
        {!is_superuser && (
          <Button
            autoFocus
            onClick={() => {
              //check any removed stamp(s)
              let removedBagItems = [];
              studentStamps.forEach((stamp) => {
                if (
                  !selectedStamps.find(
                    (selectedStamp) => selectedStamp.id === stamp.id
                  )
                ) {
                  removedBagItems.push(
                    studentComment.bag_items.find(
                      (bagItem) => bagItem.stamp.id === stamp.id
                    ).id
                  );
                }
              });

              removedBagItems.forEach((bagItemId) =>
                dispatch(
                  removeStamp({
                    commentId: studentComment.id,
                    studentId: student.id,
                    bagItemId,
                  })
                )
              );

              //add stamps
              const studentStampsIds = studentStamps.map((stamp) => stamp.id);
              const selectedStampsIds = selectedStamps.map((stamp) => stamp.id);
              const addedStampsIds = selectedStampsIds.filter(
                (selectedStampsId) =>
                  !studentStampsIds.includes(selectedStampsId)
              );

              addedStampsIds.forEach((stampId) => {
                let stamp = selectedStamps.find(
                  (stamp) => stamp.id === stampId
                );
                dispatch(
                  giveStamp({
                    studentId: student.id,
                    stamp,
                    stampId,
                    commentId: studentComment.id,
                  })
                );
              });

              setOpen(false);
            }}
          >
            ADD
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
