import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMySettings } from "../../user/userSlice";
import { ViewSwitcher } from "@devexpress/dx-react-scheduler-material-ui";

export default function Switcher({ onChange, ...restProps }) {
  const dispatch = useDispatch();
  const mySettings = useSelector((state) => state.user.mySettings);

  const switchView = (nextViewName) => {
    dispatch(updateMySettings({ ...mySettings, view: nextViewName }));
    onChange(nextViewName);
  };

  return <ViewSwitcher.Switcher onChange={switchView} {...restProps} />;
}
