import React, { forwardRef } from "react";
import AddDialog from "./AddDialog";
import Joi from "joi-browser";
import { createProgramme } from "../../../../app/entities/programmes";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../../app/entities/categories";
import CategoryAddDialog from "./CategoryAddDialog";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { getCategoryList } from "../../../../app/entities/categories";

function ProgrammeAddDialog(props, ref) {
  const { openDialog, onClose } = props;
  const dispatch = useDispatch();
  const categoryChoices = useSelector(getCategories);
  const [values, setValues] = React.useState({});

  const [openCategoryAddDialog, setOpenCategoryAddDialog] =
    React.useState(false);

  React.useEffect(() => {
    dispatch(getCategoryList());
  }, [dispatch]);

  const columns = [
    {
      render_type: "autocomplete",
      field: "category",
      headerName: "Category",
      choices: categoryChoices,
      required: true,
      displayLabel: (obj) => obj.name,

      extraEndAdornment: (
        <IconButton
          color="primary"
          onClick={() => {
            setOpenCategoryAddDialog(true);
          }}
        >
          <AddIcon />
        </IconButton>
      ),
      otherStyle: { marginTop: 1 },
    },
    {
      field: "name",
      headerName: "Course Name",
      required: true,
      flex: 1,
      required: true,
      focus: true,
    },
    {
      field: "level",
      headerName: "Course Level",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];

  const schema = {
    category: Joi.required(),
    name: Joi.string().required(),
    level: Joi.string().empty(""),
    description: Joi.string().empty(""),
  };

  return (
    <>
      <AddDialog
        title={"Programme Addition"}
        ref={ref}
        openDialog={openDialog}
        onClose={onClose}
        columns={columns}
        schema={schema}
        onSubmit={(value) => {
          console.log(value);

          const data = {
            name: value.name,
            category: value.category.id,
            level: value.level,
            description: value.description,
          };
          dispatch(createProgramme(data));
          onClose();
        }}
        values={values}
        setValues={setValues}
      />
      <CategoryAddDialog
        openDialog={openCategoryAddDialog}
        onClose={() => {
          setOpenCategoryAddDialog(false);
          onClose();
        }}
      />
    </>
  );
}

export default forwardRef(ProgrammeAddDialog);
