import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOverlappedLessons } from "../lessonsSlice";
import { WeekView } from "@devexpress/dx-react-scheduler-material-ui";

export default function TimeTableCell({
  onDoubleClick,
  startDate,
  endDate,
  ...restProps
}) {
  const dispatch = useDispatch();
  const isSuperUser = useSelector((state) => state.user.is_superuser);

  const openAppointmentForm = (event) => {
    dispatch(
      getOverlappedLessons({
        selectedStartTime: startDate,
        selectedEndTime: endDate,
      })
    );
    onDoubleClick(event);
  };

  return (
    <WeekView.TimeTableCell
      startDate={startDate}
      endDate={endDate}
      onDoubleClick={isSuperUser ? openAppointmentForm : undefined}
      {...restProps}
    />
  );
}
