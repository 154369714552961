import React, { useState, useMemo, useEffect } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import ClassIcon from "@mui/icons-material/Class";
import AppsIcon from "@mui/icons-material/Apps";
import { Divider } from "@mui/material";
import CategoryCreateDialog from "../../../../components/CreateDialog/CategoryCreateDialog";
import ProgrammeCreateDialog from "../../../../components/CreateDialog/ProgrammeCreateDialog";
import BucketDialog from "../../../../components/EditDialog/BucketDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryList,
  createCategory,
  deleteCategorybyId,
  getCategories,
  updateCategory,
} from "../../../../app/entities/categories";

export default function MenuHeader() {
  const categories = useSelector(getCategories);
  const dispatch = useDispatch();
  const [openCategory, setOpenCategory] = useState(false);
  const [openProgramme, setOpenProgramme] = useState(false);

  const handleAdd = (name) => {
    dispatch(createCategory({ name }));
  };

  const handleModify = (preCategory, category) => {
    console.log(preCategory, category);
    dispatch(updateCategory(preCategory, category));
  };

  const handleDelete = (category) => {
    dispatch(deleteCategorybyId(category.id));
  };

  const modifiedCategory = useMemo(
    () =>
      categories.map((category) => ({
        ...category,
        deleteDisabled: category.programme_count !== 0,
      })),
    [categories]
  );

  useEffect(() => {
    if (openCategory) dispatch(getCategoryList());
  }, [openCategory]);

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setOpenCategory(true)}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Manage Category" />
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setOpenProgramme(true)}>
          <ListItemIcon>
            <ClassIcon />
          </ListItemIcon>
          <ListItemText primary="Create Course" />
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <Divider />
      {/* <CategoryCreateDialog open={openCategory} setOpen={setOpenCategory} /> */}
      <ProgrammeCreateDialog open={openProgramme} setOpen={setOpenProgramme} />
      <BucketDialog
        openDialog={openCategory}
        setOpenDialog={setOpenCategory}
        dialogTitle="Manage Category"
        textFieldLabel="Category name"
        items={modifiedCategory}
        displayAttribute="name"
        handleAdd={handleAdd}
        handleModify={handleModify}
        handleDelete={handleDelete}
      />
    </>
  );
}
