import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const axios = require("axios");

export const getTeacherDetail = createAsyncThunk(
  "teacher/getTeacherDetail",
  async ({ teacherUserId }, { getState }) => {
    const state = getState();

    return await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/teachers/${teacherUserId}/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const updateTeacherDetail = createAsyncThunk(
  "teacher/updateTeacherDetail",
  async ({ teacherUserId, data }, { getState }) => {
    const state = getState();
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_URL}/teachers/${teacherUserId}/`,
      headers: {
        Authorization: state.user.token,
      },
      data: data,
    }).then((response) => response.data);
  }
);

export const teacherSlice = createSlice({
  name: "teacher",
  initialState: { isLoading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeacherDetail.fulfilled, (state, action) => {
      state.teacher = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTeacherDetail.pending, (state, action) => {
      state.teacher = action.payload;
      state.isLoading = true;
    });
  },
});

export default teacherSlice.reducer;
