import React, { forwardRef } from "react";
import AddDialog from "./AddDialog";
import Joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryList,
  getCategories,
} from "../../../../app/entities/categories";
import { getUserList, getUsers } from "../../../../app/entities/users";
import {
  getProgrammeList,
  getProgrammes,
  updateProgramme,
} from "../../../../app/entities/programmes";
import { toast } from "react-toastify";

function TeacherAddDialog(props, ref) {
  const { openDialog, onClose } = props;
  const dispatch = useDispatch();
  const categoryList = useSelector(getCategories);
  const programmeList = useSelector(getProgrammes);
  const users = useSelector(getUsers);

  const [values, setValues] = React.useState({});

  const [categoryChoices, setCategoryChoices] = React.useState([]);
  const [programmeChoices, setProgrammeChoices] = React.useState([]);
  const [levelChoices, setLevelChoices] = React.useState([]);

  const [schema, setSchema] = React.useState({
    category: Joi.string().required(),
    courseName: Joi.string().required(),
    level: Joi.string(),
  });

  const students = React.useMemo(
    () => users.filter((user) => user.is_student),
    [users]
  );
  const teachers = React.useMemo(
    () => users.filter((user) => user.is_teacher),
    [users]
  );

  // filter the choice when value is selected
  React.useEffect(() => {
    setCategoryChoices([
      ...new Set(
        programmeList
          .filter((programme) => {
            return (
              (!values.courseName || values.courseName === programme.name) &&
              (!values.level || values.level === programme.level)
            );
          })
          .map((programme) => programme.category_name)
      ),
    ]);

    setProgrammeChoices([
      ...new Set(
        programmeList
          .filter((programme) => {
            return (
              (!values.category ||
                values.category === programme.category_name) &&
              (!values.level || values.level === programme.level)
            );
          })
          .map((programme) => programme.name)
      ),
    ]);

    setLevelChoices([
      ...new Set(
        programmeList
          .filter((programme) => {
            return (
              programme.level &&
              (!values.category ||
                values.category === programme.category_name) &&
              (!values.courseName || values.courseName === programme.name)
            );
          })
          .map((programme) => programme.level)
      ),
    ]);
  }, [values, programmeList, categoryList]);

  // get data from server
  React.useEffect(() => {
    dispatch(getProgrammeList());
    dispatch(getCategoryList());
    dispatch(getUserList());
  }, [dispatch]);

  const columns = [
    {
      render_type: "autocomplete",
      field: "category",
      headerName: "Category",
      choices: categoryChoices,
      required: true,
      displayLabel: (obj) => obj,
      otherStyle: { marginTop: 1 },
    },
    {
      render_type: "autocomplete",
      field: "courseName",
      headerName: "Course Name",
      choices: programmeChoices,
      required: true,
      displayLabel: (obj) => obj,
    },
  ];

  // add level autocomplete field when the chosen porgramme has level
  if (levelChoices.length > 0) {
    columns.push({
      render_type: "autocomplete",
      field: "level",
      headerName: "Course Level",
      choices: levelChoices,
      required: true,
      displayLabel: (obj) => obj,
    });
  }

  // allow user modify teachers and students of a programme when category, courseName and level are chosen
  if (
    (levelChoices.length > 0 &&
      values.category &&
      values.courseName &&
      values.level) ||
    (levelChoices.length === 0 && values.category && values.courseName)
  ) {
    columns.push({
      render_type: "autocomplete",
      field: "teachers",
      headerName: "Teacher",
      flex: 1,
      choices: teachers,
      multiple: true,
      displayLabel: (obj) => obj.first_name + " " + obj.last_name,
    });
    columns.push({
      render_type: "autocomplete",
      field: "students",
      headerName: "student",
      flex: 1,
      choices: students,
      multiple: true,
      displayLabel: (obj) => obj.first_name + " " + obj.last_name,
    });
  }

  React.useEffect(() => {
    if (
      levelChoices.length > 0 &&
      values.category &&
      values.courseName &&
      values.level
    ) {
      const selectedTeachersList = programmeList.filter(
        (programme) =>
          programme.category_name === values.category &&
          programme.level === values.level &&
          programme.name === values.courseName
      )[0].teachers;

      const selectedTeachersObjectList = teachers.filter((teacher) => {
        return selectedTeachersList.includes(teacher.id);
      });
      //console.log(selectedTeachersObjectList, selectedTeachersList);
      const selectedStudentsList = programmeList.filter(
        (programme) =>
          programme.category_name === values.category &&
          programme.level === values.level &&
          programme.name === values.courseName
      )[0].students;

      const selectedStudentsObjectList = students.filter((student) => {
        return selectedStudentsList.includes(student.id);
      });
      setValues({
        ...values,
        teachers: selectedTeachersObjectList,
        students: selectedStudentsObjectList,
      });
    } else if (
      levelChoices.length === 0 &&
      values.category &&
      values.courseName
    ) {
      const selectedTeachersList = programmeList.filter(
        (programme) =>
          programme.category_name === values.category &&
          programme.name === values.courseName
      )[0].teachers;

      const selectedTeachersObjectList = teachers.filter((teacher) => {
        return selectedTeachersList.includes(teacher.id);
      });

      const selectedStudentsList = programmeList.filter(
        (programme) =>
          programme.category_name === values.category &&
          programme.name === values.courseName
      )[0].students;

      const selectedStudentsObjectList = students.filter((student) => {
        return selectedStudentsList.includes(student.id);
      });
      setValues({
        ...values,
        teachers: selectedTeachersObjectList,
        students: selectedStudentsObjectList,
      });
    } else {
      setValues({
        category: values.category,
        courseName: values.courseName,
        level: values.level,
      });
    }
  }, [values.category, values.courseName, values.level]);

  React.useEffect(() => {
    if (levelChoices.length > 0) {
      setSchema({
        category: Joi.string().required(),
        courseName: Joi.string().required(),
        level: Joi.string().required(),
      });
    } else {
      setSchema({
        category: Joi.string().required(),
        courseName: Joi.string().required(),
      });
    }
  }, [levelChoices]);

  return (
    <AddDialog
      title={"Teacher And Student Modification"}
      ref={ref}
      openDialog={openDialog}
      onClose={onClose}
      columns={columns}
      schema={schema}
      onSubmit={(value) => {
        const preValue =
          levelChoices.length > 0
            ? programmeList.filter(
                (programme) =>
                  programme.category_name === values.category &&
                  programme.level === values.level &&
                  programme.name === values.courseName
              )[0]
            : programmeList.filter(
                (programme) =>
                  programme.category_name === values.category &&
                  programme.name === values.courseName
              )[0];
        const newValue = {
          ...preValue,
          students: value.students.map((student) => student.id),
          teachers: value.teachers.map((teacher) => teacher.id),
        };
        const newStudent = value.students.filter(
          (student) => !preValue.students.includes(student.id)
        );
        //console.log(newStudent);
        dispatch(updateProgramme(preValue, newValue));
        toast.success("Modify Programme Success");
        onClose();
      }}
      values={values}
      setValues={setValues}
    />
  );
}

export default forwardRef(TeacherAddDialog);
