import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Autocomplete, TextField, Box, Paper } from "@mui/material";
import { updateMySettings } from "./userSlice";

const primaryColorList = [
  "red",
  "pink",
  "purple",
  "indigo",
  "deepPurple",
  "blue",
  "green",
  "orange",
  "brown",
  "lightBlue",
  "cyan",
  "teal",
  "lightGreen",
  "lime",
  "yellow",
  "amber",
  "deepOrange",
  "grey",
  "blueGrey",
];

const errorColorList = [
  "red",
  "pink",
  "purple",
  "indigo",
  "deepPurple",
  "blue",
  "green",
  "orange",
  "brown",
  "lightBlue",
  "cyan",
  "teal",
  "lightGreen",
  "lime",
  "yellow",
  "amber",
  "deepOrange",
  "grey",
  "blueGrey",
];

export default function UserSetting() {
  const dispatch = useDispatch();
  const mySetting = useSelector((state) => state.user.mySettings);
  const [modifiedmySetting, setModifiedmySetting] = React.useState({});

  React.useState(() => {
    if (mySetting) {
      setModifiedmySetting({ ...mySetting });
    }
  }, [mySetting]);

  //   console.log(modifiedmySetting);

  return (
    <>
      {mySetting && (
        <Paper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
              gap: "20px",
            }}
          >
            <Autocomplete
              id="primaryColor"
              options={primaryColorList}
              value={modifiedmySetting.primaryColor}
              renderInput={(params) => (
                <TextField {...params} label="Primary Color" />
              )}
              onChange={(event, value) => {
                dispatch(
                  updateMySettings({
                    ...modifiedmySetting,
                    primaryColor: value,
                  })
                );
                setModifiedmySetting({
                  ...modifiedmySetting,
                  primaryColor: value,
                });
              }}
            />
            <Autocomplete
              id="errorColor"
              options={errorColorList}
              value={modifiedmySetting.errorColor}
              renderInput={(params) => (
                <TextField {...params} label="Error Color" />
              )}
              onChange={(event, value) => {
                dispatch(
                  updateMySettings({ ...modifiedmySetting, errorColor: value })
                );
                setModifiedmySetting({
                  ...modifiedmySetting,
                  errorColor: value,
                });
              }}
            />
            <TextField error label="Error Display" />
          </Box>
        </Paper>
      )}
    </>
  );
}
