import { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";

import { validateProperty } from "../validator/common";
import BasicSelect from "../components/common/BasicSelect";
import BasicAutocomplete from "../components/common/Autocomplete";
import usePassword from "./usePassword";
import { randomPassword } from "../tool";

export default function useForm(
  values,
  setValues,
  schema,
  variant = "standard"
) {
  const [errors, setErrors] = useState({});
  const [showPasswordType, passwordAdornment] = usePassword({
    handleRandomClick,
  });
  const focusTextRef = useRef(null);

  function handleRandomClick(field) {
    return () => setValues({ ...values, [field]: randomPassword(6) });
  }

  const handleValidation = (value, field) => {
    const newValues = { ...values };
    const newErrors = { ...errors };

    setValues({ ...newValues, [field]: value });

    // handle validate
    const errorMessage = validateProperty({ field, value }, schema);
    if (errorMessage) newErrors[field] = errorMessage;
    else delete newErrors[field];
    setErrors(newErrors);
  };

  const renderTextField = ({
    field,
    headerName,
    type = "text",
    required,
    flex,
    fullWidth,
    random,
    focus,
    otherStyle,
    disabled,
  }) => (
    <TextField
      disabled={disabled}
      ref={focus && focusTextRef}
      autoFocus={focus}
      error={Boolean(errors[field])}
      fullWidth={fullWidth}
      helperText={errors[field]}
      id={field}
      key={field}
      label={headerName}
      onChange={(event) => handleValidation(event.target.value, field)}
      required={required}
      sx={{ flex: flex, ...otherStyle }}
      type={showPasswordType(field, type)}
      value={values ? values[field] || "" : ""}
      variant={variant}
      InputProps={{
        endAdornment: type === "password" && passwordAdornment(field, random),
      }}
    />
  );

  const renderSelectField = ({
    field,
    headerName,
    choices,
    required,
    flex,
    fullWidth,
    focus,
    otherStyle,
    disabled,
  }) => (
    <BasicSelect
      disabled={disabled}
      defaultOpen={focus}
      choices={choices}
      otherstyle={otherStyle}
      flex={flex}
      fullWidth={fullWidth}
      handleChange={(event) =>
        setValues({ ...values, [field]: event.target.value })
      }
      key={field}
      label={headerName}
      required={required}
      selectedValue={values ? values[field] || "" : ""}
      variant={variant}
    />
  );

  const renderAutoComplete = ({
    field,
    headerName,
    choices,
    multiple,
    displayLabel,
    flex,
    extraEndAdornment,
    otherStyle,
    required,
    disabled,
  }) => (
    <BasicAutocomplete
      disabled={disabled}
      displayLabel={displayLabel}
      otherStyle={otherStyle}
      flex={flex}
      handleChange={(event, value) => setValues({ ...values, [field]: value })}
      label={headerName}
      multiple={multiple}
      options={choices}
      selectedValues={values ? values[field] || (multiple ? [] : null) : ""}
      variant={variant}
      extraEndAdornment={extraEndAdornment}
      required={required}
      key={field}
    />
  );

  const renderField = (column) => {
    if (!column.render_type) return renderTextField(column);
    else if (column.render_type === "select") return renderSelectField(column);
    else if (column.render_type === "autocomplete")
      return renderAutoComplete(column);
    else
      console.error(
        `"Attribute error: ${column.render_type}" is not valid render_type for "${column.field}" in Edit Dialog`
      );
  };

  return [errors, setErrors, renderField];
}
