import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const axios = require("axios");

export const getStamps = createAsyncThunk(
  "stamps/getStamps",
  async (arg, { getState }) => {
    const state = getState();

    return axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}/stamps/`,
      headers: {
        Authorization: state.user.token,
      },
      params: {},
    }).then((response) => response.data);
  }
);

export const createStamp = createAsyncThunk(
  "stamps/createStamp",
  async ({ name, description, image }, { getState }) => {
    const state = getState();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("image", image);

    return axios({
      method: "post",
      url: `${process.env.REACT_APP_URL}/stamps/`,
      headers: {
        Authorization: state.user.token,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((response) => response.data);
  }
);

export const updateStamp = createAsyncThunk(
  "stamps/updateStamp",
  async ({ name, description, image, id }, { getState }) => {
    const state = getState();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    image && formData.append("image", image);

    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL}/stamps/${id}/`,
      headers: {
        Authorization: state.user.token,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((response) => response.data);
  }
);

export const stampsSlice = createSlice({
  name: "stamps",
  initialState: { stamps: [], isLoading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStamps.fulfilled, (state, action) => {
      state.stamps = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getStamps.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createStamp.fulfilled, (state, action) => {
      state.stamps.push(action.payload);
      toast.success("Create Stamp Success");
    });
    builder.addCase(updateStamp.fulfilled, (state, action) => {
      state.stamps = state.stamps.filter(
        (stamp) => stamp.id !== action.payload.id
      );
      state.stamps.push(action.payload);
      toast.success("Modify Stamp Success");
    });
  },
});

export default stampsSlice.reducer;
