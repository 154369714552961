import * as React from "react";
import {
  Container,
  Box,
  CssBaseline,
  Typography,
  TextField,
  Avatar,
  Button,
} from "@mui/material/";
import { useDispatch } from "react-redux";
import PasswordIcon from "@mui/icons-material/Password";
import { useParams, useNavigate } from "react-router-dom";
import { sendResetPasswordConfirm } from "../user/userSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ResetNewPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const { uid, token } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newData = { uid: uid, new_password: newPassword, token: token };
    dispatch(sendResetPasswordConfirm({ data: newData }));
    navigate("/", { replace: true });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CssBaseline />
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <PasswordIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {"Reset New Password"}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="new_password"
              label="New Password"
              name="New Password"
              type="password"
              autoFocus
              value={newPassword}
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
            />
            <TextField
              helperText={
                verifyPassword === newPassword ? "" : "Both Input Are Not Equal"
              }
              error={!(verifyPassword === newPassword)}
              margin="normal"
              required
              fullWidth
              id="verifyNewPassword"
              label="Verify New Password"
              name="Verify New Password"
              type="password"
              autoFocus
              value={verifyPassword}
              onChange={(event) => {
                setVerifyPassword(event.target.value);
              }}
            />
            <Button
              disabled={newPassword === "" || !(verifyPassword === newPassword)}
              type="submit"
              fullWidth
              variant="contained"
              id="loginInButton"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
