import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config";
import { apiCallBegan } from "../api";
import { combineState, seperateState } from "../normalize";

const endpoint = endpoints["categories"];

const slice = createSlice({
  name: "categories",
  initialState: {
    byId: {},
    allIds: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    categoryListRequest: (categories, action) => {
      categories.loading = true;
    },
    categoryListReceived: (categories, { payload }) => {
      seperateState(categories, payload);
      categories.loading = false;
    },
    categoryRequest: (categories, action) => {
      categories.loading = true;
    },
    categoryReceived: (categories, { payload }) => {
      categories.allIds.push(payload.id);
      categories.byId[payload.id] = payload;
      categories.loading = false;
    },
    categoryCreate: (categories, { payload }) => {
      categories.allIds.push(payload.id);
      categories.byId[payload.id] = payload;
    },
    categoryCreateRollback: (categories, { payload }) => {
      categories.allIds = categories.filter(
        (category) => category !== payload.id
      );
    },
    categoryUpdate: (categories, { payload }) => {
      categories.byId[payload.id] = payload;
    },
    categoryUpdateRollback: (categories, { payload }) => {
      categories.byId[payload.id] = payload;
    },
    categoryDelete: (categories, { payload }) => {
      categories.allIds = categories.allIds.filter((id) => id !== payload.id);
    },
    categoryDeleteRollback: (categories, { payload }) => {
      categories.allIds = [...categories.allIds, payload.id];
    },
  },
});

const {
  categoryListRequest,
  categoryListReceived,
  categoryRequest,
  categoryReceived,
  categoryCreate,
  categoryUpdate,
  categoryUpdateRollback,
  categoryDelete,
  categoryDeleteRollback,
} = slice.actions;

// action
export const getCategoryList = () =>
  apiCallBegan({
    endpoint,
    method: "get",
    onStart: categoryListRequest.type,
    onSuccess: categoryListReceived.type,
  });

export const retrieveCategory = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "get",
    onStart: categoryRequest.type,
    onSuccess: categoryReceived.type,
  });

export const createCategory = (category) =>
  apiCallBegan({
    endpoint,
    method: "post",
    data: category,
    onSuccess: categoryCreate.type,
  });

export const updateCategory = (preCategory, category) =>
  apiCallBegan({
    endpoint: endpoint + category.id + "/",
    method: "patch",
    data: category,
    onStart: categoryUpdate.type,
    onStartPayload: category,
    onError: categoryUpdateRollback.type,
    onErrorPayload: preCategory,
  });

export const deleteCategorybyId = (id) =>
  apiCallBegan({
    endpoint: endpoint + id + "/",
    method: "delete",
    onStart: categoryDelete.type,
    onStartPayload: { id },
    onError: categoryDeleteRollback.type,
    onErrorPayload: { id },
  });

// selector
export const getCategories = (state) => combineState(state.entities.categories);

export const getCategoryLoadingStatus = (state) =>
  state.entities.categories.loading;

export const getCategorybyId = (id) => (state) =>
  state.entities.categories.byId[id];

export default slice.reducer;
