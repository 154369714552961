import React, { useEffect, useState } from "react";
import { List, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  getModuleList,
  getModulesDetail,
} from "../../../../app/entities/modules";
import CollaspeObjective from "./CollaspeObjective";
import ModuleHeader from "./ModuleHeader";
import EmptyItem from "../EmptyItem";
import ModuleListItem from "./ModuleListItem";
import { getObjectiveList } from "../../../../app/entities/objectives";

export default function ModuleInfo(props) {
  const { programme_id } = props;
  const [open, setOpen] = useState({});
  const dispatch = useDispatch();
  const modules = useSelector(getModulesDetail);

  useEffect(() => {
    if (programme_id) dispatch(getModuleList(programme_id));
  }, [programme_id]);

  useEffect(() => {
    dispatch(getObjectiveList());
  }, []);

  if (programme_id !== null)
    return (
      <List
        sx={{
          margin: "1.5rem",
          border: "solid",
          borderRadius: "8px",
          width: "auto",
          borderColor: "#e0e0e0",
          boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.3)",
        }}
      >
        <ModuleHeader programme_id={programme_id} />
        {modules
          .sort((a, b) => b.id - a.id)
          .map((module) => (
            <React.Fragment key={module.id}>
              <ModuleListItem
                programme_id={programme_id}
                module={module}
                open={open}
                setOpen={setOpen}
              />
              <CollaspeObjective
                module={module}
                objectives={module.objectives}
                open={open[module.id]}
              />
              <Divider />
            </React.Fragment>
          ))}
        {modules.length === 0 && <EmptyItem />}
      </List>
    );
}
