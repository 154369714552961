import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Box, Chip } from "@mui/material";
import { removeGoal } from "../lessonSlice";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function LessonGoalTag({ studentComment }) {
  const dispatch = useDispatch();
  const isTeacher = useSelector((state) => state.user.is_teacher);
  const performanceItems = studentComment.performanceItems;
  const chipData = performanceItems
    ? performanceItems.map((performanceItem, index) => ({
        key: index,
        performanceItem,
      }))
    : null;

  const handleDelete = (chipToDelete) => () => {
    dispatch(
      removeGoal({
        commentId: studentComment.id,
        performanceItemId: chipToDelete.performanceItem.id,
      })
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData
        ? chipData.map((data) => {
            return (
              <ListItem key={Math.random()}>
                <Chip
                  label={data.performanceItem.performance.name}
                  onDelete={isTeacher ? handleDelete(data) : undefined}
                />
              </ListItem>
            );
          })
        : null}
    </Box>
  );
}
