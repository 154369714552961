import React from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";

import Table from "../common/Table";
import { getUsers } from "../../app/entities/users";
import { getUserLoadingStatus } from "../../app/entities/users";
import { title } from "../../tool";

export default function UserTable(props) {
  const { handleCreateRecord, handleEditOpen, handleDeleteOpen } = props;

  const users = useSelector(getUsers);
  const loading = useSelector(getUserLoadingStatus);

  const titleFormatter = ({ value }) => title(value);

  const columns = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
    },
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      valueFormatter: titleFormatter,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      valueFormatter: titleFormatter,
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1.5,
    },
    {
      field: "is_teacher",
      headerName: "Teacher",
      type: "boolean",
      flex: 0.8,
    },
    {
      field: "is_student",
      headerName: "Student",
      type: "boolean",
      flex: 0.8,
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      getActions: ({ row }) => {
        const handleEdit = () => {
          handleEditOpen(row);
        };
        const handleDelete = () => {
          handleDeleteOpen(row);
        };
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEdit}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDelete}
          />,
        ];
      },
    },
  ];

  return (
    <Table
      initialState={{
        sorting: {
          sortModel: [{ field: "username", sort: "asc" }],
        },
      }}
      rows={users}
      columns={columns}
      loading={loading}
      tableName="user"
      handleCreateRecord={handleCreateRecord}
    />
  );
}
