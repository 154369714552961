import * as React from "react";
import { Box, Button } from "@mui/material";

export default function SubmitPanel({
  isNewAppointment,
  appointmentData,
  addLesson,
  editLesson,
  setAction,
  setOpen,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {!isNewAppointment && (
        <Button
          variant="outlined"
          color="error"
          sx={{ marginTop: "20px", marginRight: "20px" }}
          onClick={() => {
            setAction("delete");
            setOpen(true);
          }}
        >
          DELETE
        </Button>
      )}
      {
        <Button
          variant="outlined"
          sx={{ marginTop: "20px" }}
          onClick={
            isNewAppointment
              ? addLesson({
                  startDate: appointmentData.startDate,
                  endDate: appointmentData.endDate,
                })
              : editLesson({
                  startDate: appointmentData.startDate,
                  endDate: appointmentData.endDate,
                })
          }
        >
          {isNewAppointment ? "CREATE" : "SAVE"}
        </Button>
      }
    </Box>
  );
}
