import * as React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  IconButton,
  TextField,
  Button,
  Box,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { FixedSizeList } from "react-window";

function SchoolsBucketItem({ item, handleDelete, handleModify }) {
  const [disabled, setDisabled] = React.useState(true);
  const [name, setName] = React.useState(item.name);
  const [type, setType] = React.useState(item.type);

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton edge="end" onClick={() => handleDelete(item.id)}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemButton onClick={() => setDisabled(false)} sx={{ gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Input
            disableUnderline={disabled}
            value={name}
            disabled={disabled}
            onChange={(event) => setName(event.target.value)}
          />
          {!disabled && (
            <FormControl fullWidth variant="standard">
              <InputLabel>School Type</InputLabel>
              <Select
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
              >
                <MenuItem value="K">Kindergarten</MenuItem>
                <MenuItem value="P">Primary School</MenuItem>
                <MenuItem value="S">Secondary School</MenuItem>
                <MenuItem value="U">University</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>

        {!disabled && (
          <Box sx={{ flexDirection: "column", display: "flex" }}>
            <IconButton
              edge="end"
              onClick={(event) => {
                event.stopPropagation();
                handleModify(item.id, name, type);
                setDisabled(true);
              }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              edge="end"
              onClick={(event) => {
                event.stopPropagation();
                setName(item.name);
                setType(item.type);
                setDisabled(true);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </ListItemButton>
    </ListItem>
  );
}

export default function SchoolsBucket({
  items,
  handleAdd,
  handleModify,
  handleDelete,
}) {
  const [newSchoolName, setNewSchoolName] = React.useState("");
  const [newSchoolType, setNewSchoolType] = React.useState("");
  const [filterByName, setFilterByName] = React.useState("");
  const [filterBySchoolType, setFilterBySchoolType] = React.useState("A");

  const filteredItems = items.filter(
    (item) =>
      (!newSchoolName || item.name.includes(newSchoolName)) &&
      (!newSchoolType || item.type === newSchoolType)
  );

  const itemsPerPage = 50;
  const [hasMore, setHasMore] = React.useState(true);
  const [records, setrecords] = React.useState(itemsPerPage);
  const loadMore = () => {
    if (records >= filteredItems.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage);
      }, 500);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box
          sx={{ display: "flex", alignItems: "center", marginTop: 1, gap: 1 }}
        >
          <TextField
            label="name"
            variant="outlined"
            onChange={(event) => {
              setNewSchoolName(event.target.value);
              setrecords(itemsPerPage);
              setHasMore(true);
            }}
            value={newSchoolName}
          />
          <FormControl fullWidth>
            <InputLabel>School Type</InputLabel>
            <Select
              value={newSchoolType}
              onChange={(event) => {
                setNewSchoolType(event.target.value);
                setrecords(itemsPerPage);
                setHasMore(true);
              }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="K">Kindergarten</MenuItem>
              <MenuItem value="P">Primary School</MenuItem>
              <MenuItem value="S">Secondary School</MenuItem>
              <MenuItem value="U">University</MenuItem>
            </Select>
          </FormControl>
          <Button
            disabled={!(newSchoolName && newSchoolType)}
            onClick={() => {
              handleAdd(newSchoolName, newSchoolType);
              setNewSchoolName("");
              setNewSchoolType("");
            }}
          >
            Add
          </Button>
        </Box>
      </Box>
      <List sx={{ maxHeight: "50vh", overflow: "auto" }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 className="loader" key={Math.random()}>
              Loading...
            </h4>
          }
          useWindow={false}
        >
          {filteredItems.slice(0, records + 1).map((item) => (
            <SchoolsBucketItem
              key={item.id}
              item={item}
              handleDelete={handleDelete}
              handleModify={handleModify}
            />
          ))}
        </InfiniteScroll>
      </List>
    </>
  );
}
