import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import CasinoIcon from "@mui/icons-material/Casino";

export default function usePassword(options = { handleRandomClick: null }) {
  const [showPassword, setShowPawword] = useState({});

  const passwordAdornment = (field = "defaultField", random = false) => (
    <InputAdornment position="end">
      {random && (
        <IconButton edge="end" onClick={options.handleRandomClick(field)}>
          <CasinoIcon />
        </IconButton>
      )}
      <IconButton
        edge="end"
        onClick={() =>
          setShowPawword({ ...showPassword, [field]: !showPassword[field] })
        }
      >
        {showPassword[field] ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const showPasswordType = (field = "defaultField", type = "password") => {
    if (type !== "password") return type;
    else if (showPassword[field]) return "text";
    else return "password";
  };

  return [showPasswordType, passwordAdornment];
}
