import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Typography, Backdrop, CircularProgress } from "@mui/material";
import BagStamp from "./BagStamp";

import { getBagItems } from "./bagSlice";
import { getStamps } from "../stamps/stampsSlice";

export default function Bag() {
  const { studentUserId } = useParams();
  const { bagItems } = useSelector((state) => state.bag);
  const { stamps, isLoading } = useSelector((state) => state.stamps);

  const [stampsCollection, setStampsCollection] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (stamps && bagItems) {
      const newStampsCollection = {};
      stamps.forEach((stamp) => {
        newStampsCollection[stamp.id] = {
          collected: false,
          number: 0,
          stamp: stamp,
        };
      });

      bagItems.forEach((bagItem) => {
        if (newStampsCollection[bagItem.stamp.id]) {
          if (bagItem.isUsed === false) {
            if (newStampsCollection[bagItem.stamp.id].collected === false) {
              newStampsCollection[bagItem.stamp.id].collected = true;
            }
            newStampsCollection[bagItem.stamp.id].number += 1;
          }
        }
      });
      setStampsCollection(newStampsCollection);
    }
  }, [bagItems, stamps]);

  React.useEffect(() => {
    if (studentUserId) {
      dispatch(getBagItems({ bagId: studentUserId }));
      dispatch(getStamps());
    }
  }, [studentUserId]);

  return !isLoading ? (
    <Box>
      {stampsCollection &&
        Object.keys(stampsCollection).map((key) => {
          if (stampsCollection[key].number > 0) {
            return (
              <BagStamp
                key={Math.random()}
                stamp={stampsCollection[key].stamp}
                number={stampsCollection[key].number}
              />
            );
          }
        })}
    </Box>
  ) : (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
