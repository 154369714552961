import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import DeleteAlertDialog from "../../../components/common/DeleteAlertDialog";

import {
  deleteUserbyId,
  getUserList,
  updateUser,
} from "../../../app/entities/users";
import UserEditDialog from "../../../components/EditDialog/UserEditDialog";
import UserAddBar from "../../../components/AddBar/UserAddBar";
import UserTable from "../../../components/Table/UserTable";
import useDeleteAlert from "../../../hooks/useDeleteAlert";
import { useEdit } from "../../../hooks/useEdit";
import useTableToolBar from "../../../hooks/useTableToolBar";
import Slide from "@mui/material/Slide";

export default function UserTablePage() {
  const dispatch = useDispatch();
  const [showAddBar, handleCreateRecord, searchValue, setSearchValue] =
    useTableToolBar("user");

  const [
    editMode,
    selectedEditUser,
    modifiedUser,
    setModifiedUser,
    handleEditOpen,
    handleEditClose,
  ] = useEdit(updateUser);

  const [alertMode, selectedDeleteUser, handleDeleteOpen, handleDeleteClose] =
    useDeleteAlert(deleteUserbyId);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <UserTable
        handleCreateRecord={handleCreateRecord}
        handleEditOpen={handleEditOpen}
        handleDeleteOpen={handleDeleteOpen}
      />
      <Slide direction="up" in={showAddBar} mountOnEnter unmountOnExit>
        <UserAddBar setSearchValue={setSearchValue} />
      </Slide>
      <DeleteAlertDialog
        targetName={selectedDeleteUser.username}
        open={alertMode}
        handleClose={handleDeleteClose}
      />
      <UserEditDialog
        preValues={selectedEditUser}
        values={modifiedUser}
        setValues={setModifiedUser}
        targetName={selectedEditUser.username}
        open={editMode}
        handleClose={handleEditClose}
      />
    </React.Fragment>
  );
}
