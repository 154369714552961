import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SELECT_NONE } from "../../config";
import { FormHelperText } from "@mui/material";

export default function BasicSelect({
  label,
  choices,
  selectedValue,
  handleChange,
  width,
  fullWidth,
  variant,
  error,
  helperText,
  required,
  flex,
  defaultOpen,
  otherStyle,
  disabled,
}) {
  const style = {
    width: fullWidth ? undefined : width,
    flex,
    ...otherStyle,
  };

  const renderSelectNone = () => {
    if (!required)
      return (
        <MenuItem value={SELECT_NONE}>
          <em>None</em>
        </MenuItem>
      );
  };

  return (
    <FormControl
      variant={variant}
      sx={style}
      fullWidth={fullWidth}
      error={error}
      disabled={disabled}
    >
      <InputLabel id="simple-select-label">
        {required ? label + " *" : label}
      </InputLabel>
      <Select
        defaultOpen={defaultOpen}
        labelId="simple-select-label"
        id="simple-select"
        value={selectedValue}
        onChange={handleChange}
      >
        {renderSelectNone()}
        {choices.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
